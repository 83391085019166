import { Link } from "react-router-dom";
import styled from "styled-components";

export const SidebarContainer = styled.div`
  max-width: 320px;
  width: 320px;
  border-right: 1px solid black;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  height: 100%;

  @media print {
    display: none;
  }
`;

export const SidebarLink = styled(Link)`
  width: 100%;
  padding: 16px 24px;

  font-size: 20px;
  text-decoration: none;
  background-color: #fff;

  :hover {
    background-color: #eee;
  }

  :active {
    background-color: #ddd;
  }

  color: black;

  :visited {
    color: black;
  }
  display: block;
`;

const SidebarLinkDisabled = styled(SidebarLink).attrs({ as: "button", disabled: true })`
  :disabled {
    color: #666;
  }

  text-align: left;
  border: none;
  cursor: not-allowed;
`

export const AppSidebar = () => {
  return (
    <SidebarContainer>
      <SidebarLink to="/">
        Characters
      </SidebarLink>
      <SidebarLinkDisabled to="/collections">
        Collections
      </SidebarLinkDisabled>
      <SidebarLinkDisabled to="/settings">
        Settings
      </SidebarLinkDisabled>
    </SidebarContainer>
  )
}