import styled from "styled-components";

export const ClassLabels = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

export const ClassLabel = styled.label<{ right?: boolean }>`
  padding: 0px 16px 4px;
  width: fit-content;
  ${({ right }) => (right ? "margin-left: auto;" : "")};
`;
