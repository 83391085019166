import { useSelector } from "react-redux";

import { selectEquipment } from "features/character";

import { ExtraHeightSimple, ModuleType } from "../utils";

import { CombatFeaturesContainer } from "./styles";
import { Weapons } from "./weapons";
import { Armors } from "./armors";

export const CombatFeatures: ModuleType = ({ id }) => {
  const { weapons, armors } = useSelector(selectEquipment);

  return (
    <CombatFeaturesContainer borderWidth={8} lineWidth={2}>
      <Weapons weapons={weapons} />
      <Armors armors={armors} />
      <ExtraHeightSimple id={id} />
    </CombatFeaturesContainer>
  );
};
