import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import {
  selectAbilityScores,
  selectNonSavingThrowsSkills,
  changeSkill,
} from "features/character";
import { Select } from "components";
import { AbilityID, SkillsType } from "types";

const SkillsContainer = styled.div`
  width: 100%;
  columns: 2;
  column-gap: 32px;
`;

const AbilityLabel = styled.p`
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: bold;
`;

const SkillRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const AbilitySkills = styled.div`
  margin-bottom: 16px;
`;

type SortedSkillsType = {
  ability: AbilityID;
  abilityName: string;
  skills: SkillsType;
}[];

const options = [
  { value: 0, label: "Not proficient" },
  { value: 1, label: "Proficient" },
  { value: 2, label: "Expert" },
];

export const Skills = () => {
  const dispatch = useDispatch();
  const abilityScores = useSelector(selectAbilityScores);
  const skills = useSelector(selectNonSavingThrowsSkills);

  const setSkill =
    (id: string) =>
    (value = 0) => {
      dispatch(changeSkill({ id, value }));
    };

  const sortedSkills = skills.reduce<SortedSkillsType>((acc, skill) => {
    const abilityIndex = acc.findIndex(
      ({ ability }) => ability === skill.ability
    );

    if (abilityIndex !== -1) {
      acc[abilityIndex].skills.push(skill);
    } else {
      const abilityName = abilityScores.find(({ id }) => id === skill.ability)
        ?.name as string;

      acc.push({ ability: skill.ability, skills: [skill], abilityName });
    }

    return acc;
  }, []);

  return (
    <SkillsContainer>
      {sortedSkills.map(({ ability, skills, abilityName }) => (
        <AbilitySkills key={ability}>
          <AbilityLabel>{abilityName}</AbilityLabel>
          {skills.map(({ id, name, proficiencyMultiplier }) => (
            <SkillRow key={id}>
              <p>{name}</p>
              <Select
                size="small"
                style={{ width: "50%" }}
                value={proficiencyMultiplier}
                options={options}
                onChange={setSkill(id)}
              />
            </SkillRow>
          ))}
        </AbilitySkills>
      ))}
    </SkillsContainer>
  );
};
