import { FC } from "react";

import styled from "styled-components";

import { Border } from "components";

const AboutContainer = styled(Border)`
  display: flex;
  flex-direction: column;
  flex-basis: calc(100px - 16px) !important;
`;

const AboutLine = styled.div`
  display: flex;
  height: 50%;
  margin-top: 16px;
  margin-bottom: 32px;
  align-items: flex-end;
  position: relative;
`;

const AboutItem = styled.div<{ label: string }>`
  width: 100%;
  padding-left: 16px;
  font-size: 24px;
  line-height: 32px;
  border-bottom: 1px solid #ddd;
  position: relative;

  &:after {
    font-size: 16px;
    line-height: 24px;
    color: #888;
    content: "${({ label }) => label}";
    position: absolute;
    top: 100%;
    left: 16px;
  }
`;

const Alignment = styled(AboutItem)`

`

export const About: FC<{ race: string }> = ({ race }) => (
  <AboutContainer border="stylish" borderWidth={8} lineWidth={2}>
    <AboutLine>
      <AboutItem label="Race">{race}</AboutItem>
      <AboutItem label="Age"></AboutItem>
    </AboutLine>
    <AboutLine>
      <Alignment label="Alignment"></Alignment>
      <AboutItem label="Height"></AboutItem>
      <AboutItem label="Weight"></AboutItem>
    </AboutLine>
  </AboutContainer>
);