import { FC } from "react";
import styled from "styled-components";

const HitPointsContainer = styled.div`
  /* border: 1px solid black; */
  width: 204px;
  height: 104px;
  padding: 8px 0 0 4px;
  position: relative;
`

const HitPointsInnerContainer = styled.div`
  border: 1px solid black;
  width: calc(100% - 4px);
  height: calc(100% - 8px);
  bottom: 0;
  right: 0;
  position: absolute;
`

const HPMaxContainer = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
`

const HPMaxValue = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: var(--module-background, #fff);
  border: 1px solid black;
  font-size: 20px;
  height: 48px;
  width: 48px;
`

const HPMaxLabel = styled.div`
  background: var(--module-background, #fff);
  border: 1px solid black;
  border-left: none;
  max-width: 80px;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 1;
  padding: 4px;
  margin-top: 2px;
  align-self: flex-start;
`

const TempHitPointsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 72px;
`

const TempHitPointsLabel = styled.div`
  background: var(--module-background, #fff);
  border: 1px solid black;
  width: 100%;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 1;
  padding: 4px;
  margin-top: 2px;
  text-align: center;
`

const TempHitPointsValue = styled.div`
  border: 1px solid black;
  border-top: none;
  flex-grow: 1;
`

export const HitPoints: FC<{ hpMax?: number }> = ({ hpMax }) => (
  <HitPointsContainer>
    <HitPointsInnerContainer />
    <HPMaxContainer>
      <HPMaxValue>{hpMax}</HPMaxValue>
      <HPMaxLabel>Hit Point<br/>Maximum</HPMaxLabel>
    </HPMaxContainer>
  </HitPointsContainer>
)

export const TempHitPoints: FC = () => (
  <TempHitPointsContainer>
    <TempHitPointsLabel>Temporary<br/>Hit Points</TempHitPointsLabel>
    <TempHitPointsValue />
  </TempHitPointsContainer>
)
