import { v4 as uuid } from "uuid";

import {
  SheetAreasIds,
  ModuleHeight,
  LayoutOption,
  SheetModules,
  SheetAreas,
  CharacterFeatureKeys,
} from "types";
import { BorderNames } from "components";
import { AllModulesKeys } from "components/character-details/sheet/sheet-modules";

import { CharacterReducer } from "./types";

type Layout = () => {
  modules: SheetModules;
  areas: SheetAreas;
};

type Layouts = {
  martial: Layout;
  spellcaster: Layout;
  blank: Layout;
};

const getLayout = () => {
  const area1Ids = [uuid(), uuid(), uuid(), uuid(), uuid()];
  const area2Ids = [uuid(), uuid()];
  const area3Ids = [uuid()];

  const modules: SheetModules = {
    [area1Ids[0]]: {
      moduleName: "ActiveStats",
    },
    [area1Ids[1]]: {
      moduleName: "AbilityScoresAndSkills",
    },
    [area1Ids[2]]: {
      moduleName: "PassiveStats",
    },
    [area1Ids[3]]: {
      moduleName: "Proficiencies",
    },
    [area1Ids[4]]: {
      moduleName: "CharacterFeatures",
      title: "Traits",
      height: "grow",
      characterFeatures: {
        racialTraits: "all",
        otherTraits: "all",
      }
    },
    [area2Ids[0]]: {
      moduleName: "CombatStats",
    },
    [area2Ids[1]]: {
      moduleName: "CombatFeatures",
    },
    [area3Ids[0]]: {
      moduleName: "CharacterFeatures",
      title: "Class Features",
      height: "grow",
      characterFeatures: {
        classFeatures: "all",
      }
    },
  };

  return {
    area1Ids,
    area2Ids,
    area3Ids,
    modules,
  };
};

const layouts: Layouts = {
  spellcaster: () => {
    const { modules, area1Ids, area2Ids, area3Ids } = getLayout();
    const spellcastingId = uuid();

    return {
      modules: {
        ...modules,
        [spellcastingId]: {
          moduleName: "Spellcasting",
          spells: "all",
          height: "grow",
        },
      },
      areas: {
        area1: { modules: area1Ids },
        area2: { modules: area2Ids.concat(spellcastingId) },
        area3: { modules: area3Ids },
      },
    };
  },
  martial: () => {
    const { modules, area1Ids, area2Ids, area3Ids } = getLayout();

    return {
      modules,
      areas: {
        area1: { modules: area1Ids },
        area2: { modules: area2Ids },
        area3: { modules: area3Ids },
      },
    };
  },
  blank: () => ({
    modules: {},
    areas: {
      area1: { modules: [] },
      area2: { modules: [] },
      area3: { modules: [] },
    },
  }),
};

export const pickLayoutOption: CharacterReducer<LayoutOption> = (
  state,
  action
) => {
  const layoutOption = action.payload;
  const { modules, areas } = layouts[layoutOption]();
  state.sheetData.modules = modules;
  state.sheetData.layout.areas = areas;
};

export const addModule: CharacterReducer<{
  moduleName: AllModulesKeys;
  area: SheetAreasIds;
  index?: number;
}> = (state, action) => {
  const { area, index, moduleName } = action.payload;
  const id = uuid();
  state.sheetData.modules[id] = { moduleName };
  if (index === undefined) {
    state.sheetData.layout.areas[area].modules.push(id);
  } else {
    state.sheetData.layout.areas[area].modules.splice(index, 0, id);
  }
};

export const removeModule: CharacterReducer<{
  area: SheetAreasIds;
  id: string;
  index: number;
}> = (state, action) => {
  const { area, id, index } = action.payload;
  state.sheetData.layout.areas[area].modules.splice(index, 1);
  delete state.sheetData.modules[id];
};

export const moveModule: CharacterReducer<{
  area: SheetAreasIds;
  prevArea: SheetAreasIds;
  index: number;
  prevIndex: number;
  id: string;
}> = (state, action) => {
  const { area, prevArea, index, prevIndex, id } = action.payload;
  console.log({ prevArea, area, prevIndex, index })

  const areas = state.sheetData.layout.areas;

  areas[prevArea].modules.splice(prevIndex, 1);
  areas[area].modules.splice(index, 0, id);
};

// ALL STAT VISIBILITY

export const toggleShowAllStats: CharacterReducer = (state) => {
  state.sheetData.showAllStats = !state.sheetData.showAllStats;
};

// HEIGHT

export const changeModuleHeight: CharacterReducer<{
  height: ModuleHeight;
  id: string;
}> = (state, action) => {
  const { height, id } = action.payload;
  state.sheetData.modules[id].height = height;
};

// BORDER

export const changeModuleBorder: CharacterReducer<{
  activeModuleId: string | false;
  border: BorderNames;
}> = (state, action) => {
  const { activeModuleId, border } = action.payload;
  if (activeModuleId) {
    state.sheetData.modules[activeModuleId].border = border;
  }
};

// MODULE STAT VISIBILITY

export const toggleShowStats: CharacterReducer<{ id: string }> = (
  state,
  action
) => {
  const { id } = action.payload;

  const currentValue = state.sheetData.modules[id].showStats;
  const newValue =
    currentValue === undefined
      ? true
      : currentValue === true
      ? false
      : undefined;

  state.sheetData.modules[id].showStats = newValue;
};

// SPELLS

type ModuleSpellReducer = CharacterReducer<{
  moduleId: string;
  spellId: string;
}>;

type SpellSubscriptionReducer = CharacterReducer<{ moduleId: string }>;

export const addModuleSpell: ModuleSpellReducer = (state, action) => {
  const { moduleId, spellId } = action.payload;
  const currentSpells = state.sheetData.modules[moduleId]?.spells || [];
  const newSpells =
    currentSpells === "all" ? [spellId] : [...currentSpells, spellId];
  state.sheetData.modules[moduleId].spells = newSpells;
};

export const removeModuleSpell: ModuleSpellReducer = (state, action) => {
  const { moduleId, spellId } = action.payload;
  const module = state.sheetData.modules[moduleId];
  const allSpells = state.spells;
  const newSpells =
    module.spells === "all"
      ? allSpells.filter(({ id }) => id !== spellId).map(({ id }) => id)
      : module.spells?.filter((id) => id !== spellId);
  module.spells = newSpells;
};

export const subscribeToSpells: SpellSubscriptionReducer = (state, action) => {
  const { moduleId } = action.payload;
  state.sheetData.modules[moduleId].spells = "all";
};

export const unsubscribeToSpells: SpellSubscriptionReducer = (
  state,
  action
) => {
  const { moduleId } = action.payload;
  state.sheetData.modules[moduleId].spells = [];
};

// FEATURES

type ModuleFeatureReducer = CharacterReducer<{
  moduleId: string;
  featureKey: CharacterFeatureKeys;
  featureId: string;
}>;

type FeaturesSubscriptionReducer = CharacterReducer<{
  moduleId: string;
  featureKey: CharacterFeatureKeys;
}>;

export const addModuleFeature: ModuleFeatureReducer = (state, action) => {
  const { moduleId, featureKey, featureId } = action.payload;
  const module = state.sheetData.modules[moduleId];

  const features = module.characterFeatures?.[featureKey];
  const newFeatures = Array.isArray(features)
    ? [...features, featureId]
    : [featureId];

  module.characterFeatures = {
    ...module.characterFeatures,
    [featureKey]: newFeatures,
  };
};

export const removeModuleFeature: ModuleFeatureReducer = (state, action) => {
  const { moduleId, featureId, featureKey } = action.payload;
  const module = state.sheetData.modules[moduleId];

  const allFeatures = state.features[featureKey];
  const features = module.characterFeatures?.[featureKey] || [];
  const newFeatures =
    features === "all"
      ? allFeatures.filter(({ id }) => id !== featureId).map(({ id }) => id)
      : features.filter((id) => id !== featureId);

  console.log(allFeatures, newFeatures);

  module.characterFeatures = {
    ...module.characterFeatures,
    [featureKey]: newFeatures,
  };
};

export const subscribeToFeatures: FeaturesSubscriptionReducer = (
  state,
  action
) => {
  const { moduleId, featureKey } = action.payload;
  const module = state.sheetData.modules[moduleId];

  module.characterFeatures = {
    ...module.characterFeatures,
    [featureKey]: "all",
  };
};

export const unsubscribeToFeatures: FeaturesSubscriptionReducer = (
  state,
  action
) => {
  const { moduleId, featureKey } = action.payload;
  const module = state.sheetData.modules[moduleId];

  module.characterFeatures = {
    ...module.characterFeatures,
    [featureKey]: undefined,
  };
};

// TITLE

export const changeModuleTitle: CharacterReducer<{
  moduleId: string;
  title: string;
}> = (state, action) => {
  const { moduleId, title } = action.payload;
  state.sheetData.modules[moduleId].title = title;
};
