import { AllModulesKeys } from "components/character-details/sheet/sheet-modules";
import { CharacterFeatureKeys } from "./character";
import { BorderNames } from "components";

export type ModuleHeight = "auto" | "grow" | number;

export type MovedModule = { area: SheetAreaType; id: string; index: number };

export type ContextualWindow = "activeModule" | "styleSelectActive";

export type ModuleCharacterFeatures = {
  [key in CharacterFeatureKeys]?: string[] | "all"
};

export type ModuleSpellsType = string[] | "all"

export type SheetAreaType = "area1" | "area2" | "area3";

export type SheetModule = {
  moduleName: AllModulesKeys;
  height?: ModuleHeight;
  border?: BorderNames;
  spells?: ModuleSpellsType;
  characterFeatures?: ModuleCharacterFeatures;
  showStats?: boolean;
  title?: string;
};

export type SheetModules = {
  [id: string]: SheetModule;
};

export type SheetAreas = {
  [id: string]: {
    modules: string[];
  };
};

export type SheetModuleIds = keyof SheetModules;
export type SheetAreasIds = keyof SheetAreas;

export type SheetDataType = {
  showAllStats: boolean;
  layout: {
    areas: SheetAreas;
  };
  modules: SheetModules;
};

export const layoutOptions = [
  "martial",
  "spellcaster",
  "blank",
] as const

export type LayoutOption = typeof layoutOptions[number]

export type SheetType = {
  draggedModule?: AllModulesKeys;
  movedModule?: MovedModule;
  area1: string[];
  area2: string[];
  area3: string[];
  modules: SheetModules;
  activeModule: string | false;
  styleSelectActive: boolean;
  windowCloseQueue: ContextualWindow[];
  showAllStats: boolean;
};