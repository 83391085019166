import styled, { keyframes } from "styled-components";

import {
  ModuleContainerBackground,
  ModuleContainerShadow,
} from "../module-container/styles";

const ModuleDropZone = styled.div<{ active?: boolean }>`
  position: absolute;
  height: calc(50% + 8px);
  width: 100%;
  z-index: 9;

  :before {
    content: "";
    display: ${({ active }) => (active ? "block" : "none")};
    position: absolute;
    background: blue;
    width: calc(100% + 16px);
    left: -8px;
    height: 8px;
    z-index: 9;
  }
`;

export const ModuleDropZoneBefore = styled(ModuleDropZone)`
  top: -8px;
  left: 0;

  :before {
    top: 4px;
  }
`;

export const ModuleDropZoneAfter = styled(ModuleDropZone)`
  top: 50%;
  left: 0;

  :before {
    bottom: 4px;
  }
`;

export const DragOverlayContainer = styled.div`
  top: -65px;
  left: -320px;
  width: 374px;
  position: absolute;

  user-select: none;

  &, * {
    pointer-events: none !important;
  }
`;

const DraggedElementBase = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const DraggedContainerWrapper = styled(DraggedElementBase)`
  z-index: 1000;

  ${ModuleContainerShadow}:before, ${ModuleContainerShadow}:after {
    opacity: 1;
  }

  ${ModuleContainerBackground} {
    z-index: 2;
  }
`;

export const DraggedModuleContainerGhost = styled(DraggedElementBase)`
  opacity: 0.2;
  padding: 8px;
`;

export const DraggedModuleInner = styled.div`
  transform: scale(var(--sheet-scale));
  transform-origin: top left;

  display: flex;

  height: 100%;
  width: 100%;
`

const grow = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.0625);
  }
`

const shrink = keyframes`
  0% {
    transform: scale(1.0625);
  }
  100% {
    transform: scale(1);
  }
`

export const DraggedModuleUpScaler = styled.div<{ active: boolean }>`
  animation: 0.16s ${({ active }) => active ? grow : shrink} ease-in-out forwards;
  display: flex;
  height: 100%;
  width: 100%;
`