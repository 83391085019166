import { FC } from "react";
import styled, { css } from "styled-components";
import { WeaponType, DiceType } from "types";
import { getModifierSymbol } from "utils";
import {
  EquipmentContainer,
  EquipmentRow,
  EquipmentRowField,
  EquipmentLabelsRow,
  EquipmentLabel,
  EquipmentNotes,
} from "./styles";

const weaponDamage = (dice: DiceType) => `${dice.amount}${dice.die}`;

const weaponLabels = ["weapon", "atk bonus", "dmg", "type"];

const weaponFieldWidths = {
  name: css`
    flex-grow: 2;
    flex-basis: 0;
  `,
  attackBonus: css`
    flex-grow: 0;
    flex-basis: 60px;
  `,
  damage: css`
    flex-grow: 1;
    flex-basis: 0;
  `,
  damageType: css`
    flex-grow: 0;
    flex-basis: 20px;
  `,
};

const WeaponName = styled(EquipmentRowField)`
  ${weaponFieldWidths.name};
`;
const WeaponAttackBonus = styled(EquipmentRowField)`
  ${weaponFieldWidths.attackBonus};
`;
const WeaponDamage = styled(EquipmentRowField)`
  ${weaponFieldWidths.damage};
`;
const WeaponDamageType = styled(EquipmentRowField)`
  ${weaponFieldWidths.damageType};
  text-align: center;
`;

const WeaponNameLabel = styled(EquipmentLabel)`
  ${weaponFieldWidths.name};
`;

const WeaponAttackBonusLabel = styled(EquipmentLabel)`
  ${weaponFieldWidths.attackBonus};
`;

const WeaponDamageLabel = styled(EquipmentLabel)`
  ${weaponFieldWidths.damage};
`;

const WeaponDamageTypeLabel = styled(EquipmentLabel)`
  ${weaponFieldWidths.damageType};
  min-width: 0;
  display: flex;
  justify-content: center;
`;

const Weapon: FC<Partial<WeaponType>> = ({
  name,
  attackBonus,
  damage,
  damageType,
  notes,
}) => (
  <>
    <EquipmentRow>
      <WeaponName>{name}</WeaponName>
      <WeaponAttackBonus>
        {attackBonus && getModifierSymbol(attackBonus)}
      </WeaponAttackBonus>
      <WeaponDamage>{damage && weaponDamage(damage)}</WeaponDamage>
      <WeaponDamageType>
        {damageType && damageType.substring(0, 1)}
      </WeaponDamageType>
    </EquipmentRow>
    {notes && <EquipmentNotes notes={notes} />}
  </>
);

export const Weapons: FC<{ weapons: WeaponType[] }> = ({ weapons }) => {
  return (
    <EquipmentContainer>
      <EquipmentLabelsRow>
        <WeaponNameLabel>{weaponLabels[0]}</WeaponNameLabel>
        <WeaponAttackBonusLabel>{weaponLabels[1]}</WeaponAttackBonusLabel>
        <WeaponDamageLabel>{weaponLabels[2]}</WeaponDamageLabel>
        <WeaponDamageTypeLabel>{weaponLabels[3]}</WeaponDamageTypeLabel>
      </EquipmentLabelsRow>
      {weapons.map((weapon) => (
        <Weapon key={weapon.name} {...weapon} />
      ))}
      {weapons.length === 0 && (
        <>
          <Weapon />
          <Weapon />
        </>
      )}
    </EquipmentContainer>
  );
};
