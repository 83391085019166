import React, {
  useState,
  memo,
  createElement,
  useCallback,
  useMemo,
  RefObject,
  FC,
} from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  changeMovedModule,
  selectIsModuleActive,
  setActiveModule,
} from "features/sheet";
import {
  changeModule,
  selectModule,
  selectShowModuleStats,
} from "features/character";
import { statValueColor } from "styles";
import { SheetAreaType, SheetModule } from "types";

import { AllModules } from "../sheet-modules";
import { ModuleDropZones } from "../module-drop";
import {
  isResizeable,
  ResizeGrow,
  useResize,
  ResizeContext,
  getHeightStyles,
} from "./resize";
import { ModuleContainerControls } from "./menus";
import {
  ModuleContainerWrapper,
  ModuleContainerShadow,
  ModuleContainerBackground,
} from "./styles";

type ModuleContainerProps = {
  id: string;
  area: SheetAreaType;
  index: number;
  style?: React.CSSProperties;
};

type ColumnRef = { columnRef: RefObject<HTMLDivElement> };

type ModuleContainerComponentProps = ModuleContainerProps & SheetModule;

const useModuleControls = (area: SheetAreaType, id: string, index: number) => {
  const dispatch = useDispatch();

  const [isDraggable, setIsDraggable] = useState(false);

  const noMoveOnZoom = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDraggable(true);
  };

  const endDrag = () => setIsDraggable(false);

  const startModuleMove = () =>
    dispatch(changeMovedModule({ id, area, index }));

  const remove = () => dispatch(changeModule.remove({ area, index, id }));

  return {
    isDraggable,
    endDrag,
    remove,
    noMoveOnZoom,
    startModuleMove,
  };
};

const useModuleActivation = (id: string, endDrag: () => void) => {
  const dispatch = useDispatch();
  const [shouldActivate, setShouldActivate] = useState(false);
  const activateModule = useCallback(
    () => dispatch(setActiveModule(id)),
    [dispatch, id]
  );

  const prepareActivation = useCallback(() => {
    endDrag();
    setShouldActivate(true);
  }, [endDrag, setShouldActivate]);

  const attemptActivation = useCallback(() => {
    shouldActivate && activateModule();
  }, [shouldActivate, activateModule]);

  const stopActivation = useMemo(
    () => (shouldActivate ? () => setShouldActivate(false) : undefined),
    [shouldActivate, setShouldActivate]
  );

  return { prepareActivation, attemptActivation, stopActivation };
};

const getStatValueColor = (showStats?: boolean) => {
  if (showStats === undefined) {
    return {};
  }

  return { [statValueColor]: showStats ? "black" : "white" };
};

const ModuleContainerComponent = React.forwardRef<
  HTMLDivElement,
  ModuleContainerComponentProps
>(({ id, area, index, style, moduleName, border = "stylish" }, ref) => {
  const isActive = useSelector(selectIsModuleActive(id));
  const { isDraggable, endDrag, remove, noMoveOnZoom, startModuleMove } =
    useModuleControls(area, id, index);

  const { prepareActivation, attemptActivation, stopActivation } =
    useModuleActivation(id, endDrag);

  const showStats = useSelector(selectShowModuleStats(id));

  const realStyle = useMemo(
    () => ({
      ...style,
      ...getStatValueColor(showStats),
    }),
    [style, showStats]
  );

  return (
    <ModuleContainerWrapper
      id={id}
      ref={ref}
      style={realStyle as React.CSSProperties}
      draggable={isDraggable}
      onDragStart={startModuleMove}
      onDragEnd={endDrag}
      onMouseDown={prepareActivation}
      onMouseUp={attemptActivation}
      onMouseMove={stopActivation}
      isActive={isActive}
    >
      <ModuleContainerShadow />
      <ModuleContainerBackground>
        {createElement(AllModules[moduleName], { border, id })}
      </ModuleContainerBackground>
      <ModuleDropZones area={area} index={index} id={id} />
      {isActive && (
        <ModuleContainerControls
          endDrag={endDrag}
          remove={remove}
          moduleName={moduleName}
          noMoveOnZoom={noMoveOnZoom}
          id={id}
          area={area}
          index={index}
        />
      )}
    </ModuleContainerWrapper>
  );
});

const ResizeableModule: FC<ModuleContainerComponentProps & ColumnRef> = ({
  columnRef,
  id,
  height,
  style,
  ...props
}) => {
  const {
    containerRef,
    stateHeight,
    resizing,
    setShouldGrow,
    ...contextHandlers
  } = useResize(id, height);

  const combinedStyle = {...getHeightStyles(stateHeight), ...style};

  return (
    <ResizeContext.Provider value={{ id, ...contextHandlers }}>
      <ModuleContainerComponent
        id={id}
        style={combinedStyle}
        ref={containerRef}
        {...props}
      />
      {resizing &&
        columnRef.current &&
        createPortal(
          <ResizeGrow id={id} setShouldGrow={setShouldGrow} />,
          columnRef.current
        )}
    </ResizeContext.Provider>
  );
};

export const Module = memo<ModuleContainerProps & ColumnRef>(
  ({ children, columnRef, ...props }) => {
    const moduleProps = useSelector(selectModule(props.id));

    return isResizeable(moduleProps.moduleName) ? (
      <ResizeableModule columnRef={columnRef} {...props} {...moduleProps} />
    ) : (
      <ModuleContainerComponent {...props} {...moduleProps} />
    );
  }
);
