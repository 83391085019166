import { FC } from "react";
import styled, { CSSProperties } from "styled-components";

import {
  useDropdown,
  DropdownItem,
  DropdownContainer,
  DropdownItemProps,
} from "components/internal";
import { Button, IconButton } from "../button";
import { RowButton } from "../row-button";

const ButtonDropdownContainer = styled.div`
  position: relative;
  line-height: 0;
`;

const DropdownIcon = styled.span`
  margin-left: 8px;
`;

type CommonDropdownProps = {
  options: { value: string; label: string }[];
  anchor?: "right" | "left";
};

type ButtonDropdownProps = CommonDropdownProps & {
  onClick: (option: string) => void;
  size?: "small";
  containerStyles?: CSSProperties;
};

type DropdownListProps = CommonDropdownProps & {
  itemProps: DropdownItemProps<string>;
};

const DropdownList = ({
  options,
  itemProps,
  anchor = "left",
}: DropdownListProps) => (
  <DropdownContainer style={{ [anchor]: "0px" }}>
    {options.map((option, index) => (
      <DropdownItem key={option.value}>
        <RowButton {...itemProps(option, index)}>{option.label}</RowButton>
      </DropdownItem>
    ))}
  </DropdownContainer>
);

export const ButtonDropdown: FC<ButtonDropdownProps> = ({
  children,
  options,
  onClick,
  ...props
}) => {
  const { isOpen, handlerProps, itemProps } = useDropdown({
    onClick,
    options,
    value: undefined,
  });

  return (
    <ButtonDropdownContainer>
      <Button {...handlerProps} {...props}>
        {children}
        <DropdownIcon>{isOpen ? "˄" : "˅"}</DropdownIcon>
      </Button>
      {isOpen && <DropdownList options={options} itemProps={itemProps} />}
    </ButtonDropdownContainer>
  );
};

export const IconButtonDropdown: FC<ButtonDropdownProps> = ({
  children,
  options,
  onClick,
  containerStyles,
  anchor,
  ...props
}) => {
  const { isOpen, handlerProps, itemProps } = useDropdown({
    onClick,
    options,
    value: undefined,
  });

  return (
    <ButtonDropdownContainer style={containerStyles}>
      <IconButton {...handlerProps} {...props}>
        {children}
      </IconButton>
      {isOpen && (
        <DropdownList anchor={anchor} options={options} itemProps={itemProps} />
      )}
    </ButtonDropdownContainer>
  );
};
