import { useLayoutEffect, useState } from "react";
import styled, { CSSObject } from "styled-components";
import useComponentSize from "@rehooks/component-size";

import {
  RowButton,
  NewNumberInput,
  NewNumberInputProps,
  InputButton,
  inputButtonPosition,
} from "components";
import {
  useDropdown,
  DropdownContainer,
  DropdownItem,
} from "components/internal";
import {
  diceOptionsArray,
  diceOptionsArrayWithUndefined,
  DiceKeys,
} from "types";

type DiceInputProps<
  T extends number | undefined,
  S extends DiceKeys | undefined
> = NewNumberInputProps<T> & {
  diceValue: S;
  onChangeDice: (option: S) => void;
  excludeNoDice?: boolean;
};

const DropdownHandler = styled(InputButton)`
  position: absolute;
  width: auto;
  padding-right: 8px;
  padding-left: 4px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;

  bottom: ${inputButtonPosition};
  right: ${inputButtonPosition};

  z-index: 10;

  &:focus {
    border: 2px solid black;
    padding-right: 6px;
    padding-left: 2px;
  }
`;

const StyledDropdownContainer = styled(DropdownContainer)`
  right: 0;
`;

const DropdownIcon = styled.span`
  margin-left: 0px;
  width: 0px;
  opacity: 0;

  transition-property: margin-left, width, opacity;
  transition-timing-function: linear;
  transition-duration: 0.1s;
`;

const DropdownValue = styled.p`
  color: black !important;
`;

const DropdownPlacholder = styled.p`
  color: #888 !important;
`;

export function DiceInput<
  T extends number | undefined,
  S extends DiceKeys | undefined
>({
  diceValue,
  onChangeDice,
  excludeNoDice = false,
  size,
  ...props
}: DiceInputProps<T, S>) {
  const diceOptions = (
    !excludeNoDice ? diceOptionsArrayWithUndefined : diceOptionsArray
  ) as { label: string; value: S }[];

  const { handlerProps, itemProps, isOpen, handlerRef } = useDropdown<S>({
    value: diceValue,
    options: diceOptions,
    onChange: onChangeDice as (value: S | undefined) => void,
  });

  const { width } = useComponentSize(handlerRef);
  const [inputPadding, setInputPadding] = useState(0);
  useLayoutEffect(() => setInputPadding(width + 8), [setInputPadding, width]);

  const inputStyle: CSSObject = {
    ...props.style,
    textAlign: "right",
    paddingRight: `${inputPadding}px`,
  };

  const label = diceOptions.find((option) => option.value === diceValue)?.label;

  return (
    <NewNumberInput {...props} size={size} style={inputStyle}>
      <DropdownHandler {...handlerProps} size={size}>
        {diceValue !== undefined ? (
          <DropdownValue>{label}</DropdownValue>
        ) : (
          <DropdownPlacholder>d00</DropdownPlacholder>
        )}
        <DropdownIcon>{isOpen ? "˄" : "˅"}</DropdownIcon>
      </DropdownHandler>
      {isOpen && (
        <StyledDropdownContainer>
          {diceOptions.map((option, index) => (
            <DropdownItem key={option.label}>
              <RowButton {...itemProps(option, index)}>
                {option.label}
              </RowButton>
            </DropdownItem>
          ))}
        </StyledDropdownContainer>
      )}
    </NewNumberInput>
  );
}
