import { SheetDataType } from "./sheet"

export type AbilityID = "str" | "dex" | "con" | "int" | "wis" | "cha";

export type SkillId =
  | "strST"
  | "athlt"
  | "dexST"
  | "acrbt"
  | "slght"
  | "stlth"
  | "conST"
  | "intST"
  | "arcna"
  | "histr"
  | "invst"
  | "natur"
  | "relgn"
  | "wisST"
  | "anmlh"
  | "insgt"
  | "prcpt"
  | "srviv"
  | "medcn"
  | "chaST"
  | "dcptn"
  | "intmd"
  | "perfr"
  | "prssn";

export type CharacterBaseType = {
  name: string;
  race: string;
  background: string;
};

export type SkillType = {
  id: SkillId;
  ability: AbilityID;
  name: string;
  proficiencyMultiplier: number;
  savingThrow?: true;
};

export type SkillsType = SkillType[];

export type AbilityScoreType = {
  id: AbilityID;
  name: string;
  modifier: number;
  value: number;
};

export type AbilityScoresType = AbilityScoreType[];

export type GenericStatType = {
  id: string;
  statName: string;
  statValue?: number;
  isModifier?: boolean;
  isNotStat?: boolean;
};

export type CharacterFeatureType = {
  id: string;
  label: string;
  text: string;
  resources?: number;
  dice?: DiceKeys;
};

export type CharacterFeaturesType = {
  classFeatures: CharacterFeatureType[];
  feats: CharacterFeatureType[];
  racialTraits: CharacterFeatureType[];
  otherTraits: CharacterFeatureType[];
};

export type CharacterFeatureKeys = keyof CharacterFeaturesType;

export type GenericStatsType = GenericStatType[];

export type ClassType = {
  id: string;
  className: string;
  subClass: string;
  level: number;
};

export const mainProficiencies = {
  lightArmour: false,
  simpleWeapons: false,
  mediumArmour: false,
  martialWeapons: false,
  heavyArmour: false,
  shields: false,
};

export const mainProficiencyKeys = [
  "lightArmour",
  "simpleWeapons",
  "mediumArmour",
  "martialWeapons",
  "heavyArmour",
  "shields",
] as const

export type MainProficiencies = typeof mainProficiencies

export type MainProficienciesKeys = typeof mainProficiencyKeys[number]

export type ProficienciesType = {
  main: MainProficiencies;
  tools: string[];
  weapons: string[];
  other: string[];
  languages: string[];
};

export type ProficienciesKeys = keyof Omit<ProficienciesType, "main">

export const diceArray = [
  "d4",
  "d6",
  "d8",
  "d10",
  "d12",
  "d20",
  "d100",
] as const;

export const diceOptionsArray = diceArray.map((die) => ({
  label: die,
  value: die,
}));

export const diceOptionsArrayWithUndefined = [
  { label: "no dice", value: undefined },
  ...diceOptionsArray,
];

export type DiceKeys = typeof diceArray[number];

export type DiceType = {
  amount: number;
  die: DiceKeys;
};

export type CombatStatsType = {
  armorClass?: number;
  shield?: number;
  initiative?: number;
  speed: number;
  climbingSpeed: number;
  swimmingSpeed: number;
  flyingSpeed: number;
  hpMax?: number;
  hitDice: DiceType[];
};

export const damageTypes = [
  "slashing",
  "piercing",
  "bludgeoning",
  "poison",
  "acid",
  "fire",
  "cold",
  "radiant",
  "necrotic",
  "lightning",
  "thunder",
  "force",
  "psychic",
] as const;

export type DamageType = typeof damageTypes[number];

export type EquipmentNoteType = {
  id: string;
  title?: string;
  text?: string;
};

export type EquipmentNotesType = EquipmentNoteType[];

export type WeaponType = {
  id: string;
  name: string;
  attackBonus: number;
  damage: DiceType;
  damageBonus: number;
  damageType: DamageType;
  notes: EquipmentNotesType;
  versatile: boolean;
  versatileDamage?: DiceType;
  ranged: boolean;
  range?: {
    normal: number;
    long: number;
  };
};

export type WeaponsType = WeaponType[];

export type ArmorType = {
  id: string;
  name: string;
  armorClass: number;
  strength?: number;
  stealthDisadvantage: boolean;
  notes: EquipmentNotesType;
};

export type ArmorsType = ArmorType[];

export type EquipmentType = {
  weapons: WeaponsType;
  armors: ArmorsType;
};

export type SpellSlotType = {
  level: number;
  amount: number;
};

export type SpellSlotsType = SpellSlotType[];

export const magicSchools = [
  "transmutation",
  "abjuration",
  "alteration",
  "conjuration",
  "divination",
  "enchantment",
  "illusion",
  "invocation",
  "necromancy",
] as const;

export type MagicSchool = typeof magicSchools[number];

export type AmountUnit = { amount: number; unit: string };

export type SpellRange = number | "self" | "unlimited" | "special" | AmountUnit;

export type SpellDuration =
  | "instant"
  | "untilDispelled"
  | "untilTriggered"
  | AmountUnit;

export type CastingTime = "action" | "bonusAction" | "reaction" | AmountUnit;

export type SpellType = {
  id: string;
  level: number;
  name: string;
  description: string;
  reference: string;
  // concentration?: boolean;
  ritual?: boolean;
  school?: MagicSchool;
  // range?: SpellRange;
  // durationUpTo?: boolean;
  // duration?: SpellDuration;
  // castingTime?: CastingTime;
  longDescription?: string;
  // components?: ("V" | "S" | "M")[];
  // material?: string;
};

export type SpellListType = SpellType[];

export type SpellcastingType = {
  spellAttackModifier?: number;
  spellSaveDC?: number;
  cantripsAmount?: number;
  spellsAmount?: number;
  spellSlots: SpellSlotsType;
};


export type CharacterType = {
  activeStats: GenericStatsType;
  passiveStats: GenericStatsType;
  base: CharacterBaseType;
  spells: SpellType[];
  classes: ClassType[];
  combatStats: CombatStatsType;
  abilityScores: AbilityScoresType;
  skills: SkillsType;
  spellcasting: SpellcastingType;
  proficiencies: ProficienciesType;
  equipment: EquipmentType;
  features: CharacterFeaturesType;
  id: string;
  createdAt: number;
  savedAt: number;
  sheetData: SheetDataType
};

export type MinimalCharacterType = Pick<
  CharacterType,
  | "id"
  | "base"
  | "classes"
  | "createdAt"
  | "savedAt"
  | "classes"
>;
