import { AbilityScoresAndSkills } from "./ability-scores-and-skills"
import { Proficiencies } from "./proficiencies"
import { CharacterFeatures } from "./character-features"
import { CombatStats } from "./combat-stats"
import { CombatFeatures } from "./combat-features"
import { Spellcasting } from "./spellcasting"
import { PassiveStats } from "./passive-stats"
import { ActiveStats } from "./active-stats"

export const AllModules = {
  AbilityScoresAndSkills,
  Proficiencies,
  CharacterFeatures,
  CombatStats,
  CombatFeatures,
  Spellcasting,
  PassiveStats,
  ActiveStats
} as const;
  
export type AllModulesKeys = keyof typeof AllModules;