import {
  forwardRef,
} from "react";
import styled from "styled-components";

import { noFocusMixin, buttonColors as colors } from "styles"
import { useHideFocusOnClick } from "components/internal"
import { ButtonElementProps } from "../common";

const borderWidth = 1
const itemVerticalPadding = 4

const RowButtonStyles = styled.button<{ hideFocus: boolean }>`
  text-transform: capitalize;
  position: relative;
  width: 100%;
  text-align: left;
  background: white;
  padding: ${itemVerticalPadding}px ${16 - borderWidth}px;
  border: 0;

  line-height: 24px;
  font-size: 16px;

  background-color: ${colors.background};
  border-color: ${colors.border};
  color: ${colors.text};
  
  :hover {
    background-color: ${colors.hoverBackground};
  }

  :active {
    background-color: ${colors.activeBackground};
  }

  ${noFocusMixin}

  :focus {
    :before {
      content: "";
      display: ${({ hideFocus }) => hideFocus ? "none" : "block"};
      position: absolute;
      top: calc(50% - 1px);
      transform: translateY(-50%);
      left: 4px;
      height: 2px;
      width: 8px;
      background: black;
    }
  }
`;

export const RowButton = forwardRef<HTMLButtonElement, ButtonElementProps>(
  ({ children, ...props }, ref) => {
    const hideFocusProps = useHideFocusOnClick(props);

    return (
      <RowButtonStyles {...props} {...hideFocusProps} ref={ref}>
        {children}
      </RowButtonStyles>
    );
  }
);

