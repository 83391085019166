import styled, { css } from "styled-components";

import { FloatingButton } from "components";

export const ResizeGrowIndicator = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  position: absolute;
  border: 2px solid black;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%) scale(calc(1 / var(--sheet-scale, 1)));

  cursor: ns-resize;

  &:after {
    content: "X";
    display: block;
    font-size: 20px;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    padding-bottom: 4px;
    line-height: 1;
  }
  
  &:hover {
    height: 40px;
    width: 40px;
    border: 4px solid pink;
  }
`;

export const ResizeIndicator = styled(FloatingButton)`
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%) scale(calc(1 / var(--sheet-scale, 1)));
  cursor: ns-resize;
  z-index: 10;

  &:after {
    display: block;
    content: "↕";
    font-size: 20px;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    padding-bottom: 4px;
    line-height: 1;
  }
`;

export const ModuleContainerShadow = styled.div`
  :before,
  :after {
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    content: "";
    position: absolute;
    border-radius: 16px;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
  :before {
    box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.3);
    z-index: 3;
  }
  :after {
    box-shadow: 0 2px 8px 3px rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  @media print {
    display: none;
  }
`;

export const ModuleContainerBackground = styled.div`
  position: relative;

  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-grow: 1;

  padding: 8px;
  border-radius: 16px;
  background: white;
`;

export const ModuleContainerWrapper = styled.div<{ isActive: boolean }>`
  position: relative;
  display: flex;
  justify-content: stretch;
  align-items: stretch;

  transition: transform var(--animation-time) ease;

  :before {
    content: "";
    position: absolute;
    border-radius: 16px;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  :hover {
    ${ModuleContainerShadow}:before {
      opacity: 1;
    }

    ${ModuleContainerBackground} {
      z-index: 2;
    }
  }

  ${({ isActive }) =>
    !isActive
      ? css``
      : css`
          ${ModuleContainerShadow}:before {
            opacity: 1;
          }
          ${ModuleContainerShadow}:after {
            opacity: 1;
          }

          ${ModuleContainerBackground} {
            z-index: 4 !important;
            /* box-shadow: inset 0 0 20px 0 #ff00ff29; */
          }
        `}
`;

export const DraggedContainerWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: stretch;
  align-items: stretch;

  z-index: 1000;
  top: -65px;
  left: -320px;
  width: 374px;

  user-select: none;

  pointer-events: none !important;

  * {
    pointer-events: none !important;
  }

  :before {
    content: "";
    position: absolute;
    border-radius: 16px;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  ${ModuleContainerShadow}:before, ${ModuleContainerShadow}:after {
    opacity: 1;
  }

  ${ModuleContainerBackground} {
    z-index: 2;
  }
`;

export const DraggedContainerWrapperInner = styled.div`
  transform: scale(var(--sheet-scale));
  height: 100%;
  display: flex;
  width: 100%;
  transform-origin: top left;
`;

export const DraggedModuleContainerGhost = styled.div`
  user-select: none;
  position: absolute;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  padding: 8px;

  top: -65px;
  left: -320px;
  width: 374px;

  pointer-events: none;

  * {
    pointer-events: none !important;
  }

  top: 0;
  left: 0;

  opacity: 0.2;
`;

export const DraggedModuleContainerGhostInner = styled.div`
  display: content;
  width: 100%;
  transform-origin: top left;
  transform: scale(var(--sheet-scale));
`;
