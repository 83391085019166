import { FC } from "react";
import styled from "styled-components";

import { getModifierSymbol } from "utils";
import { GenericStatType } from "types";
import { statColorMixin } from "styles";
import { Border } from "components";

const StatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 1;
  justify-content: space-around;
  width: 100%;

  flex-wrap: wrap;
`;

const StatBlock = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;

const StatValue = styled.div`
  font-size: 20px;
  min-height: 32px;
  max-height: 32px;
  min-width: 32px;
  max-width: 32px;
  border: 1px solid black;
  border-radius: 50%;
  background: white;
  z-index: 1;

  ${statColorMixin};

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const StatLabel = styled(Border)`
  /* border: 1px solid black; */
  height: auto;
  margin-left: -8px;
  padding: 4px;
  padding-left: 12px;
  padding-right: 8px;
  font-size: 10px;
  text-transform: uppercase;
`;

export const GenericStat: FC<GenericStatType> = ({
  statName,
  statValue,
  isModifier,
  isNotStat
}) => (
  <StatBlock>
    <StatLabel borderWidth={4} lineWidth={1} border="flagRight">{statName}</StatLabel>
    <StatValue>
      {!isNotStat && statValue && isModifier ? getModifierSymbol(statValue) : statValue}
    </StatValue>
  </StatBlock>
);

export const GenericStats: FC<{ stats: GenericStatType[] }> = ({ stats }) => (
  <StatsContainer>
    {stats.map((stat) => (
      <GenericStat key={stat.statName} {...stat} />
    ))}
  </StatsContainer>
);
