import {
  createRef,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
  FocusEvent,
  MouseEvent,
  RefObject,
} from "react";
import styled from "styled-components";
import { v4 as uuid } from "uuid";

type DropdownOption<T> = { value: T; label: string };

type DropdownProps<T> = {
  value: T | undefined;
  options: DropdownOption<T>[];
  onClick?: (option: T) => void;
  onChange?: (option: T) => void;
};

export type DropdownItemProps<T> = (
  option: DropdownOption<T>,
  index: number
) => {
  "data-dropdown": string;
  ref: RefObject<HTMLButtonElement>;
  onKeyDown: (e: KeyboardEvent<HTMLButtonElement>) => void;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  onBlur: (e: FocusEvent<HTMLElement>) => void;
};

export function useDropdown<T>({
  options,
  onChange,
  onClick,
  value,
}: DropdownProps<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const id = useRef<string>(uuid());
  const handlerRef = useRef<HTMLButtonElement>(null);
  const itemRefs = options.map(() => createRef<HTMLButtonElement>());

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleChoice = (option: T) => (e: MouseEvent<HTMLButtonElement>) => {
    option && onClick?.(option);
    onChange?.(option);
    setIsOpen(false);
    handlerRef?.current?.focus();
    e.stopPropagation();
  };

  const handleItemButtonKeyDown =
    (index: number) => (e: KeyboardEvent<HTMLButtonElement>) => {
      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
        e.preventDefault();
      }

      if (e.key === "ArrowUp" && index !== 0) {
        itemRefs[index - 1]?.current?.focus();
        onChange?.(options[index - 1].value);
      }

      if (e.key === "ArrowDown" && index !== itemRefs.length - 1) {
        itemRefs[index + 1]?.current?.focus();
        onChange?.(options[index + 1].value);
      }

      if (e.key === "Escape") {
        setIsOpen(false);
        handlerRef?.current?.focus();
      }
    };

  const handleHandlerKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
      if (onClick) {
        setIsOpen(true);
      } else if (value) {
        // TODO: fix bug when value is falsy
        const index = options.findIndex((option) => option.value === value);

        if (e.key === "ArrowUp" && index !== 0) {
          onChange?.(options[index - 1].value);
        } else if (index !== options.length - 1) {
          onChange?.(options[index + 1].value);
        }
      } else {
        onChange?.(options[0].value);
      }
    }

    if (e.key === "Escape") {
      setIsOpen(false);
      handlerRef?.current?.focus();
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (value) {
        const selectedIndex = options.findIndex(
          (option) => option.value === value
        );
        itemRefs[selectedIndex]?.current?.focus();
      } else {
        itemRefs[0]?.current?.focus();
      }
    }
  }, [isOpen, itemRefs, value, options]);

  const handleBlur = (e: FocusEvent<HTMLElement>) => {
    if (e.relatedTarget) {
      const relatedTarget = e.relatedTarget as HTMLElement;
      const shouldOpen =
        relatedTarget?.getAttribute("data-dropdown") === id.current;
      if (!shouldOpen) {
        setIsOpen(false);
      }
    } else {
      setIsOpen(false);
    }
  };

  const itemProps: DropdownItemProps<T> = (option, index) => ({
    "data-dropdown": id.current,
    ref: itemRefs[index],
    onKeyDown: handleItemButtonKeyDown(index),
    onClick: handleChoice(option.value),
    onBlur: handleBlur,
  });

  return {
    id,
    isOpen,
    itemRefs,
    handlerRef,
    toggleIsOpen,
    handleChoice,
    handleItemButtonKeyDown,
    handleHandlerKeyDown,
    handleBlur,
    handlerProps: {
      "data-dropdown": id.current,
      ref: handlerRef,
      onClick: toggleIsOpen,
      onBlur: handleBlur,
      onKeyDown: handleHandlerKeyDown,
    },
    itemProps,
  };
}

const borderWidth = 1;
const itemVerticalPadding = 4;

export const DropdownContainer = styled.ul`
  position: absolute;
  min-width: 100%;
  z-index: 11;
  top: 100%;
  margin-top: 4px;
  background: white;
  list-style: none;
  border: ${borderWidth}px solid black;
  border-radius: 8px;
  overflow: auto;
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.3);
  right: 0;
`;

export const DropdownItem = styled.li`
  :first-child > button {
    padding-top: ${itemVerticalPadding - borderWidth}px;
  }

  :last-child > button {
    padding-bottom: ${itemVerticalPadding - borderWidth}px;
  }
`;
