import { useState, CSSProperties } from "react";
import { DragStartEvent, DragEndEvent, DragOverEvent, ViewRect, LayoutRect } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS as dndCss } from "@dnd-kit/utilities";

export type OnChange<O extends {}> = <T extends keyof O>(
  key: T
) => (value: O[T]) => void;

type UseSimpleDragAndDropProps<T extends { id: string }> = {
  items: T[];
  onChangeOrder: (index: number, id: string) => void;
};

export function useSimpleDragAndDrop<T extends { id: string }>({
  items,
  onChangeOrder,
}: UseSimpleDragAndDropProps<T>) {
  const [activeId, setActiveId] = useState<string | undefined>();
  const activeItem: T | undefined = activeId
    ? items.find((item) => item.id === activeId)
    : undefined;

  const onDragStart = (e: DragStartEvent) => setActiveId(e.active.id);
  const onDragEnd = ({ over }: DragEndEvent) => {
    setActiveId(undefined);

    if (over && over.id !== activeId && activeId) {
      const overIndex = items.findIndex(({ id }) => id === over.id);
      onChangeOrder(overIndex, activeId);
    }
  };
  const onDragCancel = () => setActiveId(undefined);

  return {
    activeId,
    activeItem,
    dndContextProps: {
      onDragStart,
      onDragEnd,
      onDragCancel,
    },
  };
}

type UseDndAttributesProps = {
  id: string;
  active: boolean;
};

export function useDndAttributes({ id, active }: UseDndAttributesProps) {
  const { transition, transform, attributes, listeners, setNodeRef } =
    useSortable({ id });

  const style: CSSProperties = {
    transform: dndCss.Transform.toString(transform),
    transition: transition || undefined,
    opacity: active ? 0.3 : 1,
  };

  const itemProps = { style, ref: setNodeRef };
  const handleProps = { ...attributes, ...listeners };

  return {
    itemProps,
    handleProps,
  };
}

type UseComplexDragAndDropProps<T extends { id: string }> = {
  items: { [key: string]: T[] };
  onChangeOrder: (
    index: number,
    id: string,
    key: string,
    oldKey: string
  ) => void;
};

const getMiddle = ({ offsetTop, height }: ViewRect | LayoutRect) => offsetTop + height * 0.5

export function useComplexDragAndDrop<T extends { id: string; label: string }>({
  items,
  onChangeOrder,
}: UseComplexDragAndDropProps<T>) {
  type Container = undefined | string;
  const [activeId, setActiveId] = useState<undefined | string>();
  const [saved, setSaved] = useState<
    { key: string; index: number } | undefined
  >();

  const itemKeys = Object.keys(items);
  const findContainer = (id?: string): Container => {
    if (id && id in items && items[id].length === 0) {
      return id
    }

    return itemKeys.find((key) => items[key].findIndex((item) => item.id === id) > -1);
  }

  const activeContainer = findContainer(activeId);

  const activeItem: T | undefined =
    activeId && activeContainer
      ? items[activeContainer].find((item) => item.id === activeId)
      : undefined;

  const onDragStart = ({ active }: DragStartEvent) => {
    setActiveId(active.id);
    const key = findContainer(active.id);
    if (key) {
      const index = items[key].findIndex(({ id }) => id === active.id);
      setSaved({ key, index });
    }
  };
  const onDragEnd = ({ over }: DragEndEvent) => {
    setActiveId(undefined);
    setSaved(undefined);

    if (over && over.id !== activeId && activeId && activeContainer) {
      const overContainer = findContainer(over.id);

      if (overContainer && overContainer === activeContainer) {
        const overIndex = items[overContainer].findIndex(
          ({ id }) => id === over.id
        );

        onChangeOrder(overIndex, activeId, overContainer, activeContainer);
      }
    }
  };
  const onDragCancel = () => {
    if (activeId) {
      setActiveId(undefined);
      const oldKey = findContainer(activeId);
      if (saved && oldKey) {
        const { key, index } = saved;
        onChangeOrder(index, activeId, key, oldKey);
      }
    }
  };

  const onDragOver = ({ active, over }: DragOverEvent) => {
    if (over && over.id !== activeId && activeId && activeContainer) {
      const overContainer = findContainer(over.id);

      if (false) {

      } else if (overContainer && overContainer !== activeContainer) {
        const overItems = items[overContainer];
        const overIndex = overItems.findIndex(({ id }) => id === over.id);
        const isOverLastItem = overIndex === overItems.length - 1

        let newIndex: number = 0;

        if (active.rect.current.translated && isOverLastItem) {
          const overMiddle = getMiddle(over.rect)
          const activeMiddle = getMiddle(active.rect.current.translated)

          const isBelowLastItem = overMiddle < activeMiddle

          const modifier = isBelowLastItem ? 1 : 0;
                    
          newIndex = overIndex + modifier;
        } else {
          newIndex = overIndex
        }

        onChangeOrder(newIndex, activeId, overContainer, activeContainer);
      }
    }
  };

  return {
    activeId,
    activeItem,
    activeContainer,
    dndContextProps: {
      onDragStart,
      onDragEnd,
      onDragCancel,
      onDragOver,
    },
  };
}
