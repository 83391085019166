import { v4 as uuid } from "uuid";

import { CharacterFeatureType, SpellType, ClassType, ArmorType, WeaponType, EquipmentNoteType } from "types";

export const create = {
  feature: (): CharacterFeatureType => ({
    id: uuid(),
    label: "",
    text: "",
  }),
  class: (): ClassType => ({
    id: uuid(),
    className: "",
    subClass: "",
    level: 1,
  }),
  spell: (): SpellType => ({
    id: uuid(),
    level: 0,
    name: "",
    description: "",
    reference: "",
  }),
  armor: (): ArmorType => ({
    id: uuid(),
    name: "",
    armorClass: 10,
    stealthDisadvantage: false,
    notes: []
  }),
  weapon: (): WeaponType => ({
    id: uuid(),
    name: "",
    attackBonus: 0,
    ranged: false,
    versatile: false,
    damage: {amount: 1, die: "d6" },
    damageBonus: 0,
    damageType: "slashing",
    notes: []
  }),
  equipmentNote: (): EquipmentNoteType => ({
    id: uuid(),
  }),
};
