import { useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import styled from "styled-components";

import { auth } from "./authentication"

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 16px;
  background-color: #eeeeee;
  border-radius: 32px;
  padding: 32px;
  min-width: 320px;
  margin: auto;
  width: 400px;
`;

export const LoginText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 16px;
  line-height: 20px;
`

export const LoginLink = styled(Link)`
  color: #000;
  :hover {
    color: #444;
  }
  :active {
    color: #888;
  }
`

export const Title = styled.h1`
  span {
    line-height: 1;
    display: block;
    font-size: 20px;
    margin: auto;
    width: auto;
    margin-bottom: 8px;
  }
  line-height: 1;
  margin-bottom: 8px;
  font-size: 32px;
`

export  const useAuthStatus = () => {
  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();
  useEffect(() => {
    if (error) {
      // do something with the error
      console.error(error)
      return;
    }
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) history.replace("/");
  }, [user, loading, history, error]);
  return {
    user,
    loading,
    error,
  }
}
