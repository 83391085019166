import React, { ChangeEvent, useLayoutEffect, useRef } from "react";
import styled from "styled-components";

import { inputStyles, Container, Label } from "../styles";
import { BaseTextAreaProps } from "../types";

export type TextAreaProps<T extends string | undefined> = BaseTextAreaProps & {
  type?: "password" | "email";
  onChange: (value: T, event: ChangeEvent<HTMLTextAreaElement>) => void;
  label?: string;
  internalLabel?: boolean;
  value: T;
};

const TextAreaInput = styled.textarea<{ internalLabel?: boolean }>`
  ${inputStyles};
  resize: none;
`;

export function TextArea<T extends string | undefined> ({
  label,
  onChange,
  value,
  internalLabel,
  ...props
}: TextAreaProps<T>) {
  const ref = useRef<HTMLTextAreaElement>(null);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value as T, e);
  };

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.style.height = "0px";
      ref.current.style.height = `${ref.current.scrollHeight + 4}px`;
    }
  }, [value, ref]);

  return (
    <Container>
      {label && <Label internal={internalLabel}>{label}</Label>}
      <TextAreaInput
        {...props}
        internalLabel={internalLabel}
        ref={ref}
        value={value}
        onChange={handleChange}
      />
    </Container>
  );
};
