import { FC } from "react";
import { DiceType } from "types";
import { Resources } from "../../resources"

export const HitDice: FC<{ hitDice: DiceType[] }> = ({ hitDice }) => (
  <Resources
    label="hit dice"
    resources={[
      {
        label: "total",
        value: hitDice.map(({ amount, die }) => `${amount} ${die}`)
      },
      {
        label: "used"
      }
    ]}
  />
);
