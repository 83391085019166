import { FC } from "react";
import styled from "styled-components";

import { SpellSlotType, SpellSlotsType } from "types";
import { getOrdinal, mapNTimes, splitIntoWholeFractions } from "utils";

const SpellSlotsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
`;

const SpellSlotsColumn = styled.div`
  display: flex;
  justify-content: center;

  flex-wrap: wrap;

  gap: 4px 8px;
`;

const SpellSlotRow = styled.div`
  display: flex;
  align-items: center;

  gap: 4px;
`;

const SpellSlotLevelLabel = styled.div`
  font-size: 14px;
  line-height: 1;
  width: 20px;
  margin-right: 4px;
  text-align: right;
`;

const SpellSlotLevelContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 60px;
  gap: 4px;
`;

const SpellSlot = styled.div`
  height: 12px;
  width: 12px;
  border: 1px solid black;
  border-radius: 50%;
`;

type SpellSlotsProps = {
  spellSlots: SpellSlotsType;
  oneRow?: boolean;
  columns?: 2 | 3;
};

export const SpellSlots: FC<SpellSlotsProps> = ({
  spellSlots,
  oneRow = false,
  columns = 2
}) => {
  let spellsPerColumn: SpellSlotType[][] = [];

  if (spellSlots.length > 3 && !oneRow) {
    // Split spell slots into arrays equaling the amount of numbers
    spellsPerColumn = splitIntoWholeFractions<SpellSlotType>(
      spellSlots,
      columns
    );
  } else {
    spellsPerColumn = [spellSlots];
  }

  const columnStyle = {
    flexDirection: oneRow ? "row" : "column",
  } as const;

  return (
    <SpellSlotsContainer>
      {spellsPerColumn.map((spellSlotsFraction, columnNumber) => (
        <SpellSlotsColumn key={columnNumber} style={columnStyle}>
          {spellSlotsFraction.map(({ level, amount }) => (
            <SpellSlotRow key={level}>
              <SpellSlotLevelLabel>{getOrdinal(level)}</SpellSlotLevelLabel>
              <SpellSlotLevelContainer>
                {mapNTimes(amount, (i) => (
                  <SpellSlot key={`${level}${i}`} />
                ))}
              </SpellSlotLevelContainer>
            </SpellSlotRow>
          ))}
        </SpellSlotsColumn>
      ))}
    </SpellSlotsContainer>
  );
};
