import { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import {
  selectAbilityScores,
  changeAbilityScore,
  selectSavingThrows,
  changeSkill,
} from "features/character";
import { NewNumberInput, Checkbox } from "components";

const AbilitiesContainer = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: auto auto 30% auto;
  gap: 16px 32px;
  align-items: center;
`;

const Label = styled.p<{ align?: "right" | "center" }>`
  font-size: 12px;

  ${({ align }) => (align ? `text-align: ${align};` : "")}
`;

const Modifier = styled.p`
  text-align: right;
`;


export const Abilities = () => {
  const dispatch = useDispatch();
  const abilityScores = useSelector(selectAbilityScores);
  const savingThrows = useSelector(selectSavingThrows);
  const setAbilityScore = (index: number) => (value = 0) => {
    dispatch(changeAbilityScore({ index, value }));
  };

  const setSavingThrow = (id: string) => (proficient: boolean) => {
    dispatch(
      changeSkill({ id, value: proficient ? 1 : 0 })
    );
  };

  return (
    <AbilitiesContainer>
      <Label>Name</Label>
      <Label align="right">Modifier</Label>
      <Label align="right">Score</Label>
      <Label align="center">Save Proficiency</Label>
      {abilityScores.map(({ name, id, value, modifier }, i) => {
        const savingThrow = savingThrows.find(({ ability }) => ability === id);

        return (
          <Fragment key={id}>
            <label htmlFor={id}>{name}</label>
            <Modifier>{modifier}</Modifier>
            <NewNumberInput
              style={{ textAlign: "right" }}
              size="small"
              key={id}
              id={id}
              value={value}
              onChange={setAbilityScore(i)}
            />
            {savingThrow && (
              <Checkbox
                style={{ justifyContent: "center" }}
                onChange={setSavingThrow(savingThrow.id)}
                value={savingThrow.proficiencyMultiplier === 1}
              />
            )}
          </Fragment>
        );
      })}
    </AbilitiesContainer>
  );
};
