import { PayloadAction } from "@reduxjs/toolkit";

import { AllModulesKeys } from "components/character-details/sheet/sheet-modules";
import { MovedModule, SheetType } from "types";

type BaseReducer<T> = (state: SheetType, action: T) => void;
type SheetReducer<T = undefined> = BaseReducer<PayloadAction<T>>;

export const changeDraggedModule: SheetReducer<AllModulesKeys | undefined> = (
  state,
  action
) => {
  state.draggedModule = action.payload;
};

export const changeMovedModule: SheetReducer<MovedModule | undefined> = (
  state,
  action
) => {
  state.movedModule = action.payload;
};

export const setActiveModule: SheetReducer<string | false> = (
  state,
  action
) => {
  if (action.payload !== state.activeModule) {
    state.styleSelectActive = false;
    state.activeModule = action.payload;
    if (action.payload) {
      state.windowCloseQueue = ["activeModule"];
    }
  }
};

export const setStyleSelectActive: SheetReducer<boolean> = (state, action) => {
  state.styleSelectActive = action.payload;
  if (action.payload) {
    state.windowCloseQueue.push("styleSelectActive");
  } else {
    const styleSelectIndex =
      state.windowCloseQueue.indexOf("styleSelectActive");

    if (styleSelectIndex !== -1) {
      state.windowCloseQueue = state.windowCloseQueue.slice(
        0,
        styleSelectIndex
      );
    }
  }
};

export const closeWindow: SheetReducer = (state) => {
  if (state.windowCloseQueue.length) {
    const closeAction = state.windowCloseQueue.pop();
    if (closeAction) {
      state[closeAction] = false;
    }
  }
};
