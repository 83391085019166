import styled from "styled-components";
import { Border } from "components";
import { statColorMixin } from "styles";

const abilityScoreSmallHeight = "2.5em";
const abilityScoreBlockBorderWidth = "1px";

export const AbilityScoresColumn = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: space-around;
  line-height: 1;
  padding-bottom: calc(${abilityScoreSmallHeight} / 2 - ${abilityScoreBlockBorderWidth});
`;

export const AbilityScoreBlock = styled(Border)`
  padding: 0.5em 0.5em 0 0.5em;
  min-width: 6.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  :not(:last-child) {
    margin-bottom: calc(${abilityScoreSmallHeight} / 2 - ${({ borderWidth }) => borderWidth / 2}px + 0.5em);;
  }
`;

export const AbilityScoreName = styled.div`
  margin-bottom: 0.5em;
`;

export const AbilityScoreLarge = styled.div`
  margin-bottom: 0.25em;
  font-size: 2em;
  ${statColorMixin};
`;

export const AbilityScoreSmall = styled(Border)`
  position: relative;
  width: 100%;
  z-index: 1;
  width: calc(100% - 1em * 2);
  height: ${abilityScoreSmallHeight};

  margin-bottom: calc(-${abilityScoreSmallHeight} / 2 - 4px);
  display: flex;
  align-items: center;
  justify-content: center;
  fill: white;

  ${statColorMixin};
`;
