import { useState } from "react";

import { TextInput, Button } from "components";

import { sendPasswordResetEmail } from "./authentication";
import {
  useAuthStatus,
  LoginContainer,
  LoginText,
  LoginLink,
  Title,
} from "./common";

export const Reset = () => {
  const [email, setEmail] = useState("");

  const onSubmit = () => sendPasswordResetEmail(email);

  useAuthStatus();

  return (
    <LoginContainer>
      <Title>Reset password</Title>
      <TextInput
        type="email"
        value={email}
        onChange={setEmail}
        placeholder="Email address"
      />
      <Button onClick={onSubmit}>Send password reset email</Button>
      <LoginText>
        <p>
          Don't have an account? <LoginLink to="/register">Register</LoginLink>{" "}
          now.
        </p>
      </LoginText>
    </LoginContainer>
  );
};
