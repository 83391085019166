import { useState, useEffect } from "react";
import { useParams, Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  useFetchCharacter,
  useDeleteCharacter,
  useSaveCharacter,
} from "firebase-utils";
import { selectIsCharacterCached, selectCachedCharacter } from "features/ui";
import { selectCharacter, initializeCharacter } from "features/character";

import { CharacterSheet } from "./sheet";
import { CharacterHeader } from "./header";
import { Stats } from "./stats";

export { CharacterHeader };
export * from "./stats";

const usePosition = () => {
  const [shouldInit, setShouldInit] = useState(true);
  const [scale, onZoom] = useState(1);
  const [moved, onMove] = useState<[number, number]>([0, 0]);

  return { shouldInit, scale, moved, onZoom, onMove, onInit: setShouldInit };
};

export const Character = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [deleteCharacter, isDeleting] = useDeleteCharacter();
  const [saveCharacter, isSaving, isDone] = useSaveCharacter();
  const [showIsDone, setShowIsDone] = useState(false);
  const hasFetched = useSelector(selectIsCharacterCached(id));
  const cachedCharacter = useSelector(selectCachedCharacter(id));
  const character = useSelector(selectCharacter);

  const shouldNotFetch = hasFetched || isDeleting;
  const [isLoading] = useFetchCharacter(shouldNotFetch, id);

  useEffect(() => {
    if (hasFetched) {
      dispatch(initializeCharacter(cachedCharacter));
    }
  }, [hasFetched, cachedCharacter, dispatch]);

  useEffect(() => {
    if (isDone) {
      setShowIsDone(true);
      setTimeout(() => {
        setShowIsDone(false);
      }, 500);
    }
  }, [isDone]);

  const onSave = () => {
    if (!isSaving) {
      saveCharacter(character);
    }
  };

  const onDelete = () => {
    if (!isDeleting) {
      deleteCharacter(character.id);
    }
  };

  const sheetProps = usePosition();

  return (
    <>
      <CharacterHeader
        name={isLoading ? "loading..." : character.base.name}
        showIsDone={showIsDone}
        isSaving={isSaving}
        isDeleting={isDeleting}
        onSave={onSave}
        onDelete={onDelete}
      />
      <Switch>
        <Route path="/:id/stats">
          <Stats />
        </Route>
        <Route path="/:id/sheet">
          <CharacterSheet {...sheetProps} isLoading={isLoading} />
        </Route>
      </Switch>
    </>
  );
};
