import React, { forwardRef } from "react";
import styled, { css } from "styled-components";

import { useHideFocusOnClick } from "components/internal"
import {
  noFocusMixin,
  focusRingMixin,
  buttonColors as colors,
  buttonStyles as styles,
} from "styles";


type ButtonElementProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const alignments = {
  start: "flex-start",
  end: "flex-end",
  center: "center",
  stretch: "strech"
} as const

type AlignKeys = keyof typeof alignments

type ButtonProps = ButtonElementProps & {
  fullWidth?: boolean;
  size?: "small"
  alignSelf?: AlignKeys
};

const align = ({ alignSelf }: ButtonProps) => !alignSelf ? "" : `align-self: ${alignments[alignSelf]};`

const buttonBaseStyles = css`
  cursor: pointer;
  line-height: 24px;
  font-size: 16px;

  background-color: ${colors.background};
  border-color: ${colors.border};
  color: ${colors.text};

  &:hover {
    background-color: ${colors.hoverBackground};
  }

  &:active {
    background-color: ${colors.activeBackground};
  }

  border-width: ${styles.borderWidth};
  border-style: solid;
`;

const ButtonOuter = styled.button.attrs(({ type = "button" }) => ({
  type,
}))<ButtonProps & { hideFocus?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  ${buttonBaseStyles};

  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  
  ${align};

  border-radius: ${styles.borderRadius};

  ${({ size }) => size === "small" ? `
    min-height: 32px;
    padding: 2px ${styles.horizontalPadding};
  ` : `
    min-height: ${styles.height};
    padding: ${styles.verticalPadding} ${styles.horizontalPadding};
  `}


  &:focus {
    ${noFocusMixin}

    &:before {
      ${({ hideFocus }) => (!hideFocus ? focusRingMixin() : "")}
    }
  }
`;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...props }, ref) => {
    const hideFocusProps = useHideFocusOnClick(props);

    return (
      <ButtonOuter {...props} {...hideFocusProps} ref={ref}>
        {children}
      </ButtonOuter>
    );
  }
);

type IconButtonProps = ButtonElementProps & {
  size?: "small" | "medium";
};

const IconButtonOuter = styled.button<IconButtonProps>`
  position: relative;
  height: ${({ size }) => (size === "small" ? "32" : "40")}px;
  width: ${({ size }) => (size === "small" ? "32" : "40")}px;
  min-width: ${({ size }) => (size === "small" ? "32" : "40")}px;
  border-radius: 50%;

  ${buttonBaseStyles};

  &:focus {
    ${noFocusMixin}

    &:before {
      ${focusRingMixin({ radius: "50%" })}
    }
  }
`;

export const IconButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...props }, ref) => {
    const hideFocusProps = useHideFocusOnClick(props);

    return (
      <IconButtonOuter {...props} {...hideFocusProps} ref={ref}>
        {children}
      </IconButtonOuter>
    );
  }
);

const FloatingButtonOuter = styled(IconButtonOuter)`
  position: absolute;
  z-index: 10;
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.3);
`;

export const FloatingButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...props }, ref) => {
    const hideFocusProps = useHideFocusOnClick(props);

    return (
      <FloatingButtonOuter {...props} {...hideFocusProps} ref={ref}>
        {children}
      </FloatingButtonOuter>
    );
  }
);
