import { useSelector, useDispatch } from "react-redux";

import { selectEquipment, changeWeapon } from "features/character";
import {
  TextInput,
  NewNumberInput,
  InputGroup,
  Button,
  TextArea,
  Select,
  DiceInput,
} from "components";
import { WeaponType, DiceType, damageTypes } from "types";
import {
  EquipmentHandler,
  EquipmentNoteHandler,
  EquipmentContainer,
  EquipmentNotesContainer,
} from "./common";

const weaponLabels = {
  name: "Name",
  range: "Range",
  damage: "Damage",
  damageType: "Damage type",
  attackBonus: "Attack bonus",
  damageBonus: "Damage bonus",
  versatile: "Versatile",
} as const;

type WeaponHandler = EquipmentHandler<WeaponType>
type WeaponDamageHandler = EquipmentHandler<DiceType>

const damageOptions = damageTypes.map((damage) => ({ label: damage, value: damage }))

export const Weapons = () => {
  const dispatch = useDispatch();
  const { weapons } = useSelector(selectEquipment);

  const onAddWeapon = () => {
    dispatch(changeWeapon.add());
  };

  const onWeaponChange: WeaponHandler = (key, index) => (value) => {
    dispatch(changeWeapon.change({ index, key, value }));
  };

  const onWeaponDamageChange: WeaponDamageHandler = (key, index) => (value) => {
    dispatch(changeWeapon.changeDamage({ index, key, value }));
  };

  const onAddWeaponNote = (equipmentIndex: number) => () => {
    dispatch(changeWeapon.addNote(equipmentIndex));
  };

  const onWeaponNoteChange: EquipmentNoteHandler =
    (equipmentIndex, key, index) => (value) => {
      dispatch(changeWeapon.changeNote({ equipmentIndex, index, key, value }));
    };

  return (
    <EquipmentContainer>
      {weapons.map((weapon, i) => (
        <InputGroup.Container key={weapon.id}>
          <InputGroup.Row>
            <TextInput
              internalLabel
              label={weaponLabels.name}
              value={weapon.name}
              onChange={onWeaponChange("name", i)}
            />
            <NewNumberInput
              internalLabel
              label={weaponLabels.attackBonus}
              value={weapon.attackBonus}
              onChange={onWeaponChange("attackBonus", i)}
            />
          </InputGroup.Row>
          <InputGroup.Row widths={{  }}>
            <DiceInput
              internalLabel
              label={weaponLabels.damage}
              value={weapon.damage.amount}
              diceValue={weapon.damage.die}
              onChange={onWeaponDamageChange("amount", i)}
              onChangeDice={onWeaponDamageChange("die", i)}
            />
            <NewNumberInput
              internalLabel
              label={weaponLabels.damageBonus}
              value={weapon.damageBonus}
              onChange={onWeaponChange("damageBonus", i)}
            />
            <Select
              options={damageOptions}
              internalLabel
              label={weaponLabels.damageType}
              value={weapon.damageType}
              onChange={onWeaponChange("damageType", i)}
            />
            <InputGroup.Checkbox
              label={weaponLabels.versatile}
              value={weapon.versatile}
              onChange={onWeaponChange("versatile", i)}
            />
          </InputGroup.Row>
          <EquipmentNotesContainer>
            {weapon.notes.map((note, noteIndex) => (
              <InputGroup.Container key={note.id}>
                <InputGroup.Row>
                  <TextInput
                    internalLabel
                    label="title"
                    value={note.title}
                    onChange={onWeaponNoteChange(i, "title", noteIndex)}
                  />
                </InputGroup.Row>
                <InputGroup.Row>
                  <TextArea
                    internalLabel
                    label="text"
                    value={note.text}
                    onChange={onWeaponNoteChange(i, "text", noteIndex)}
                  />
                </InputGroup.Row>
              </InputGroup.Container>
            ))}
            <Button alignSelf="start" size="small" onClick={onAddWeaponNote(i)}>
              Add weapon note
            </Button>
          </EquipmentNotesContainer>
        </InputGroup.Container>
      ))}
      <Button alignSelf="start" size="small" onClick={onAddWeapon}>
        Add weapon
      </Button>
    </EquipmentContainer>
  );
};
