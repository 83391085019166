import { FC, Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Switch,
  Route,
  Link,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import styled from "styled-components";

import { SidebarContainer } from "components";
import { changeLastStatTab } from "features/ui";

import { CharacterTabs } from "../common";
import { Abilities } from "./abilities";
import { Spells } from "./spells";
import { Skills } from "./skills";
import { Base } from "./base";
import { CombatStats } from "./combat-stats";
import { Class } from "./class";
import { Armor, Weapons } from "./equipment";
import { Proficiencies } from "./proficiencies";
import { Spellcasting } from "./spellcasting";

const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 640px;
  margin: 0 auto;
`;

const ContentContainer = styled.div`
  width: 100%;
  padding-right: 240px;
  overflow: auto;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 65px);
`;

const SectionHeader = styled.h2`
  margin-top: 32px;
  margin-bottom: 32px;
`;

const SidebarLink = styled(Link)`
  width: 100%;
  padding: 16px 24px;

  font-size: 20px;
  text-decoration: none;
  background-color: #fff;

  :hover {
    background-color: #eee;
  }

  :active {
    background-color: #ddd;
  }

  color: black;

  :visited {
    color: black;
  }
  display: block;
`;

type Section = { Component: FC; sectionTitle?: string };
type StatBlock = { title: string; sections: Section[] };

const statBlocks: { [key: string]: StatBlock } = {
  "": { title: "Core features", sections: [{ Component: Base }] },
  "/class": { title: "Class", sections: [{ Component: Class }] },
  "/combat-stats": {
    title: "Combat stats",
    sections: [{ Component: CombatStats }],
  },
  "/abilities-and-skills": {
    title: "Abilities & Skills",
    sections: [
      { Component: Abilities, sectionTitle: "Abilities" },
      { Component: Skills, sectionTitle: "Skills" },
    ],
  },
  "/spellcasting": {
    title: "Spellcasting",
    sections: [
      { Component: Spellcasting, sectionTitle: "Spellcasting stats" },
      { Component: Spells, sectionTitle: "Spells" }
    ]
  },
  "/equipment": {
    title: "Equipment",
    sections: [
      { Component: Armor, sectionTitle: "Armor" },
      { Component: Weapons, sectionTitle: "Weapons" },
    ],
  },
  "/proficiencies": {
    title: "Proficiencies",
    sections: [{ Component: Proficiencies }],
  },
};

const statPaths: (keyof typeof statBlocks)[] = [
  "",
  "/class",
  "/abilities-and-skills",
  "/combat-stats",
  "/spellcasting",
  "/proficiencies",
  "/equipment",
];

export const Stats = () => {
  let { path, url } = useRouteMatch();
  let { pathname } = useLocation();
  let dispatch = useDispatch();

  useEffect(() => {
    const splitPath = pathname.split("/");
    const lastPart = splitPath[splitPath.length - 1];
    dispatch(changeLastStatTab(lastPart === "stats" ? "" : `/${lastPart}`));
  });

  return (
    <PageContainer>
      <SidebarContainer>
        <CharacterTabs />
        <ul>
          {statPaths.map((statPath) => (
            <li key={statPath}>
              <SidebarLink to={`${url}${statPath}`}>
                {statBlocks[statPath].title}
              </SidebarLink>
            </li>
          ))}
        </ul>
      </SidebarContainer>
      <ContentContainer>
        <StatsContainer>
          <Switch>
            {statPaths.map((statPath) => {
              const { title, sections } = statBlocks[statPath];
              return (
                <Route exact key={statPath} path={`${path}${statPath}`}>
                  {sections.map(({ Component, sectionTitle = title }) => (
                    <Fragment key={sectionTitle}>
                      <SectionHeader>{sectionTitle}</SectionHeader>
                      <Component />
                    </Fragment>
                  ))}
                </Route>
              );
            })}
          </Switch>
        </StatsContainer>
      </ContentContainer>
    </PageContainer>
  );
};
