import { FC } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Button } from "components";

const CharacterHeaderContainer = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid black;

  @media print {
    display: none;
  }
`;

const Logo = styled(Link)`
  font-size: 16px;
  width: 300px;
  color: black;
  text-decoration: none;
  :before {
    content: "🠔 ";
  }
`;

const HeaderControls = styled.div`
  width: 300px;
  display: flex;
  justify-content: flex-end;
  padding-right: 80px;
  gap: 16px;
`;

const CharacterName = styled.h1`
  font-size: 20px;
`;

type CharacterHeaderProps = {
  name: string;
  onSave: () => void;
  onDelete: () => void;
  isDeleting: boolean;
  isSaving: boolean;
  showIsDone: boolean;
};

export const CharacterHeader: FC<CharacterHeaderProps> = ({
  name,
  onSave,
  onDelete,
  isDeleting,
  isSaving,
  showIsDone,
}) => (
  <CharacterHeaderContainer>
    <Logo to="/">Back to characters</Logo>
    <CharacterName>{name}</CharacterName>
    <HeaderControls>
      <Button size="small" onClick={onSave}>
        Save {isSaving && " (Saving)"} {showIsDone && " (Success)"}
      </Button>
      <Button size="small" onClick={onDelete}>
        Delete {isDeleting && " (Deleting)"}
      </Button>
    </HeaderControls>
  </CharacterHeaderContainer>
);
