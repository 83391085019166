import { SpellcastingType } from "types";

import { SimpleChangeReducer, CharacterReducer } from "./types";

type ChangeSpellSlotAmount = CharacterReducer<{ index: number; value: number }>;

export const changeSpellcasting: SimpleChangeReducer<SpellcastingType> = (
  state,
  action
) => {
  const { key, value } = action.payload;
  state.spellcasting[key] = value;
};

export const changeSpellSlotAmount: ChangeSpellSlotAmount = (state, action) => {
  const { index, value } = action.payload;
  state.spellcasting.spellSlots[index].amount = value;
};

export const addSpellSlot: CharacterReducer = (state) => {
  const spellSlots = state.spellcasting.spellSlots;
  const level = spellSlots[spellSlots.length - 1]?.level + 1 || 1;
  state.spellcasting.spellSlots.push({ level, amount: 1 });
};

export const removeLastSpellSlot: CharacterReducer = (state) => {
  state.spellcasting.spellSlots.pop();
};
