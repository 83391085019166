import styled, { css } from "styled-components";
import { statColorMixin } from "styles";

export const SpellListContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const SpellListLabelRow = styled.div`
  display: flex;
  width: 100%;
`;

const SpellListLabel = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  &:not(:last-child) {
    margin-right: 8px;
  }
`;

const spellListColumnWidths = {
  level: css`
    flex-basis: 20px;
  `,
  main: css`
    flex-basis: 0;
    flex-grow: 1;
  `,
  reference: css`
    flex-basis: 32px;
  `,
};

export const SpellListLabelLevel = styled(SpellListLabel)`
  ${spellListColumnWidths.level};
`;
export const SpellListLabelMain = styled(SpellListLabel)`
  ${spellListColumnWidths.main};
`;
export const SpellListLabelReference = styled(SpellListLabel)`
  ${spellListColumnWidths.reference};
`;

export const SpellListItem = styled.div`
  display: flex;
  width: 100%;
  padding: 4px 0;
  min-height: 40px;
  align-items: center;
  ${statColorMixin};

  &:not(:last-child) {
    border-bottom: 1px solid grey;
  }

  p:not(:last-child) {
    margin-right: 8px;
  }

  font-size: 14px;
`;

export const VerticalDivider = styled.span`
  :after {
    content: "|";
    line-height: 12px;
    font-size: 16px;
    font-weight: 600;
    margin: 0 4px;
  }
`;
export const SpellListItemLevel = styled.p`
  ${spellListColumnWidths.level};
  font-weight: bold;
  text-align: center;
`;
export const SpellListItemName = styled.span`
  float: left;
  font-weight: bold;

  &:not(:last-of-type):after {
    content: "|";
    margin: 0 4px;
  }
`;
export const SpellListItemMain = styled.p`
  ${spellListColumnWidths.main};
`;
export const SpellListItemReference = styled.p`
  ${spellListColumnWidths.reference};
  font-size: 12px;
  text-transform: uppercase;
`;

const line = `
<svg viewBox="0 0 10 40" xmlns="http://www.w3.org/2000/svg">
  <line stroke="black" x1="0" y1="40" x2="10" y2="40" />
</svg>
`;
export const ExtraItemsBackground = styled.div`
  width: 100%;
  flex-grow: 1;
  background: url(${`'data:image/svg+xml;utf8,${line}'`});
  background-repeat: repeat;
  background-size: 10px 40px;
  background-position: top;
`;
