import {
  FC,
  useEffect,
  useState,
  useCallback,
  createElement,
} from "react";
import { useSelector, useDispatch } from "react-redux";

import { drag, selectDrag } from "features/module-drag";
import { SheetModule } from "types";

import {
  ModuleContainerBackground,
  ModuleContainerShadow,
} from "../module-container/styles";
import { MoveButton } from "../module-container/menus";
import { AllModules } from "../sheet-modules";

import {
  ModuleDropZoneBefore,
  ModuleDropZoneAfter,
  DragOverlayContainer,
  DraggedContainerWrapper,
  DraggedModuleContainerGhost,
  DraggedModuleInner,
  DraggedModuleUpScaler
} from "./styles";
import { changeModule, selectModule } from "features/character";

export const ModuleDropZones: FC<{ area: string; index: number; id: string }> =
  ({ index, area, id }) => {
    const [ready, setReady] = useState(true);
    const dispatch = useDispatch();

    const isDragging = useSelector(selectDrag.isDragging);
    const currentIndex = useSelector(selectDrag.index);
    const currentArea = useSelector(selectDrag.area);
    const prepareEnd = useSelector(selectDrag.prepareEnd);

    const enterBefore = () => {
      if (ready && (currentArea !== area || currentIndex !== index)) {
        dispatch(drag.changeArea(area));
        dispatch(drag.changeIndex(index));
        dispatch(drag.changePlacementNode({ id, placement: "before" }));
        setReady(false);
      }
    };

    const enterAfter = () => {
      if (ready && (currentArea !== area || currentIndex !== index + 1)) {
        const newArea = currentArea !== area;
        dispatch(drag.changeArea(area));
        dispatch(drag.changeIndex(index + 1));
        dispatch(drag.changePlacementNode({ id, placement: "after", newArea }));
        setReady(false);
      }
    };

    useEffect(() => {
      if (!ready) {
        setTimeout(() => {
          setReady(true);
        }, 160);
      }
    }, [ready]);

    return isDragging && !prepareEnd ? (
      <>
        <ModuleDropZoneBefore onMouseEnter={enterBefore} />
        <ModuleDropZoneAfter onMouseEnter={enterAfter} />
      </>
    ) : null;
  };

type DraggedModuleProps = Omit<SheetModule, "border"> & {
  id: string;
};

export const DraggedModule: FC<
  DraggedModuleProps & { coordinates: [number, number]; }
> = ({ moduleName, id, coordinates }) => {
  const dispatch = useDispatch();
  const [modulePosition, setModulePosition] = useState(coordinates);
  const [active, setActive] = useState(true);

  const prepareEnd = useSelector(selectDrag.prepareEnd);
  const { area: prevArea, index: prevIndex } = useSelector(selectDrag.originalPosition) || {};
  const area = useSelector(selectDrag.area);
  const index = useSelector(selectDrag.index);

  const [x, y] = prepareEnd ? coordinates : modulePosition

  const onMouseMove = useCallback((e: MouseEvent) => {
    requestAnimationFrame(() => {
      setModulePosition(([prevX, prevY]) => [
        prevX + e.movementX,
        prevY + e.movementY,
      ]);
    });
  }, []);

  const onMouseUp = useCallback(() => {
    setActive(false);
    dispatch(drag.setPrepareEnd())
  }, [dispatch]);

  useEffect(() => {
    if (prepareEnd) {
      setTimeout(() => {
        dispatch(drag.end())
        if (area && prevArea && index !== undefined && prevIndex !== undefined) {
          console.log("hello")
          dispatch(changeModule.order({ area, prevArea, index, prevIndex, id }))
        }
      }, 160)
    }
  }, [area, prevArea, index, prevIndex, id, prepareEnd, dispatch])

  useEffect(() => {
    if (active) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    } else {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [active, onMouseUp, onMouseMove]);

  const style = {
    transition: prepareEnd ? "transform 0.16s ease-in-out" : undefined,
    transform: `translate3d(${x}px, ${y}px, 0px)`,
  };

  return (
    <DraggedContainerWrapper style={style}>
      <DraggedModuleInner>
        <DraggedModuleUpScaler active={!prepareEnd}>
          <ModuleContainerShadow />
          <ModuleContainerBackground>
            {createElement(AllModules[moduleName], { border: "stylish", id })}
          </ModuleContainerBackground>
          <MoveButton />
        </DraggedModuleUpScaler>
      </DraggedModuleInner>
    </DraggedContainerWrapper>
  );
};

export const DraggedModuleGhost: FC<DraggedModuleProps & { coordinates: [number, number]; }> = ({
  id,
  moduleName,
  coordinates: [x, y],
}) => {

  const style = {
    transform: `translate3d(${x}px, ${y}px, 0)`,
  };

  return (
    <DraggedModuleContainerGhost style={style}>
      <DraggedModuleInner>
        {createElement(AllModules[moduleName], { border: "stylish", id })}
      </DraggedModuleInner>
    </DraggedModuleContainerGhost>
  );
};

export const DragOverlay: FC<{ id?: string }> = ({ id }) => {
  const height = useSelector(selectDrag.currentHeight);
  const coordinates = useSelector(selectDrag.currentCoords) || [0, 0];
  const draggedModuleProps = useSelector(selectModule(id || ""))

  return (id && draggedModuleProps) ? (
    <DragOverlayContainer style={{ height }}>
      <DraggedModule {...draggedModuleProps} id={id} coordinates={coordinates} />
      <DraggedModuleGhost {...draggedModuleProps} id={id} coordinates={coordinates} />
    </DragOverlayContainer>
  ) : null;
};
