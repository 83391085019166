import styled from "styled-components";

import { useDispatch } from "react-redux";

import { Button } from "components";
import { layoutOptions, LayoutOption } from "types";
import {
  changeSheet,
} from "features/character";

const ChooseLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  padding-top: 128px;
  gap: 32px;
`;

const ChooseLayoutOptions = styled.div`
  display: flex;
  gap: 32px;

  > * {
    flex-basis: 100%;
    flex-grow: 1;
  }
`;

const ChooseLayoutTitle = styled.h2``;

const ChooseLayoutOption = styled(Button)``;

const layoutNames = {
  martial: "Martial Layout",
  spellcaster: "Spellcasting Layout",
  blank: "Blank Layout",
} as const;

export const ChooseLayout = () => {
  const dispatch = useDispatch();

  const onClickOption = (option: LayoutOption) => () => {
    dispatch(changeSheet.pickLayoutOption(option));
  };

  return (
    <ChooseLayoutContainer>
      <ChooseLayoutTitle>Choose a layout</ChooseLayoutTitle>
      <ChooseLayoutOptions>
        {layoutOptions.map((option) => (
          <ChooseLayoutOption onClick={onClickOption(option)} key={option}>
            {layoutNames[option]}
          </ChooseLayoutOption>
        ))}
      </ChooseLayoutOptions>
    </ChooseLayoutContainer>
  );
};
