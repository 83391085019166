import styled from "styled-components";
import { Button } from "components";

export const ModalBackdrop = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.1);

  display: flex;
  align-items: center;
  justify-content: center;

  pointer-events: all;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  min-width: 400px;
  background: white;
  border-radius: 32px;
  padding: 32px;
  box-shadow: 0 3px 12px 4px rgba(0, 0, 0, 0.4);
`;

export const ModalTop = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModalTitle = styled.h2``;

export const ModalBottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

export const ActionButton = styled(Button)`
  margin-left: auto;
`;

export const ModalContents = styled.div`
  flex-grow: 1;
`;

export const HiddenFocusElement = styled.div`
  position: absolute;
  height: 0;
  width: 0;
  display: hidden;
`;
