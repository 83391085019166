import styled from "styled-components";

import { varStatValueColor, statColorMixin } from "styles";
import { SkillType } from "types";

type SymbolProps = Pick<SkillType, "proficiencyMultiplier">

export const SkillsContainer = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1;
  justify-content: space-around;
`;

export const SkillGroupContainer = styled.div`
  display: flex;
  :not(:last-child) {
    margin-bottom: 0.5em;
  }
`;

export const SkillGroupAbilityLabelContainer = styled.div`
  position: relative;
  width: 1.5em;
  border-right: ${1 / 16}em solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.25em;
  margin-right: 0.5em;

  :before,
  :after {
    content: "";
    position: absolute;
    height: ${3 / 16}em;
    width: ${3 / 16}em;
    right: -${2 / 16}em;
    transform: rotate(45deg);
    background: black;
  }
  :before {
    top: -${2 / 16}em;
  }
  :after {
    bottom: -${2 / 16}em;
  }
`;

export const SkillGroupAbilityLabel = styled.div`
  text-transform: uppercase;
  font-size: 0.75em;
  font-weight: 600;
  transform: rotate(-90deg);
`;

export const SkillGroupList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SkillGroupListItem = styled.div`
  display: flex;
  align-items: center;
  height: 1.25em;

  :not(:last-child) {
    margin-bottom: 0.25em;
  }
`;

const proficiencySymbolFill = (multiplier = 1) => ({
  proficiencyMultiplier,
}: SymbolProps) => (proficiencyMultiplier >= multiplier ? varStatValueColor : "white");

export const SkillSymbol = styled.div<SymbolProps>`
  position: relative;
  height: 1em;
  width: 1em;

  :before,
  :after {
    content: "";
    position: absolute;
    border: ${1 / 16}em solid black;
    border-radius: 50%;
    transition: background-color 0.2s ease-in-out;
  }
  :before {
    top: 0;
    left: 0;
    height: 0.5em;
    width: 0.5em;
    background-color: ${proficiencySymbolFill(2)};
  }
  :after {
    bottom: 0;
    right: 0;
    height: 0.75em;
    width: 0.75em;
    background-color: ${proficiencySymbolFill()};
  }
`;

export const SavingThrowSymbol = styled.div<SymbolProps>`
  position: relative;
  height: 1em;
  width: 1em;

  :before {
    content: "";
    position: absolute;
    border: ${1 / 16}em solid black;
    height: ${9 / 16}em;
    width: ${9 / 16}em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    transition: background-color 0.2s ease-in-out;
    background-color: ${proficiencySymbolFill()};
  }
`;

export const SkillValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5em;
  height: 100%;
  width: 1.5em;
  border-bottom: ${1 / 16}em solid black;

  ${statColorMixin}
`;

export const SkillName = styled.div`
  margin-left: 0.5em;
  font-size: 1em;
`;
