import styled from "styled-components"

import { FloatingButton } from "components";

const dots = `
<svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
  <circle fill="black" cx="6" cy="6" r="2"/>
</svg>
`;

export const MoveButton = styled.div`
  z-index: 10;
  position: absolute;
  cursor: move !important;

  min-width: 48px;
  top: -2em;
  transform: scale(calc(1 / var(--sheet-scale, 1))) translateY(1px);
  transform-origin: bottom;
  left: calc(50% - 24px);
  background: url(${`'data:image/svg+xml;utf8,${dots}'`}), #ddd;
  background-repeat: space;
  background-size: auto 50%;
  height: 2em;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border: 0.25em solid #ddd;
`;

export const CancelButton = styled(FloatingButton)`
  top: 0;
  left: 0;
  z-index: 10;
  transform: translate(-50%, -50%) scale(calc(1 / var(--sheet-scale, 1)));
`;

export const ControlsContainer = styled.div`
  background: white;
  right: -8px;
  padding: 16px;
  transform: translateX(100%) scale(calc(1 / var(--sheet-scale, 1)));
  transform-origin: top left;
  border: 1px solid black;
  border-left-color: none;
  position: absolute;
  z-index: 10;
  cursor: initial;

  border-radius: 16px;
  box-shadow: 0 3px 12px 4px rgba(0, 0, 0, 0.4);

  display: flex;
  flex-direction: column;
  justify-content: stretch;

  > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const ControlsWrapper = styled.div`
  display: contents;

  @media print {
    display: none;
  }
`;