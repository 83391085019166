import { CharacterReducer } from "./types";

import { findIndexById } from "utils"

const getModifier = (score: number) => Math.floor((score - 10) / 2);

export const changeAbilityScore: CharacterReducer<{
  index: number;
  value: number;
}> = (state, action) => {
  const { index, value } = action.payload
  state.abilityScores[index].value = value;
  state.abilityScores[index].modifier = getModifier(value);
};

export const changeSkill: CharacterReducer<{ id: string; value: number }> = (
  state,
  action
) => {
  const index = findIndexById(state.skills, action.payload.id);
  state.skills[index].proficiencyMultiplier = action.payload.value;
};
