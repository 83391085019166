import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
  createContext,
  CSSProperties,
  FC
} from "react";
import { useDispatch, useSelector } from "react-redux";
import useComponentSize from "@rehooks/component-size";

import { ModuleHeight } from "types";
import { changeModule, selectAreas } from "features/character";
import { ZoomContext } from "components";
import { sum } from "utils";
import { AllModulesKeys } from "components/character-details/sheet/sheet-modules";

import { ResizeIndicator, ResizeGrowIndicator } from "./styles";

const resizeableModules: AllModulesKeys[] = [
  "CombatFeatures",
  "Spellcasting",
  "CharacterFeatures",
];

export const isResizeable = (moduleName: AllModulesKeys) =>
  resizeableModules.includes(moduleName);

const auto = { height: "auto" } as const;
const grow = { flexGrow: 1, flexBasis: "1px" } as const;
export const getHeightStyles = (height: ModuleHeight): CSSProperties =>
  ({ auto, grow }[height] || { height: `${height}px` });

type ResizeContextValues = {
  id: string,
  setExtraHeight: (newHeight: number) => void,
  setIsReady: (isReady: boolean) => void,
  resizeStart: (e: React.MouseEvent) => void,
}

export const ResizeContext = createContext<ResizeContextValues>({
  id: "",
  setExtraHeight: () => {},
  setIsReady: () => {},
  resizeStart: () => {},
});

export const useExtraHeightHook = () => {
  const extraHeightRef = useRef(null);
  const [hasBeenChecked, setHasBeenChecked] = useState(false);
  const { height } = useComponentSize(extraHeightRef);

  const { setExtraHeight, setIsReady } = useContext(ResizeContext);

  /** TODO: Clean up, don't need 3 useEffects */

  useEffect(() => {
    setExtraHeight(height);
  }, [height, setExtraHeight]);

  // When height has been measured, tell the parent component
  // to react to any height changes of the extraHeight measurer
  // for when content changes.
  useEffect(() => {
    if (hasBeenChecked) {
      setIsReady(true);
    }
  }, [hasBeenChecked, setIsReady]);

  // Check that height has been measured
  useEffect(() => {
    if (!hasBeenChecked) {
      setHasBeenChecked(true);
    }
  }, [height, setHasBeenChecked, hasBeenChecked]);

  return extraHeightRef;
};

type UseResize = (
  id: string,
  height?: ModuleHeight
) => {
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  resizeStart: (e: React.MouseEvent) => void;
  stateHeight: ModuleHeight;
  setExtraHeight: (extraHeight: number) => void;
  setIsReady: (isReady: boolean) => void;
  setShouldGrow: (shouldGrow: boolean) => void;
  resizing: boolean;
};

export const useResize: UseResize = (id, height = "auto") => {
  const dispatch = useDispatch();

  const scale = useContext(ZoomContext);

  const [isReady, setIsReady] = useState(false);
  const [extraHeight, setExtraHeight] = useState(0);
  const [originalHeight, setOriginalHeight] = useState(0);
  const [stateHeight, setStateHeight] = useState(height);
  const [resizing, setResizing] = useState(false);
  const [resizeBasis, setResizeBasis] = useState(0);
  const [shouldGrow, setShouldGrow] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const resizeStart = (e: React.MouseEvent) => {
    e.stopPropagation();
    setResizing(true);
    setResizeBasis(e.clientY - Math.round(extraHeight * scale));
    if (containerRef.current && !originalHeight) {
      setOriginalHeight(containerRef.current.clientHeight - extraHeight);
    }
  };

  const resizeEnd = () => {
    setResizing(false);
  };

  const resize = useCallback(
    ({ clientY }: { clientY: number }) => {
      if (resizing) {
        if (shouldGrow) {
          window.requestAnimationFrame(() => {
            setStateHeight("grow");
          });
        } else {
          const newHeight =
            originalHeight + Math.round((clientY - resizeBasis) / scale);

          const heightToUse = clientY > resizeBasis ? newHeight : "auto";

          window.requestAnimationFrame(() => {
            setStateHeight(heightToUse);
          });
        }
      }
    },
    [originalHeight, resizeBasis, scale, resizing, shouldGrow]
  );

  useEffect(() => {
    if (!resizing && extraHeight === 0 && isReady && height !== "grow") {
      window.requestAnimationFrame(() => {
        setStateHeight("auto");
      });
    }
  }, [extraHeight, resizing, isReady, height]);

  const registerModuleHeight = useCallback(
    (height: ModuleHeight) => {
      dispatch(changeModule.height({ height, id }));
    },
    [id, dispatch]
  );

  /* Using window listeners to be able to detect mouse movement outside the element */
  useEffect(() => {
    if (resizing) {
      window.addEventListener("mousemove", resize);
      window.addEventListener("mouseup", resizeEnd);
    } else {
      registerModuleHeight(stateHeight);
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", resizeEnd);
    }

    return () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", resizeEnd);
    };
  }, [resizing, resize, stateHeight, registerModuleHeight]);

  return {
    containerRef,
    resizeStart,
    stateHeight,
    setExtraHeight,
    setIsReady,
    resizing,
    setShouldGrow,
  };
};

type ResizeGrowProps = {
  id: string;
  setShouldGrow: (shouldGrow: boolean) => void;
};

export const ResizeGrow: FC<ResizeGrowProps> = ({ id, setShouldGrow }) => {
  const [anchorHeight, setAnchorHeight] = useState<number>(0);
  const [shouldRender, setShouldRender] = useState(false);
  const areas = useSelector(selectAreas);

  useEffect(() => {
    const areasArray = Object.entries(areas);
    const area = areasArray.find(([_, { modules }]) => modules.includes(id));
    if (area) {
      const [areaName, { modules }] = area;
      const moduleIndex = modules.findIndex((module) => module === id);

      if (moduleIndex !== modules.length - 1) {
        return;
      }
      /* 
        TODO: Clean up
        Currently gets heights of modules in ALL areas,
        while only using the ones from the current array.
      */
      const moduleHeights = areasArray.reduce<{ [key: string]: number[] }>(
        (acc, [area, { modules }]) => {
          const moduleHeights = modules
            .map((moduleId) => document.getElementById(moduleId)?.offsetHeight)
            .filter(Boolean) as number[];

          return {
            ...acc,
            [area]: moduleHeights,
          };
        },
        {}
      );

      const areaHeights = moduleHeights[areaName];

      const procedingHeights = areaHeights.slice(moduleIndex + 1);
      setShouldRender(true);
      setAnchorHeight(sum(procedingHeights));
    }
  }, [areas, id]);

  if (!shouldRender) {
    return null;
  }

  return (
    <ResizeGrowIndicator
      style={{ top: `calc(100% - ${anchorHeight}px)` }}
      onMouseEnter={() => setShouldGrow(true)}
      onMouseLeave={() => setShouldGrow(false)}
    />
  );
};

export const ResizeButton = () => {
  const { resizeStart } = useContext(ResizeContext);

  return <ResizeIndicator size="small" onMouseDown={resizeStart} />;
};
