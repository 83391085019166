import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { selectSpellcasting, changeSpellcasting } from "features/character";
import { SpellcastingType } from "types";
import { NewNumberInput, InputGroup } from "components";

import { OnChange } from "./utils";
import { getOrdinal } from "utils";

const SpellcastingContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;

  gap: 16px;

  > * {
    flex-basis: 1;
  }
`;

const SpellcastingStatsContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 16px;
`;

const SpellSlotsContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 4px;
`;

const SpellSlotsLabel = styled.label`
  display: block;
`;

const RemoveSpellSlotsButton = styled(InputGroup.RowButton)`
  border-top-right-radius: 8px !important;
`;

type OnSpellcastingChange = OnChange<SpellcastingType>;

const spellcastingTextKeys = {
  spellAttackModifier: "Spell attack modifier",
  spellSaveDC: "Spell save DC",
  cantripsAmount: "Cantrips known",
  spellsAmount: "Spells known",
  spellSlots: "Spell slots",
} as const;

export const Spellcasting = () => {
  const dispatch = useDispatch();
  const {
    spellAttackModifier,
    spellSaveDC,
    cantripsAmount,
    spellsAmount,
    spellSlots,
  } = useSelector(selectSpellcasting);
  const spellSlotsLevels = spellSlots.length;

  const onChangeSpellcasting: OnSpellcastingChange = (key) => (value) => {
    dispatch(changeSpellcasting.change({ value, key }));
  };

  const onChangeSpellSlots = (index: number) => (value: number) => {
    dispatch(changeSpellcasting.spellSlot.changeAmount({ index, value }));
  };

  const onAddSpellSlots = () => {
    dispatch(changeSpellcasting.spellSlot.add());
  };

  const onRemoveSpellSlots = () => {
    dispatch(changeSpellcasting.spellSlot.removeLast());
  };

  return (
    <SpellcastingContainer>
      <SpellcastingStatsContainer>
        <NewNumberInput
          value={spellAttackModifier}
          onChange={onChangeSpellcasting("spellAttackModifier")}
          label={spellcastingTextKeys.spellAttackModifier}
        />
        <NewNumberInput
          value={spellSaveDC}
          onChange={onChangeSpellcasting("spellSaveDC")}
          label={spellcastingTextKeys.spellSaveDC}
        />
        <NewNumberInput
          value={cantripsAmount}
          onChange={onChangeSpellcasting("cantripsAmount")}
          label={spellcastingTextKeys.cantripsAmount}
        />
        <NewNumberInput
          value={spellsAmount}
          onChange={onChangeSpellcasting("spellsAmount")}
          label={spellcastingTextKeys.spellsAmount}
        />
      </SpellcastingStatsContainer>
      <SpellSlotsContainer>
        <SpellSlotsLabel htmlFor="first">
          {spellcastingTextKeys.spellSlots}
        </SpellSlotsLabel>
        <InputGroup.Container>
          {spellSlots.map(({ level, amount }, i) => (
            <InputGroup.Row key={level.toString()}>
              <NewNumberInput
                id={i === 0 ? "first" : undefined}
                label={`${getOrdinal(level)} level`}
                internalLabel
                min={1}
                max={10}
                value={amount || 1}
                onChange={onChangeSpellSlots(i)}
              />
              {spellSlotsLevels - 1 === i && (
                <RemoveSpellSlotsButton onClick={onRemoveSpellSlots}>
                  X
                </RemoveSpellSlotsButton>
              )}
            </InputGroup.Row>
          ))}
          <InputGroup.BottomButton onClick={onAddSpellSlots}>
            Add spell slots
          </InputGroup.BottomButton>
        </InputGroup.Container>
      </SpellSlotsContainer>
    </SpellcastingContainer>
  );
};
