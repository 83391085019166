import { useSelector } from 'react-redux';
import styled from "styled-components";
import { selectAbilityScores, selectSkills } from 'features/character';

import { ModuleType } from "../utils"
import { AbilityScores } from "./ability-scores";
import { Skills } from "./skills";

const AbilityScoresAndSkillsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-grow: 1;
`;

export const AbilityScoresAndSkills: ModuleType = ({ border }) => {
  const abilityScores = useSelector(selectAbilityScores)
  const skills = useSelector(selectSkills)

  const borderOptions = {
    borderWidth: 8,
    lineWidth: 2,
    border
  }

  return (
  <AbilityScoresAndSkillsContainer>
    <AbilityScores {...borderOptions} abilityScores={abilityScores} />
    <Skills abilityScores={abilityScores} skills={skills} />
  </AbilityScoresAndSkillsContainer>
)};
