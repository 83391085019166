import { FC } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { selectProficiencies } from "features/character";
import { ProficienciesType } from "types";

import { ModuleType } from "../utils";
import {
  ProficienciesContainer,
  MainProficiencies,
  ProficiencySymbolContainer,
  ProficiencySymbolLabel,
  ProficiencySymbol,
  LanguagesAndToolsContainer,
  LanguagesAndToolsSection,
  SectionLabel,
  LanguagesAndToolsList,
} from "./components";

const mainProficiencyLabels = {
  lightArmour: "light armour",
  simpleWeapons: "simple weapons",
  mediumArmour: "medium armour",
  martialWeapons: "martial weapons",
  heavyArmour: "heavy armour",
  shields: "shields",
} as const;

const ListItem = styled.span`
  :not(:first-of-type) {
    :not(:last-of-type) {
      :before {
        content: ", ";
      }
    }

    :last-of-type {
      :before {
        content: " & ";
      }
    }
  }
`;

const Connector = styled.div<{ lineWidth: number }>`
  height: calc(100% - 32px);
  margin-top: 16px;
  width: calc(8px + 1px);
  margin-left: -1px;
  margin-right: -1px;
  border-top: ${({ lineWidth }) => lineWidth}px solid black;
  border-bottom: ${({ lineWidth }) => lineWidth}px solid black;
`;

const List: FC<{ items: string[] }> = ({ items }) => (
  <>
    {items.map((item) => (
      <ListItem key={item}>{item}</ListItem>
    ))}
  </>
);

export const Proficiencies: ModuleType = () => {
  const { main, weapons, tools, other, languages } =
    useSelector(selectProficiencies);

  const proficiencies = [...weapons, ...tools, ...other];

  const lineWidth = 2;
  const borderWidth = 8;
  const border = "stylish";

  const borderOptions = {
    lineWidth,
    borderWidth,
    border,
  } as const;

  return (
    <ProficienciesContainer>
      <MainProficiencies {...borderOptions}>
        <SectionLabel>Proficiencies</SectionLabel>
        {Object.entries(main).map(([key, value]) => (
          <ProficiencySymbolContainer key={key}>
            <ProficiencySymbolLabel>
              {mainProficiencyLabels[key as keyof ProficienciesType["main"]]}
            </ProficiencySymbolLabel>
            <ProficiencySymbol isProficient={value} />
          </ProficiencySymbolContainer>
        ))}
      </MainProficiencies>
      <Connector lineWidth={lineWidth} />
      <LanguagesAndToolsContainer {...borderOptions}>
        <LanguagesAndToolsSection>
          <SectionLabel>Languages</SectionLabel>
          <LanguagesAndToolsList>
            <List items={languages} />
          </LanguagesAndToolsList>
        </LanguagesAndToolsSection>
        <LanguagesAndToolsSection>
          <SectionLabel>Tools & other Proficiencies</SectionLabel>
          <LanguagesAndToolsList>
            <List items={proficiencies} />
          </LanguagesAndToolsList>
        </LanguagesAndToolsSection>
      </LanguagesAndToolsContainer>
    </ProficienciesContainer>
  );
};
