import { FC } from "react";
import styled from "styled-components";

import { EquipmentNotesType } from "types";
import { Border } from "components";

export const CombatFeaturesContainer = styled(Border)`
  display: flex;
  flex-direction: column;
  padding: 8px;
  width: 100%;
`;

export const EquipmentContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const EquipmentRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 4px;
  min-height: 24px;
  line-height: 1;
`;

export const EquipmentRowField = styled.div`
  background: #d4d4d4;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  &:not(:last-of-type) {
    margin-right: 4px;
  }
  padding: 4px 8px;
  text-transform: capitalize;
`;

export const EquipmentLabelsRow = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

export const EquipmentLabel = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  line-height: 1;
  &:not(:last-child) {
    margin-right: 8px;
  }
`;

const WeaponNotesContainer = styled.div`
  margin: 4px 0;
`;
const WeaponNote = styled.span`
  &:not(:last-child):after {
    content: ", ";
  }
`;
const WeaponNoteTitle = styled.strong`
  text-transform: capitalize;
  &:not(:last-child):after {
    content: ":";
  }
`;

export const EquipmentNotes: FC<{ notes: EquipmentNotesType }> = ({ notes }) => (
  <WeaponNotesContainer>
    {notes.map(({ title, text }) => (
      <WeaponNote key={title}>
        <WeaponNoteTitle>{title}</WeaponNoteTitle>
        {text && <span> {text}</span>}
      </WeaponNote>
    ))}
  </WeaponNotesContainer>
);
