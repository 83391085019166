import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./data";
import * as reducers from "./reducers";

export * from "./selectors"

export const sheetSlice = createSlice({
  name: "sheet",
  initialState,
  reducers,
});

export const {
  changeDraggedModule,
  changeMovedModule,
  setActiveModule,
  setStyleSelectActive,
  closeWindow,
} = sheetSlice.actions;

export const sheetReducer = sheetSlice.reducer;
