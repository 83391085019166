import { FC, useRef, useState } from "react";
// import { Redirect } from "react-router-dom"
import styled from "styled-components";
import { v4 as uuid } from "uuid";

import { Modal, TextInput, NewNumberInput } from "components";
import { useAddCharacter } from "firebase-utils";

import { MinimalCharacterType } from "types";

type CreateCharacterModalProps = {
  onClose: () => void;
};

const CreateCharacterForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`;

export const CreateCharacterModal: FC<CreateCharacterModalProps> = ({
  onClose,
}) => {
  const [name, setName] = useState("");
  const [race, setRace] = useState("");
  const [className, setClassName] = useState("");
  const [level, setLevel] = useState(1);
  const id = useRef(uuid())
  
  const [addCharacter] = useAddCharacter()

  const saveCharacter = () => {
    const timestamp = Date.now()
    const character: MinimalCharacterType = {
      id: id.current,
      base: {
        name,
        race,
        background: "",
      },
      classes: [{ id: uuid(), className, subClass: "", level: level || 1 }],
      createdAt: timestamp,
      savedAt: timestamp,
    };

    addCharacter(character)
  };

  return (
    <Modal
      action={saveCharacter}
      actionText="Create character"
      onClose={onClose}
      title="Create a new character"
    >
      <CreateCharacterForm>
        <TextInput required label="Name" value={name} onChange={setName} />
        <TextInput label="Race" value={race} onChange={setRace} />
        <TextInput
          label="Class name"
          value={className}
          onChange={setClassName}
        />
        {className && (
          <NewNumberInput label="Level" value={level} onChange={setLevel} />
        )}
        </CreateCharacterForm>
    </Modal>
  );
};
