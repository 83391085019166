import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "app/store";
import { CharacterType } from "types";

type UIState = {
  lastStatTab: string;
  characterListFetched: boolean;
  charactersCached: { [id: string]: CharacterType };
};

const initialState: UIState = {
  lastStatTab: "",
  characterListFetched: false,
  charactersCached: {},
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    changeLastStatTab: (state, action: PayloadAction<string>) => {
      state.lastStatTab = action.payload;
    },
    cacheCharacter: (state, action: PayloadAction<CharacterType>) => {
      state.charactersCached[action.payload.id] = action.payload;
    },
    setCharacterListFetched: (state) => {
      state.characterListFetched = true;
    },
    removeCachedCharacter: (state, action: PayloadAction<string>) => {
      delete state.charactersCached[action.payload];
    },
  },
});

export const {
  cacheCharacter,
  changeLastStatTab,
  removeCachedCharacter,
  setCharacterListFetched,
} = uiSlice.actions;

export const selectLastStatTab = (state: RootState) => state.ui.lastStatTab;
export const selectIsCharacterCached = (id: string) => (state: RootState) =>
  id in state.ui.charactersCached;
export const selectCachedCharacter = (id: string) => (state: RootState) =>
  state.ui.charactersCached[id];
export const selectIsCharacterListFetched = (state: RootState) =>
  state.ui.characterListFetched;

export const uiReducer = uiSlice.reducer;
