import { useSelector } from 'react-redux';

import { selectActiveStats } from 'features/character';

import { ModuleType } from "../utils"
import { GenericStats } from "../generic-stats";

export const ActiveStats: ModuleType = () => {
  const activeStats = useSelector(selectActiveStats)

  return (
    <GenericStats stats={activeStats} />
)};
