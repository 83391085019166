import { ChangeEvent, forwardRef } from "react";
import styled from "styled-components";

import { inputStyles, Container, Label } from "../styles";
import { BaseInputProps } from "../types";

export type TextInputProps<T extends string | undefined> = BaseInputProps & {
  type?: "password" | "email" | "text";
  onChange: (value: T, event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  internalLabel?: boolean;
  allowUndefined?: boolean;
  value: T;
};

export const Input = styled.input<{ internalLabel?: boolean }>`
  ${inputStyles};
`;

function TextInputComponent<T extends string | undefined>(
  {
    label,
    onChange,
    type = "text",
    internalLabel,
    style,
    className,
    allowUndefined,
    value = "",
    required,
    ...props
  }: TextInputProps<T>,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== "") {
      onChange(e.target.value as T, e);
    } else {
      const newValue = allowUndefined ? undefined : "";
      onChange(newValue as T, e);
    }
  };

  return (
    <Container style={style} className={className}>
      {label && <Label internal={internalLabel}>{label}{required && " *"}</Label>}
      <Input
        {...props}
        type={type}
        value={value}
        ref={ref}
        style={style}
        className={className}
        internalLabel={internalLabel}
        required={required}
        onChange={handleChange}
      />
    </Container>
  );
}

export const TextInput = forwardRef(TextInputComponent);
