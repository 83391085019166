import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "data-base";

import { reducers } from "./reducers"

export const characterSlice = createSlice({
  name: "character",
  initialState,
  reducers,
});

export const {
  changeBase,
  changeCombatStats,
  changeAbilityScore,
  changeSkill,
  changeActiveStat,
  changePassiveStat,
  initializeCharacter
} = characterSlice.actions;

export const changeProficiency = {
  changeSingle: characterSlice.actions.changeProficiency,
  changeMulti: characterSlice.actions.changeProficiencies,
  changeMain: characterSlice.actions.changeMainProficiency,
};

export const changeSpell = {
  change: characterSlice.actions.changeSpell,
  order: characterSlice.actions.changeSpellOrder,
  remove: characterSlice.actions.removeSpell,
  add: characterSlice.actions.addSpell,
};

export const changeSpellcasting = {
  change: characterSlice.actions.changeSpellcasting,
  spellSlot: {
    changeAmount: characterSlice.actions.changeSpellSlotAmount,
    add: characterSlice.actions.addSpellSlot,
    removeLast: characterSlice.actions.removeLastSpellSlot,
  }
};

export const changeArmor = {
  add: characterSlice.actions.addArmor,
  change: characterSlice.actions.changeArmor,
  addNote: characterSlice.actions.addArmorNote,
  changeNote: characterSlice.actions.changeArmorNote,
};

export const changeWeapon = {
  add: characterSlice.actions.addWeapon,
  change: characterSlice.actions.changeWeapon,
  changeDamage: characterSlice.actions.changeWeaponDamage,
  addNote: characterSlice.actions.addWeaponNote,
  changeNote: characterSlice.actions.changeWeaponNote,
};

export const changeFeature = {
  order: characterSlice.actions.changeFeatureOrder,
  remove: characterSlice.actions.removeFeature,
  move: characterSlice.actions.moveFeature,
  add: characterSlice.actions.addFeature,
  change: characterSlice.actions.changeFeatureReducer,
};

export const changeClass = {
  remove: characterSlice.actions.removeClass,
  add: characterSlice.actions.addClass,
  change: characterSlice.actions.changeClass,
};

export const changeHitDice = {
  remove: characterSlice.actions.removeHitDice,
  add: characterSlice.actions.addHitDice,
  change: characterSlice.actions.changeHitDice,
};

export const changeSheet = {
  pickLayoutOption: characterSlice.actions.pickLayoutOption,
  showStats: characterSlice.actions.toggleShowAllStats,
};

export const changeModule = {
  height: characterSlice.actions.changeModuleHeight,
  title: characterSlice.actions.changeModuleTitle,
  add: characterSlice.actions.addModule,
  remove: characterSlice.actions.removeModule,
  order: characterSlice.actions.moveModule,
  border: characterSlice.actions.changeModuleBorder,
  statVisibility: characterSlice.actions.toggleShowStats,
};

export const changeModuleSpell = {
  add: characterSlice.actions.addModuleSpell,
  remove: characterSlice.actions.removeModuleSpell,
  subscribe: characterSlice.actions.subscribeToSpells,
  unsubscribe: characterSlice.actions.unsubscribeToSpells,
};

export const changeModuleFeature = {
  add: characterSlice.actions.addModuleFeature,
  remove: characterSlice.actions.removeModuleFeature,
  subscribe: characterSlice.actions.subscribeToFeatures,
  unsubscribe: characterSlice.actions.unsubscribeToFeatures,
};

export const characterReducer = characterSlice.reducer;
