import { FC } from "react";
import styled, { css } from "styled-components";

const shieldSloping = "80%"

const shieldMixin = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  
  &:before, &:after {
    content: "";
    position: absolute;
    display: block;
    height: 110%;
    width: 100%;
    top: 0;
    border: 1px solid black;
    overflow: hidden;
  }
  &:before {
    left: 0;
    border-right: 0;
    border-bottom-left-radius: ${shieldSloping};
    clip-path: polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%);
  }
  &:after {
    right: 0;
    border-left: 0;
    border-bottom-right-radius: ${shieldSloping};
    clip-path: polygon(100% 0%, 50% 0%, 50% 100%, 100% 100%);
  }
`

const ArmorClassWithShieldContainer = styled.div`
  position: relative;
  height: 80px;
  width: 80px;
`

const ArmorClass = styled.div`
  position: relative;
  height: 72px;
  width: 72px;
  font-size: 32px;
  padding-bottom: 8px;
  ${shieldMixin};
`

const Shield = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;

  height: 32px;
  width: 32px;
  font-size: 20px;
  padding-bottom: 4px;
  ${shieldMixin};

  background: var(--module-background, #fff);
  z-index: 1;
`

type ArmorClassWithShieldProps = { armorClass?: number, shield?: number }
export const ArmorClassWithShield: FC<ArmorClassWithShieldProps> = ({ armorClass, shield }) => (
  <ArmorClassWithShieldContainer>
    <ArmorClass>{armorClass}</ArmorClass>
    <Shield>{shield}</Shield>
  </ArmorClassWithShieldContainer>
)