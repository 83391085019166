import { memo } from "react";

import { SpellType } from "types";

import { ExtraHeightLines } from "../utils"
import {
  SpellListContainer,
  SpellListLabelRow,
  SpellListLabelLevel,
  SpellListLabelMain,
  SpellListLabelReference,
  SpellListItem,
  VerticalDivider,
  SpellListItemLevel,
  SpellListItemName,
  SpellListItemMain,
  SpellListItemReference,
} from "./styles"

type SpellListProps = { id: string, spells: SpellType[] }

export const SpellList = memo<SpellListProps>(({ spells, id }) => {
  const hasReferences = Boolean(spells.find((spell) => spell.reference !== ""));

  return (
    <SpellListContainer>
      <SpellListLabelRow>
        <SpellListLabelLevel>Lvl</SpellListLabelLevel>
        <SpellListLabelMain>
          Name
          <VerticalDivider />
          Description
        </SpellListLabelMain>
        {hasReferences && (
          <SpellListLabelReference>Ref</SpellListLabelReference>
        )}
      </SpellListLabelRow>
      {spells.map(({ level, name, description, reference }) => (
        <SpellListItem key={name}>
          <SpellListItemLevel>{level}</SpellListItemLevel>
          <SpellListItemMain>
            <SpellListItemName>{name}</SpellListItemName>
            {description && <span>{description}</span>}
          </SpellListItemMain>
          {hasReferences && (
            <SpellListItemReference>{reference}</SpellListItemReference>
          )}
        </SpellListItem>
      ))}
      <ExtraHeightLines id={id} />
    </SpellListContainer>
  );
});
