import { findIndexById } from "utils";

import { CharacterReducer } from "./types";

type ChangeStatReducer = CharacterReducer<{ id: string, value?: number }>

export const changeActiveStat: ChangeStatReducer = (state, action) => {
  const { id, value } = action.payload;
  const index = findIndexById(state.activeStats, id);
  state.activeStats[index].statValue = value;
};

export const changePassiveStat: ChangeStatReducer = (state, action) => {
  const { id, value } = action.payload;
  const index = findIndexById(state.passiveStats, id);
  state.passiveStats[index].statValue = value;
};
