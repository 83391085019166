import React, {
  CSSProperties,
  useState,
  FC,
  useRef,
  useEffect,
  createContext,
  useCallback,
} from "react";
import styled from "styled-components";

import { a4 } from "styles";
import { FloatingButton } from "components";
import { roundToDecimals } from "utils";

const padding = 32;

const maxScale = 1.5;
const defaultScale = 1;
const minScale = 0.2;
const scaleStep = 0.1;
const invertedScaleStep = 1 / scaleStep;

const ZoomContainerOuter = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: ${32}px;
  background: #eee;
  overflow: hidden;

  cursor: grab;

  :active {
    cursor: grabbing;
  }

  @media print {
    padding: 0;
  }
`;

const ZoomButton = styled(FloatingButton)`
  position: relative;
`;

const ZoomContainerInner = styled.div`
  position: absolute;

  > * {
    user-select: none;
  }

  @media print {
    position: relative;
    transform: scale(1) translate3d(0, 0, 0) !important;
  }
`;

const ZoomControls = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 1;
  > *:not(:last-child) {
    margin-bottom: 16px;
  }

  @media print {
    display: none;
  }
`;

const ZoomLabel = styled.p`
  text-align: right;
`;

export const ZoomContext = createContext(1);

export type ZoomContainerProps = {
  onZoom: (scale: number) => void;
  scale: number;
  onMove: (scale: [number, number]) => void;
  moved: [number, number];
  shouldInit: boolean;
  onInit: (state: boolean) => void;
};

export const ZoomContainer: FC<ZoomContainerProps> = ({
  children,
  onZoom,
  scale,
  moved,
  onMove,
  shouldInit,
  onInit,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [movedState, setMovedState] = useState<[number, number]>(moved);
  const [moving, setMoving] = useState(false);

  const style = {
    "--sheet-scale": scale,
    transform: `scale(${scale}) translate3d(${movedState.join("px, ")}px, 0)`,
  } as CSSProperties;

  const zoom = (direction: number) => () => {
    const diff = scaleStep * direction;
    if (scale + diff > maxScale) {
      onZoom(maxScale);
    } else if (scale + diff < minScale) {
      onZoom(minScale);
    } else {
      const roundScale = Math.round((scale + diff) * 100) / 100;
      const rounding = direction < 0 ? Math.ceil : Math.floor;
      const newScale =
        rounding(roundScale * invertedScaleStep) / invertedScaleStep;
      onZoom(newScale);
    }
  };

  const zoomIn = zoom(1);
  const zoomOut = zoom(-1);

  const moveStart = () => {
    setMoving(true);
  };

  const moveEnd = () => {
    setMoving(false);
    onMove(movedState);
  };

  const move = useCallback(({ movementX, movementY }: React.MouseEvent) => {
    if (moving) {
        const movedX = movementX / scale;
        const movedY = movementY / scale;
        setMovedState(([prevX, prevY]) => [prevX + movedX, prevY + movedY]);
    }
  }, [moving, scale]);

  useEffect(() => {
    if (containerRef.current && shouldInit) {
      const width = containerRef.current.offsetWidth - padding * 2;
      const newScale = width / a4.width;
      const realScale = [minScale, newScale, defaultScale].sort(
        (a, b) => a - b
      )[1];

      const scaleInverted = 1 / realScale;
      const coefficient = (scaleInverted - 1) / 2;
      const movedY = Math.round(coefficient * -a4.height);
      const movedX =
        Math.round(realScale === 1
          ? coefficient * -a4.width
          : (width - a4.width) / (2 * realScale));

      onZoom(realScale);
      onMove([movedX, movedY]);
      setMovedState([movedX, movedY]);
      onInit(true);
    }
  }, [onZoom, shouldInit, onInit, onMove]);

  return (
    <ZoomContext.Provider value={scale}>
      <ZoomContainerOuter
        ref={containerRef}
        onMouseDown={moveStart}
        onMouseUp={moveEnd}
        onMouseMove={move}
      >
        <ZoomControls>
          <ZoomLabel>{roundToDecimals(scale * 100, 1)}%</ZoomLabel>
          <ZoomButton onClick={zoomIn}>+</ZoomButton>
          <ZoomButton onClick={zoomOut}>-</ZoomButton>
        </ZoomControls>
        <ZoomContainerInner style={style}>{children}</ZoomContainerInner>
      </ZoomContainerOuter>
    </ZoomContext.Provider>
  );
};
