import { useSelector } from "react-redux";
import styled from "styled-components";

import {
  selectModuleSpells,
  selectSpellcasting,
  selectSpells,
} from "features/character";
import { Border } from "components";

import { ModuleType } from "../utils";
import { GenericStats } from "../generic-stats";
import { Resources } from "../resources";
import { SpellSlots } from "./spell-slots";
import { SpellList } from "./spell-list";

const SpellcastingContainer = styled(Border)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 8px;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap-reverse;
  justify-content: center;
  gap: 16px 8px;
`;

export const Spellcasting: ModuleType = ({
  border = "stylish",
  id,
}) => {
  const spells = useSelector(id ? selectModuleSpells(id) : selectSpells) || [];

  const {
    spellAttackModifier,
    spellSaveDC,
    cantripsAmount,
    spellsAmount,
    spellSlots,
  } = useSelector(selectSpellcasting);

  const borderOptions = {
    borderWidth: 8,
    lineWidth: 2,
    border,
  };

  const oneRow = false;

  const flexStyle = {
    justifyContent: oneRow ? "center" : "space-between",
  } as const;

  return (
    <SpellcastingContainer {...borderOptions}>
      <GenericStats
        stats={[
          {
            isModifier: true,
            id: "spellAttackModifier",
            statName: "Spell attack modifier",
            statValue: spellAttackModifier,
          },
          {
            id: "spellSaveDC",
            statName: "Spell save DC",
            statValue: spellSaveDC,
          },
        ]}
      />
      <FlexContainer style={flexStyle}>
        <SpellSlots oneRow={oneRow} spellSlots={spellSlots} />
        <Resources
          size="medium"
          resources={[
            { label: "cantrips", value: cantripsAmount },
            { label: "spells", value: spellsAmount },
          ]}
        />
      </FlexContainer>
      <SpellList spells={spells} id={id} />
    </SpellcastingContainer>
  );
};
