import { v4 as uuid } from "uuid";

import { spellIDs } from "data";

import { SheetModules, SheetType } from "types";

const area1ids = {
  activeStats: uuid(),
  abilities: uuid(),
  passiveStats: uuid(),
  proficiencies: uuid(),
  racialTraits: uuid(),
};

const area2ids = {
  combatStats: uuid(),
  combatFeatures: uuid(),
  spellcasting: uuid(),
};

const area3ids = {
  classFeatures: uuid(),
  otherTraits: uuid(),
};

const initialModules: SheetModules = {
  [area1ids.activeStats]: { moduleName: "ActiveStats" },
  [area1ids.abilities]: { moduleName: "AbilityScoresAndSkills" },
  [area1ids.passiveStats]: { moduleName: "PassiveStats" },
  [area1ids.proficiencies]: { moduleName: "Proficiencies" },
  [area1ids.racialTraits]: {
    moduleName: "CharacterFeatures",
    title: "Racial traits",
    characterFeatures: { racialTraits: "all" },
    height: "grow",
  },
  [area2ids.combatStats]: { moduleName: "CombatStats" },
  [area2ids.combatFeatures]: { moduleName: "CombatFeatures" },
  [area2ids.spellcasting]: {
    moduleName: "Spellcasting",
    height: "grow",
    spells: spellIDs,
  },
  [area3ids.classFeatures]: {
    moduleName: "CharacterFeatures",
    title: "Class features",
    characterFeatures: { classFeatures: "all" },
    height: "grow",
  },
  [area3ids.otherTraits]: {
    moduleName: "CharacterFeatures",
    title: "Other traits",
    characterFeatures: { otherTraits: "all" },
    height: "grow",
  },
};

export const initialState: SheetType = {
  showAllStats: true,
  draggedModule: undefined,
  movedModule: undefined,
  area1: Object.values(area1ids),
  area2: Object.values(area2ids),
  area3: Object.values(area3ids),
  modules: initialModules,
  activeModule: false,
  styleSelectActive: false,
  windowCloseQueue: [],
};
