import styled from "styled-components"
import { secondaryButtonStyles } from "styles"

export const InputGroupRowButton = styled.button`
  position: absolute;
  left: 100%;
  height: 100%;
  border-radius: 8px;
  width: 32px;
  padding-left: 8px;
  margin-left: -8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  transition: background-color 0.12s ease-in-out;

  ${secondaryButtonStyles}
`;