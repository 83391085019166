import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "app/store";
import { MinimalCharacterType } from "types";

type CharacterListState = {
  data: MinimalCharacterType[];
  isDone: boolean;
};

const initialState: CharacterListState = {
  data: [],
  isDone: false,
};

export const characterListSlice = createSlice({
  name: "characterList",
  initialState,
  reducers: {
    changeCharacterList: (
      state,
      action: PayloadAction<MinimalCharacterType[]>
    ) => {
      state.data = action.payload.sort((a, b) => b.savedAt - a.savedAt);
      state.isDone = true;
    },
    changeCharacterInList: (
      state,
      action: PayloadAction<MinimalCharacterType>
    ) => {
      const index = state.data.findIndex(
        (character) => character.id === action.payload.id
      );
      if (index > -1) {
        state.data.splice(index, 1);
      }
      state.data.unshift(action.payload);
    },
    removeCharacterFromList: (
      state,
      action: PayloadAction<string>
    ) => {
      const index = state.data.findIndex(
        (character) => character.id === action.payload
      );
      if (index > -1) {
        state.data.splice(index, 1);
      }
    },
  },
});

export const { changeCharacterList, changeCharacterInList, removeCharacterFromList } =
  characterListSlice.actions;

export const selectCharacterList = (state: RootState) =>
  state.characterList.data;

export const characterListReducer = characterListSlice.reducer;
