import React, { FC } from "react";
import styled from "styled-components";

const DeathSavesOuterContainer = styled.div`
  width: 136px;
`;

const DeathSavesLabel = styled.div`
  width: 100%;
  margin-bottom: 4px;

  text-transform: uppercase;
  text-align: center;
  font-size: 12px;
  line-height: 1;
`;

const DeathSavesContainer = styled.div`
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  padding: 8px;
  height: 64px;
  justify-content: space-around;
`;

const DeathSavesRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 16px;
`;
const DeathSavesRowLabel = styled.div`
  text-transform: uppercase;
  text-align: right;
  font-size: 10px;
  line-height: 1;
`;
const DeathSavesCounter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 56px;
  margin-left: 4px;
  position: relative;
  flex-shrink: 0;

  &:before {
    content: "";
    position: absolute;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    left: 4px;
    width: calc(100% - 8px);
    height: 6px;
    border: 1px solid black;
    z-index: -1;
  }
`;
const DeathSavesSymbol = styled.div`
  height: 16px;
  width: 16px;
  border: 1px solid black;
  background: var(--module-background, #fff);
  border-radius: 50%;
`;

const DeathSavesRow: FC<{ label: string }> = ({ label }) => (
  <DeathSavesRowContainer>
    <DeathSavesRowLabel>{label}</DeathSavesRowLabel>
    <DeathSavesCounter>
      <DeathSavesSymbol />
      <DeathSavesSymbol />
      <DeathSavesSymbol />
    </DeathSavesCounter>
  </DeathSavesRowContainer>
);

export const DeathSaves: FC = () => (
  <DeathSavesOuterContainer>
    <DeathSavesLabel>death saves</DeathSavesLabel>
    <DeathSavesContainer>
      <DeathSavesRow label="successes" />
      <DeathSavesRow label="failures" />
    </DeathSavesContainer>
  </DeathSavesOuterContainer>
);
