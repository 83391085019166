import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { changeDraggedModule } from "features/sheet";
import { changeSheet, selectShowAllStats } from "features/character";
import { Border, Button, SidebarContainer } from "components";
import {
  AllModules,
  AllModulesKeys,
} from "components/character-details/sheet/sheet-modules";
import { CharacterTabs } from "../../common";

const textKeys = {
  AbilityScoresAndSkills: "Ability Scores & Skills",
  Proficiencies: "Proficiencies",
  CombatStats: "Combat Stats",
  CombatFeatures: "Combat Features",
  Spellcasting: "Spellcasting",
  PassiveStats: "Passive Stats",
  ActiveStats: "Active Stats",
  CharacterFeatures: "Character Features",
} as const;

const text = (key: AllModulesKeys) => textKeys[key];

const ModulesContainer = styled.div`
  margin: 16px;
  display: flex;
  flex-wrap: wrap;
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
  margin-bottom: 0;
  gap: 16px;
`

const Module = styled(Border)`
  height: 48px;
  width: 100%;

  display: flex;
  cursor: move;

  margin-top: 16px;

  align-items: center;
  justify-content: center;

  :hover {
    stroke: goldenrod;
  }
`;

export const Sidebar = () => {
  const dispatch = useDispatch();
  const showStats = useSelector(selectShowAllStats);

  const onDragStart = (module: AllModulesKeys) => () => {
    dispatch(changeDraggedModule(module));
  };

  const onDragEnd = () => {
    dispatch(changeDraggedModule());
  };

  const onClickShowStats = () => {
    dispatch(changeSheet.showStats());
  };

  return (
    <SidebarContainer>
      <CharacterTabs />
      <ControlsContainer>
        <Button onClick={window.print}>
          Print
        </Button>
        <Button onClick={onClickShowStats}>
          {showStats ? "Hide stats" : "Show stats"}
        </Button>
      </ControlsContainer>
      <ModulesContainer>
        <h3>Sheet Modules</h3>
        {Object.keys(AllModules).map((module) => (
          <Module
            lineWidth={1}
            borderWidth={4}
            key={module}
            draggable
            onDragStart={onDragStart(module as AllModulesKeys)}
            onDragEnd={onDragEnd}
          >
            {text(module as AllModulesKeys)}
          </Module>
        ))}
      </ModulesContainer>
    </SidebarContainer>
  );
};
