import { FC } from "react";
import { useSelector } from "react-redux";

import styled from "styled-components";

import { selectBase, selectClasses } from "features/character";

import { Name } from "./name"
import { About } from "./about"
import { ClassSingle } from "./class-single"
import { ClassMulti } from "./class-multi"

const TopAreaContainer = styled.div`
  grid-area: top;
  display: flex;
  height: 144px;

  > * {
    width: 100%;
    flex-basis: 100px;
    flex-grow: 1;
  }
`;

export const TopArea: FC = () => {
  const { name, race } = useSelector(selectBase);
  const classes = useSelector(selectClasses);

  const multiClass = classes.length > 1

  return (
    <TopAreaContainer>
      <Name borderWidth={8} lineWidth={1} border="flagLeft">{name}</Name>
      <About race={race} />
      {multiClass ? (
        <ClassMulti classes={classes} />
        ) : (
        <ClassSingle {...classes[0]} />
      )}
    </TopAreaContainer>
  );
};
