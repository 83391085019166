import { WeaponType, ArmorType, EquipmentNoteType, DiceType } from "types";

import { ChangeReducer, CharacterReducer } from "./types";
import { create } from "./utils";

type ChangeEquipmentNoteReducer = ChangeReducer<
  EquipmentNoteType,
  { equipmentIndex: number }
>;

export const addArmor: CharacterReducer = (state) => {
  state.equipment.armors.push(create.armor());
};

export const changeArmor: ChangeReducer<ArmorType> = (state, action) => {
  const { index, key, value } = action.payload;
  state.equipment.armors[index][key] = value;
};

export const addArmorNote: CharacterReducer<number> = (state, action) => {
  state.equipment.armors[action.payload].notes.push(create.equipmentNote());
};

export const changeArmorNote: ChangeEquipmentNoteReducer = (state, action) => {
  const { equipmentIndex, index, key, value } = action.payload;
  state.equipment.armors[equipmentIndex].notes[index][key] = value;
};

export const addWeapon: CharacterReducer = (state) => {
  state.equipment.weapons.push(create.weapon());
};

export const changeWeapon: ChangeReducer<WeaponType> = (state, action) => {
  const { index, key, value } = action.payload;
  state.equipment.weapons[index][key] = value;
};

export const changeWeaponDamage: ChangeReducer<DiceType> = (state, action) => {
  const { index, key, value } = action.payload;
  state.equipment.weapons[index].damage[key] = value;
};

export const addWeaponNote: CharacterReducer<number> = (state, action) => {
  state.equipment.weapons[action.payload].notes.push(create.equipmentNote());
};

export const changeWeaponNote: ChangeEquipmentNoteReducer = (state, action) => {
  const { equipmentIndex, index, key, value } = action.payload;
  state.equipment.weapons[equipmentIndex].notes[index][key] = value;
};
