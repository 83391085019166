export function findByProperty<
  TKey extends string,
  TObjects extends { [key in TKey]: string }
>(propertyKey: TKey, array: TObjects[], value: string): TObjects[] {
  return array.filter((item) => value === item[propertyKey]);
}

export const findById = (id: string) => (obj: { id: string }) => obj.id === id;
export const pickById = (obj: { id: string }[]) => (id: string) =>
  obj.find(findById(id));

type IdFilter = <T extends { id: string }>(array: T[], ids: string[]) => T[];

export const filterByIds: IdFilter = (array, ids) =>
  array.filter(({ id }) => ids.includes(id));

export const filterByMissingIds: IdFilter = (array, ids) =>
  array.filter(({ id }) => !ids.includes(id));

export function findIndexById<T extends { id: string; [key: string]: any }>(
  array: T[],
  searchId: string
): number {
  return array.findIndex(({ id }) => searchId === id);
}

export const getModifierSymbol = (modifier: number): string =>
  `${modifier > 0 ? "+" : ""}${modifier}`;

const ordinals = {
  "1": "st",
  "2": "nd",
  "3": "rd",
  default: "th",
};

/**
 * Get "ordinal indicator" string of number: 1 => "1st", 2 => "2nd" etc.
 */
export const getOrdinal = (number: number) => {
  const isTeen = number >= 11 && number <= 19;
  const numberAsString = number.toString();
  const lastNumber = numberAsString.slice(-1);
  const ordinalsKey =
    lastNumber in ordinals && !isTeen ? lastNumber : "default";
  const ordinalEnd = ordinals[ordinalsKey as keyof typeof ordinals];
  return `${numberAsString}${ordinalEnd}`;
};

/**
 * Itterates n number of times over array of the size.
 * Executes the given callback function using the map method.
 */
export const mapNTimes = (
  size: number,
  func: (i: number, array: any[]) => any
) =>
  Array(size)
    .fill(undefined)
    .map((_, i, array) => func(i, array));

/**
 * Takes an array and tries to split it into an amount of fractions.
 */
function getWholeFraction<T>(array: T[], fractions: number, n: number): T[] {
  const exactFraction = array.length / fractions;
  const start = Math.ceil(n * exactFraction);
  const end = Math.ceil((n + 1) * exactFraction);
  // console.log(start, end)
  return array.slice(start, end);
}

// function getFraction<T>(array: T[], fractions: number, n: number): T[] {
//   const exactFraction = array.length / fractions;
//   const start = Math.ceil(n * exactFraction);
//   const end = Math.ceil((n + 1) * exactFraction);
//   return array.slice(start, end);
// }

// const getFractionSize = (exactFraction: number) => (i: number) => Math.ceil((i + 1) * exactFraction) - Math.ceil(i * exactFraction);

// const getFractionSizes = (arrayLength: number, fractions: number): number[] => {
//   const exactFraction = arrayLength / fractions;
//   return mapNTimes(fractions, getFractionSize(exactFraction)).sort((a, b) => b - a)
// }

/**
 * Split an array into fractions.
 * Example: Array of length 5 split into 2 => 2 arrays of lengths 3 and 2.
 * Example: Array of length 8 split into 3 => 3 arrays of lengths 3, 3 and 2.
 */

export function splitIntoWholeFractions<T>(
  array: T[],
  fractions: number
): T[][] {
  // const remainder = array.length % fractions // 1

  // if (remainder === 0) {
  //   return mapNTimes(fractions, (i) => getFraction(array, fractions, i))
  // }

  // const fractionSizes = getFractionSizes(array.length, fractions)
  // console.log(fractionSizes)

  // const exactFraction = array.length / fractions
  // const largestPart = Math.ceil(exactFraction) // 3
  // const smallestPart = Math.floor(exactFraction) // 2
  // console.log(array.length, fractions, exactFraction, largestPart, smallestPart)

  // const splits = getFractions() // [3, 3, 2, 2]

  // return fractionSizes.map((size, i,) => )

  return mapNTimes(fractions, (i) => getWholeFraction(array, fractions, i));
}

/**
 * Rounds number to amount of decimals, default 1 decimal: 3.5
 */
export const roundToDecimals = (number: number, decimals = 1) => {
  const power = Math.pow(10, decimals);
  return Math.round(number * power) / power;
};

export const sum = (numbers: number[]) =>
  numbers.reduce((acc, current) => acc + current, 0);