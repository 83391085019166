import { css } from "styled-components";

export const a4 = {
  width: 1188,
  height: 1680,
} as const;

export const noFocusMixin = css`
  outline: none;
  &::-moz-focus-inner {
    border: 0;
  }
`;

export const colors = {
  color0: "#BC8FFF",
  color1: "#9082E8",
  color2: "#9CA9FF",
  color3: "#82AAE8",
  color4: "#8FD9FF",
  button: "#512099",
  buttonHover: "#7930E6",
  buttonActive: "#2F1359",
};

// TODO: Rename to something like "sheetSpacing"
export const spacing = {
  xSmall: "4px",
  small: "8px",
  medium: "12px",
  large: "16px",
} as const;

export type Style = { [key: string]: number };
type CalcFunction = (value: number) => string;

export const unit: CalcFunction = (value: number) => `${value}px`;

// Todo: Create smart system for calculating styles

// type StyleCalcs = { [key: string]: CalcFunction }

// const calculateStyles = (rawStyles: Style, calulations: CalcFunction ) => {
//   return ({ ...rawStyles, ...calulations })
// }

// _________ BUTTON ______

const buttonRawStyles: Style = {
  borderWidth: 2,
  borderRadius: 8,
  height: 40,
  horizontalPadding: 24,
  verticalPadding: 8,
};

export const buttonStyles = {
  borderWidth: unit(buttonRawStyles.borderWidth),
  borderRadius: unit(buttonRawStyles.borderRadius),
  height: unit(buttonRawStyles.height),
  horizontalPadding: unit(
    buttonRawStyles.horizontalPadding - buttonRawStyles.borderWidth
  ),
  verticalPadding: unit(
    buttonRawStyles.verticalPadding - buttonRawStyles.borderWidth
  ),
};

export const buttonColors = {
  background: "white",
  border: "black",
  text: "black",
  hoverBackground: "#eee",
  activeBackground: "#ddd",
};

const buttonFocusColors = {
  border: "black",
  background: "transparent",
};

const buttonRawFocusStyles: Style = {
  borderWidth: 2,
  padding: 4,
};

export const buttonFocusStyles = {
  borderWidth: unit(buttonRawFocusStyles.borderWidth),
  padding: unit(buttonRawFocusStyles.padding),
  size: unit(
    buttonRawFocusStyles.padding +
      buttonRawFocusStyles.borderWidth +
      buttonRawStyles.borderWidth
  ),
  borderRadius: unit(
    buttonRawStyles.borderRadius + buttonRawFocusStyles.padding
  ),
};

type FocusRingStyles = {
  radius?: string;
  size?: string;
};
export const focusRingMixin = ({ size, radius }: FocusRingStyles = {}) => css`
  content: "";
  position: absolute;
  display: block;
  top: -${size || buttonFocusStyles.size};
  left: -${size || buttonFocusStyles.size};
  height: calc(100% + ${size || buttonFocusStyles.size} * 2);
  width: calc(100% + ${size || buttonFocusStyles.size} * 2);

  border-color: ${buttonFocusColors.border};
  background-color: ${buttonFocusColors.background};

  border-width: ${buttonFocusStyles.borderWidth};
  border-style: solid;

  border-radius: ${radius || buttonFocusStyles.borderRadius};
`;

export const statValueColor = "--stat-value-color";
export const varStatValueColor = `var(${statValueColor}, black)`;

export const statColorMixin = `
  transition: color 0.2s ease-in-out;
  color: ${varStatValueColor};
`;

export const secondaryButtonStyles = css`
  border: 0;
  text-align: center;
  cursor: pointer;

  background-color: #ddd;

  :hover,
  &.hover {
    background-color: #ccc;
  }

  :active,
  &.active {
    background-color: #bbb;
  }

  :focus,
  &.focus {
    border: 2px solid black;
    ${noFocusMixin};
  }
`;

export const inputZIndex = css`
  z-index: 3;

  :hover {
    z-index: 4;
  }

  :focus-within {
    z-index: 5;
  }
`;
