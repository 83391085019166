import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "app/store";

type DraggedPosition = { area: string; index: number };
type DraggedModuleHeight = "grow" | number;
type Coordinates = [number, number];
type PlacementNode = { id: string; placement: "before" | "after", newArea?: boolean };

type ModuleDragState = {
  module: string | undefined;
  originalPosition: DraggedPosition | undefined;
  startCoords: Coordinates | undefined;
  height: DraggedModuleHeight | undefined;
  minHeight: number | undefined;
  currentHeight: number | undefined;
  currentCoords: Coordinates | undefined;
  area: string | undefined;
  index: number | undefined;
  placementNode: PlacementNode | undefined;
  prepareEnd: boolean | undefined;
};

const initialState: ModuleDragState = {
  module: undefined,
  originalPosition: undefined,
  startCoords: undefined,
  height: undefined,
  minHeight: undefined,
  currentHeight: undefined,
  currentCoords: undefined,
  area: undefined,
  index: undefined,
  placementNode: undefined,
  prepareEnd: undefined,
};

export const moduleDrag = createSlice({
  name: "moduleDrag",
  initialState,
  reducers: {
    start: (state, action: PayloadAction<string>) => {
      state.module = action.payload;
    },
    end: (state) => {
      state.module = initialState.module;
      state.startCoords = initialState.startCoords;
      state.height = initialState.height;
      state.minHeight = initialState.minHeight;
      state.currentHeight = initialState.currentHeight;
      state.area = initialState.area;
      state.index = initialState.index;
      state.placementNode = initialState.placementNode;
      state.prepareEnd = initialState.prepareEnd;
    },
    changeOriginalPosition: (state, action: PayloadAction<DraggedPosition>) => {
      state.originalPosition = action.payload;
    },
    changeStartCoords: (state, action: PayloadAction<Coordinates>) => {
      state.startCoords = action.payload;
      state.currentCoords = action.payload;
    },
    changeCurrentCoords: (state, action: PayloadAction<Coordinates>) => {
      state.currentCoords = action.payload;
    },
    changeHeight: (state, action: PayloadAction<DraggedModuleHeight>) => {
      state.height = action.payload;
    },
    changeCurrentHeight: (state, action: PayloadAction<number>) => {
      state.currentHeight = action.payload;
    },
    changeMinHeight: (state, action: PayloadAction<number>) => {
      state.minHeight = action.payload;
    },
    changeArea: (state, action: PayloadAction<string>) => {
      if (state.area !== action.payload) {
        state.area = action.payload;
      }
    },
    changeIndex: (state, action: PayloadAction<number>) => {
      if (state.index !== action.payload) {
        state.index = action.payload;
      }
    },
    changePlacementNode: (state, action: PayloadAction<PlacementNode>) => {
      state.placementNode = action.payload;
    },
    setPrepareEnd: (state) => {
      state.prepareEnd = true;
    },
  },
});

export const drag = moduleDrag.actions;

const selectHeight = (state: RootState) => {
  if (state.moduleDrag.module) {
    return (
      state.character.sheetData.modules[state.moduleDrag.module]?.height ||
      state.moduleDrag.minHeight
    );
  }

  return undefined;
};

const selectModule = (state: RootState) => {
  if (state.moduleDrag.module) {
    return {
      ...state.character.sheetData.modules[state.moduleDrag.module],
      id: state.moduleDrag.module,
    };
  }

  return undefined;
};

const selectModuleId = (state: RootState) => state.moduleDrag.module

const selectIsDragging = (state: RootState) => Boolean(state.moduleDrag.module);
const selectIndex = (state: RootState) => state.moduleDrag.index;
const selectArea = (state: RootState) => state.moduleDrag.area;
const selectOriginalPosition = (state: RootState) => state.moduleDrag.originalPosition;
const selectPlacementNode = (state: RootState) =>
  state.moduleDrag.placementNode;
const selectMinHeight = (state: RootState) => state.moduleDrag.minHeight;
const selectCurrentHeight = (state: RootState) =>
  state.moduleDrag.currentHeight;
const selectStartCoords = (state: RootState) => state.moduleDrag.startCoords;
const selectCurrentCoords = (state: RootState) =>
  state.moduleDrag.currentCoords;
const selectPrepareEnd = (state: RootState) =>
  state.moduleDrag.prepareEnd;

export const selectDrag = {
  originalPosition: selectOriginalPosition,
  moduleId: selectModuleId,
  isDragging: selectIsDragging,
  index: selectIndex,
  area: selectArea,
  module: selectModule,
  height: selectHeight,
  placementNode: selectPlacementNode,
  minHeight: selectMinHeight,
  currentHeight: selectCurrentHeight,
  startCoords: selectStartCoords,
  currentCoords: selectCurrentCoords,
  prepareEnd: selectPrepareEnd,
};

export const moduleDragReducer = moduleDrag.reducer;
