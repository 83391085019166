import { SpellType } from "types";
import { findIndexById } from "utils";

import { ChangeReducer, CharacterReducer } from "./types";
import { create } from "./utils";

export const changeSpell: ChangeReducer<SpellType> = (state, action) => {
  const { index, value, key } = action.payload;
  state.spells[index][key] = value;
};

export const addSpell: CharacterReducer = (state) => {
  state.spells.push(create.spell());
};

export const removeSpell: CharacterReducer<{ index: number }> = (
  state,
  action
) => {
  state.spells.splice(action.payload.index, 1);
};

export const changeSpellOrder: CharacterReducer<{
  index: number;
  id: string;
}> = (state, action) => {
  const { index, id } = action.payload;
  const { spells } = state;
  const oldIndex = findIndexById(spells, id);
  const feature = spells[oldIndex];
  spells.splice(oldIndex, 1); // remove old
  spells.splice(index, 0, feature); // add new
};
