import { InputGroupBottomButton } from "./bottom-button";
import { InputGroupContainer } from "./container";
import { InputGroupRow } from "./row";
import { InputGroupRowButton } from "./row-button";
import { InputGroupCheckbox } from "./checkbox";

export const InputGroup = {
  Row: InputGroupRow,
  Container: InputGroupContainer,
  BottomButton: InputGroupBottomButton,
  RowButton: InputGroupRowButton,
  Checkbox: InputGroupCheckbox
};
