import styled from "styled-components";
import { Border } from "components"

export const ProficienciesContainer = styled.div`
  display: flex;
  font-size: 14px;
`;

export const MainProficiencies = styled(Border)`
  flex-basis: 136px;
  display: flex;
  flex-wrap: wrap;
  /* border: 1px solid black; */
  justify-content: center;
  align-items: stretch;
  padding-top: 4px;
`;

export const ProficiencySymbolContainer = styled.div`
  width: 50%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ProficiencySymbolLabel = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  font-size: 10px;
  line-height: 1;
  margin-bottom: 4px;
  text-align: center;
  justify-content: center;
  text-transform: uppercase;
`;

export const ProficiencySymbol = styled.div<{ isProficient: boolean }>`
  display: flex;
  flex-direction: column;

  height: 12px;
  width: 12px;

  border: 1px solid black;
  border-radius: 50%;

  background: ${({ isProficient }) => (isProficient ? "black" : "white")};
`;

export const LanguagesAndToolsContainer = styled(Border)`
  display: flex;
  flex-direction: column;
  flex-basis: 160px;
  flex-grow: 3;
  /* margin-left: 16px; */
  padding: 8px 16px;
  /* margin-left: --1px; */
`;

export const LanguagesAndToolsSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  align-items: center;
`;

export const SectionLabel = styled.div`
  max-width: 120px;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  border-bottom: 1px solid black;
  padding: 0 8px;
  margin-bottom: 8px;
`;

export const LanguagesAndToolsList = styled.div`
  width: 100%;
  font-size: 16px;
  text-align: left;
  text-transform: capitalize;
`;