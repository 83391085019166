import styled, { css, keyframes, StyledComponent } from "styled-components";

import { secondaryButtonStyles } from "styles";

const pop = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.016);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const dndActiveStyles = css`
  transform: scale(1.016);
  animation: ${pop} 0.12s ease-out;

  :before {
    content: "";
    position: absolute;
    opacity: 0;

    top: 0;
    left: -32px;

    width: calc(100% + 64px);
    height: 100%;
    z-index: 10;

    border-radius: 8px;
    box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.3),
      0 2px 8px 3px rgba(0, 0, 0, 0.3);
    animation: ${fadeIn} 0.12s ease-out;
    opacity: 1;
    cursor: grabbing;
  }
`;

export const dragging = (Component: StyledComponent<any, any, any, any>) =>
  styled(Component)`
    ${dndActiveStyles}
  `;


const outsideButtonStyles = (direction: "right" | "left") => css`
  position: absolute;
  height: 100%;
  top: 0;
  ${direction}: -32px;
  width: calc(32px + 8px);
  ${secondaryButtonStyles};
  ${`border-top-${direction}-radius`}: 8px;
  ${`border-bottom-${direction}-radius`}: 8px;
`;

export const DragHandle = styled.button`
  ${outsideButtonStyles("left")};
  cursor: grab;
`;

export const RemoveButton = styled.button`
  ${outsideButtonStyles("right")};

  &:before {
    position: absolute;
    content: "X";
    top: 50%;
    right: 0;
    width: 34px;
    color: black;
    transform: translateY(-50%);
    line-height: 1;
  }

  :focus:before {
    right: -2px;
  }
`;
