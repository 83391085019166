import styled from "styled-components";
import { Border } from "components"

export const Name = styled(Border)`
  align-self: center;
  border-right: none;
  font-size: 30px;
  padding: 16px;
  height: min-content;
  text-align: center;
`;