import styled from "styled-components";

import { a4 } from "styles";

const scale = 2 / 3;
const translate = ((1 / scale - 1) / 2) * 100;

export const SheetPage = styled.div`
  background: white;
  min-height: ${a4.height}px;
  max-height: ${a4.height}px;
  overflow: visible;
  width: ${a4.width}px;
  padding: 32px;

  display: grid;
  grid-template-areas: "top top top" "area1 area2 area3";
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: min-content;

  * {
    font-family: serif;
  }

  @media print {
    overflow: hidden;
    transform: scale(${scale}) translate(-${translate}%, -${translate}%);
  }
`;

export const SheetColumn = styled.div`
  position: relative;
  align-self: stretch;
  justify-self: stretch;
  max-height: inherit;

  @media print {
    display: block;
  }
`;

export const SheetColumnInner = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  :after {
    content: "";
    position: absolute;
    z-index: -100;
    top: -8px;
    left: -8px;
    background: red;
    width: calc(100% + 16px);
    height: calc(100% + 16px);
    border-radius: 24px;
    box-shadow: 0px 0px 16px 16px red;
    opacity: 0.3;
  }

  @media print {
    :after {
      display: none;
    }
  }
`;

export const CharacterSheetEditorContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const SheetContainerInner = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;
