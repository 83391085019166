import { useEffect, useCallback } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";

import { auth, db, Login, Reset, Register } from "./account";

import {
  CharacterList,
  Loader,
  Character
} from "components";
import { closeWindow } from "features/sheet";
import { FloatingSettings } from "components/floating-settings";

const GlobalStyles = createGlobalStyle`
  *, *:after, *:before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  * {
    font-family: sans-serif;
  }

  body {
    fill: none;
    stroke: black;
  }
`;

const Main = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const KeyboardManager = () => {
  const dispatch = useDispatch();

  const deselectModule = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        dispatch(closeWindow());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    document.addEventListener("keydown", deselectModule);

    return () => document.removeEventListener("keydown", deselectModule);
  }, [deselectModule]);

  return null;
};

const Application = () => {
  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();

  const fetchUser = useCallback(async () => {
    try {
      if (user) {
        const query = await db.collection("users").doc(user.uid).get();
        const data = await query.data();

        if (!data) {
          await db.collection("users").doc(user.uid).set({
            uid: user.uid,
            email: user.email,
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [user]);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (error) {
      alert(error.message);
      history.replace("/login");
      return;
    }
    if (!user) {
      history.replace("/login");
      return;
    }

    fetchUser();
  }, [user, loading, error, fetchUser, history]);

  return loading ? (
    <Loader />
  ) : (
    <Route path="/">
      <KeyboardManager />
      <FloatingSettings />
      <Route path="/" exact>
        <CharacterList />
      </Route>
      <Route path="/:id">
        <Character />
      </Route>
    </Route>
  );
};

export const App = () => (
  <Router>
    <GlobalStyles />
    <Main>
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/reset">
          <Reset />
        </Route>
        <Route exact path="/register">
          <Register />
        </Route>
        <Application />
      </Switch>
    </Main>
  </Router>
);
