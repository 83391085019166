import { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import {
  changeCombatStats,
  changePassiveStat,
  changeActiveStat,
  changeHitDice,
  selectCombatStats,
  selectActiveStats,
  selectPassiveStats,
} from "features/character";
import {
  NewNumberInput,
  DiceInput,
  InputGroup,
} from "components";
import { diceArray, CombatStatsType, GenericValueChange, DiceType } from "types";

const Container = styled.div`
  width: 100%;

  display: grid;
  gap: 16px;

  grid-template-columns: repeat(2, 1fr);
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;

  border: 1px solid #ddd;
  padding: calc(16px - 1px);
  border-radius: 16px;
  background: #fcfcfc;
`;

const GroupTitle = styled.h3`
  margin-bottom: 16px;
  font-size: 20px;
`;

const InputColumns = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);

  align-items: center;
`;

const PrimaryCard = styled(Card)`
  > label:first-of-type {
    margin-bottom: 16px;
  }
`

const labels = {
  armorClass: "Armor class",
  shield: "Shield armor class",
  initiative: "Initiative",
  hpMax: "Max HP",
  speed: "Walking",
  swimmingSpeed: "Swimming",
  climbingSpeed: "Climbing",
  flyingSpeed: "Flying",
};

const firstRow = ["armorClass", "shield"] as const;
const speedRow = [
  "speed",
  "swimmingSpeed",
  "climbingSpeed",
  "flyingSpeed",
] as const;

type CombatStatHandler = <T extends keyof CombatStatsType>(key: T) => (value: CombatStatsType[T]) => void
type GenericStatHandler = (id: string) => (value?: number) => void
type HitDieHandler = GenericValueChange<DiceType>

export const CombatStats = () => {
  const dispatch = useDispatch();
  const { hitDice, ...combatStats } = useSelector(selectCombatStats);
  const activeStats = useSelector(selectActiveStats).filter(
    (stat) => !stat.isNotStat
  );
  const passiveStats = useSelector(selectPassiveStats);
  

  const handleChange: CombatStatHandler =
    (key) =>
    (value) => {
      dispatch(changeCombatStats({ key, value }));
    };

  const handleChangeActiveStat: GenericStatHandler =
    (id) =>
    (value) => {
      dispatch(changeActiveStat({ id, value }));
    };

  const handleChangePassiveStat: GenericStatHandler =
    (id) =>
    (value) => {
      dispatch(changePassiveStat({ id, value }));
    };

  const handleChangeHitDice: HitDieHandler = (index, key) => (value) => {
    dispatch(changeHitDice.change({ index, value, key }));
  };

  const handleAddHitDice = () => {
    dispatch(changeHitDice.add());
  };

  const handleRemoveHitDice = (index: number) => () => {
    dispatch(changeHitDice.remove(index));
  };

  return (
    <Container>
      <PrimaryCard>
        <GroupTitle>Primary stats</GroupTitle>
        {activeStats.map(({ statName, statValue, id }) => (
          <NewNumberInput
            key={id}
            label={statName}
            alignRight
            size="small"
            value={statValue}
            onChange={handleChangeActiveStat(id)}
          />
        ))}
        <NewNumberInput
          alignRight
          size="small"
          label={labels.initiative}
          value={combatStats.initiative}
          onChange={handleChange("initiative")}
        />
      </PrimaryCard>
      <Card>
        <GroupTitle>Health</GroupTitle>
        <NewNumberInput
          alignRight
          size="small"
          label={labels.hpMax}
          value={combatStats.hpMax}
          onChange={handleChange("hpMax")}
        />
        <label style={{ marginTop: "16px", marginBottom: "4px" }} htmlFor="armorClass">
          Hit dice
        </label>
        <InputGroup.Container>
          {hitDice.map((dice, index) => (
            <InputGroup.Row key={dice.die}>
              <DiceInput
                excludeNoDice
                size="small"
                id={`hitDice-${index}`}
                value={dice.amount}
                diceValue={dice.die}
                onChange={handleChangeHitDice(index, "amount")}
                onChangeDice={handleChangeHitDice(index, "die")}
              />
              {hitDice.length !== 1 && (
                <InputGroup.RowButton onClick={handleRemoveHitDice(index)}>
                  X
                </InputGroup.RowButton>
              )}
            </InputGroup.Row>
          ))}
          {hitDice.length !== diceArray.length && (
            <InputGroup.BottomButton onClick={handleAddHitDice}>
              Add hit dice +
            </InputGroup.BottomButton>
          )}
        </InputGroup.Container>
      </Card>
      <Card>
        <GroupTitle>Armor</GroupTitle>
        <InputColumns>
          {firstRow.map((statID) => (
            <Fragment key={statID}>
              <label htmlFor={statID}>
                {labels[statID]}
              </label>
              <NewNumberInput
                id={statID}
                alignRight
                size="small"
                value={combatStats[statID]}
                onChange={handleChange(statID)}
              />
            </Fragment>
          ))}
        </InputColumns>
      </Card>
      <Card>
        <GroupTitle>Senses</GroupTitle>
          <InputColumns>
            {passiveStats.map(({ statName, statValue, id }) => (
              <Fragment key={id}>
                <label htmlFor={id}>
                  {statName}
                </label>
                <NewNumberInput
                  id={id}
                  alignRight
                  size="small"
                  value={statValue}
                  onChange={handleChangePassiveStat(id)}
                />
              </Fragment>
            ))}
          </InputColumns>
      </Card>
      <Card>
        <GroupTitle>Speed</GroupTitle>
        <InputColumns>
          {speedRow.map((statID) => (
            <Fragment key={statID}>
              <label htmlFor={statID}>
                {labels[statID]}
              </label>
              <NewNumberInput
                id={statID}
                alignRight
                size="small"
                value={combatStats[statID]}
                onChange={handleChange(statID)}
              />
            </Fragment>
          ))}
        </InputColumns>
      </Card>
    </Container>
  );
};
