import React from "react";

type Props = {
  onBlur?: React.FocusEventHandler,
  onKeyDown?: React.KeyboardEventHandler,
  onMouseDown?: React.MouseEventHandler,
}

export const useHideFocusOnClick = (props?: Props) => {
  const [hideFocus, setHideFocus] = React.useState(false);

  const onBlur = (e: any) => {
    setHideFocus(false);
    props?.onBlur?.(e);
  };

  const onKeyDown = (e: any) => {
    setHideFocus(false);
    props?.onKeyDown?.(e);
  };

  const onMouseDown = (e: any) => {
    setHideFocus(true);
    props?.onMouseDown?.(e);
  };

  return { hideFocus, onBlur, onKeyDown, onMouseDown, setHideFocus };
};
