import { FC, CSSProperties, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ZoomContainer, ZoomContainerProps } from "components";
import { SheetAreaType } from "types";
import { statValueColor } from "styles";
import {
  selectModules,
  selectShowAllStats,
  selectAreas,
} from "features/character";

import { Sidebar } from "./sidebar";
import {
  SheetPage,
  SheetColumn,
  SheetColumnInner,
  CharacterSheetEditorContainer,
  SheetContainerInner,
} from "./styles";
import { Module } from "./module-container";
import { TopArea } from "./top-area";
import { ChooseLayout } from "./choose-layout";
import { DragOverlay } from "./module-drop";

import styled, { css } from "styled-components";
import { selectDrag, drag } from "features/module-drag";
import { useState } from "react";

// TODO: Make DraggedModuleHeightDisplacer better
// More performant by moving out the selectors
// Move to its own file
const DraggedModuleHeightDisplacerComponent = styled.div<{ grow: boolean }>`
  height: 0px;
  width: 100%;

  ${({ grow }) =>
    grow &&
    css`
      flex-basis: 1px;
      flex-grow: 1;
    `}
`;

type DraggedModuleHeightDisplacerProps = {
  area: string;
  scale: number;
};

const DraggedModuleHeightDisplacer: FC<DraggedModuleHeightDisplacerProps> = ({
  area,
  scale,
}) => {
  const currentArea = useSelector(selectDrag.area);
  const height = useSelector(selectDrag.height);
  const minHeight = useSelector(selectDrag.minHeight);
  const placementNode = useSelector(selectDrag.placementNode);
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (ref.current && area === currentArea) {
      const newHeight = ref.current.offsetHeight;
      dispatch(drag.changeCurrentHeight(newHeight));

      if (!placementNode) {
        return;
      }
      const element = document.getElementById(placementNode.id);
      if (!element) {
        return;
      }

      const rect = element.getBoundingClientRect();
      const { x, y, height: placementNodeHeight } = rect;

      if (placementNode.placement === "before") {
        dispatch(drag.changeCurrentCoords([x, y]));
      } else if (placementNode.newArea) {
        dispatch(drag.changeCurrentCoords([x, y + placementNodeHeight]));
      } else {
        dispatch(
          drag.changeCurrentCoords([
            x,
            y + placementNodeHeight - newHeight * scale,
          ])
        );
      }
    }
  }, [currentArea, placementNode, area, dispatch, scale]);

  const style = {
    height,
    minHeight,
    display: currentArea === area ? "block" : "none",
  };

  return (
    <DraggedModuleHeightDisplacerComponent
      ref={ref}
      style={style}
      grow={height === "grow"}
    />
  );
};

type ColumnProps = {
  area: SheetAreaType;
  scale: number;
  draggedModule?: string;
};

const Column: FC<ColumnProps> = ({
  area,
  draggedModule,
  scale,
}) => {
  const columnRef = useRef<HTMLDivElement>(null);
  const modules = useSelector(selectModules(area));

  const height = useSelector(selectDrag.currentHeight);
  const currentArea = useSelector(selectDrag.area);
  const currentIndex = useSelector(selectDrag.index) || 0;

  const isDraggedModuleInArea = currentArea === area;

  const style = isDraggedModuleInArea ? `translateY(${height}px)` : undefined;

  const realModules = modules.filter((module) => module !== draggedModule);

  return (
    <SheetColumn ref={columnRef} style={{ gridArea: area }}>
      <SheetColumnInner>
        {realModules.map((id, index) =>
          draggedModule === id ? null : (
            <Module
              key={id}
              id={id}
              columnRef={columnRef}
              index={index}
              area={area}
              style={{
                transform:
                  isDraggedModuleInArea && currentIndex <= index
                    ? style
                    : undefined,
              }}
            />
          )
        )}
        {draggedModule && (
          <DraggedModuleHeightDisplacer
            scale={scale}
            area={area}
          />
        )}
      </SheetColumnInner>
    </SheetColumn>
  );
};

type CharacterSheetProps = { isLoading: boolean } & ZoomContainerProps;

export const CharacterSheet: FC<CharacterSheetProps> = ({
  isLoading,
  ...zoomContainerProps
}) => {
  const showStats = useSelector(selectShowAllStats);
  const areas = useSelector(selectAreas);
  const areasArray = Object.keys(areas) as SheetAreaType[];
  const showChooseLayout = !isLoading && areasArray.length === 0;
  const showSheet = !isLoading && areasArray.length > 0;

  const [hasMounted, setHasMounted] = useState(false);

  const draggedModuleId = useSelector(selectDrag.moduleId);

  const cssVariables = {
    [statValueColor]: showStats ? "black" : "white",
    "--animation-time": draggedModuleId && hasMounted ? "0.16s" : "0s",
    "--sheet-scale": zoomContainerProps.scale,
  } as CSSProperties;

  useLayoutEffect(() => {
    setTimeout(() => {
      setHasMounted(Boolean(draggedModuleId));
    }, 0);
  }, [draggedModuleId]);

  return (
    <CharacterSheetEditorContainer style={cssVariables}>
      <Sidebar />
      {showChooseLayout && <ChooseLayout />}
      <SheetContainerInner>
        {showSheet && (
          <>
            <ZoomContainer {...zoomContainerProps}>
              <SheetPage>
                <TopArea />
                {areasArray.map((area) => (
                  <Column
                    area={area}
                    key={area}
                    scale={zoomContainerProps.scale}
                    draggedModule={draggedModuleId}
                  />
                ))}
              </SheetPage>
            </ZoomContainer>
            <DragOverlay id={draggedModuleId} />
          </>
        )}
      </SheetContainerInner>
    </CharacterSheetEditorContainer>
  );
};
