import React from "react";
import styled, { css } from "styled-components";

type Size = {
  size?: "small"
}

export type CheckboxProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  "type" | "value" | "onChange" | "ref" | "size"
> & Size & {
  label?: string;
  value: boolean;
  onChange: (value: boolean, e: React.ChangeEvent<HTMLInputElement>) => void;
};

const CheckboxInput = styled.input`
  height: 0;
  width: 0;
  overflow: hidden;
  position: absolute;
  pointer-events: none;

  :checked + div:before {
    content: "✓";
  }
`;

const getSize = ({ size }: Size) => ({
  small: css`
    height: 16px;
    width: 16px;
    font-size: 12px;

    &:before {
      transform: translate(0.5px, 0.5px);
    }
  `,
  medium: css`
    height: 24px;
    width: 24px;

    &:before {
      transform: translate(-1px, 1px);
    }
  `
}[size || "medium"])

const CheckboxStyle = styled.div<Size>`
  ${getSize}
  border: 2px solid black;
  border-radius: 4px;
  display: flex;
  line-height: 1;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  :hover {
    background: #eee;
  }

  :active {
    background: #ddd;
  }
`;

export const CheckboxContainer = styled.label`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  border-radius: 8px;

  :focus-within > ${CheckboxStyle} {
    background: #eee;
  }
`;

const LabelStyles = styled.p`
  margin-right: 16px;
`;

export const Checkbox = ({
  label,
  value,
  onChange,
  className,
  style,
  size,
  ...props
}: CheckboxProps) => (
  <CheckboxContainer className={className} style={style}>
    {label && <LabelStyles>{label}</LabelStyles>}
    <CheckboxInput
      {...props}
      type="checkbox"
      checked={value}
      onChange={(e) => onChange(e.target.checked, e)}
    />
    <CheckboxStyle size={size} />
  </CheckboxContainer>
);
