import styled from "styled-components";

export const InputGroupContainer = styled.div`
  position: relative;
  width: 100%;

  > * {
    position: relative;
  }
`;
