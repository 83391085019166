import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import {
  changeModule,
  changeModuleFeature,
  selectAllFeatures,
  selectModuleTitle,
  selectModuleCharacterFeatures,
  selectModuleCharacterFeatureSubscriptions,
} from "features/character";

import { Checkbox, TextInput } from "components";
import { CharacterFeatureKeys, CharacterFeatureType } from "types";

import { MenuProps } from ".";

const featureKeyLabels = {
  classFeatures: "Class Features",
  feats: "Feats",
  racialTraits: "Racial Traits",
  otherTraits: "Other Traits",
} as const;

const Category = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Categories = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 8px;
`;

const CategoryCheckbox = styled(Checkbox)`
  > p {
    font-weight: 600;
  }
`;

const FeatureCheckbox = styled(Checkbox)`
  > p {
    padding-left: 8px;
  }
`;

export const CharacterFeaturesMenu = ({ id: moduleId }: MenuProps) => {
  const dispatch = useDispatch();

  const title = useSelector(selectModuleTitle(moduleId));
  const features = useSelector(selectAllFeatures);
  const moduleFeatures = useSelector(selectModuleCharacterFeatures(moduleId));
  const featureSubscriptions = useSelector(
    selectModuleCharacterFeatureSubscriptions(moduleId)
  );

  const changeTitle = (title: string) => {
    dispatch(changeModule.title({ moduleId, title }));
  };

  const onChangeFeature =
    (featureKey: CharacterFeatureKeys, featureId: string) =>
    (checked: boolean) => {
      const method = checked ? "add" : "remove";
      dispatch(
        changeModuleFeature[method]({ moduleId, featureKey, featureId })
      );
    };

  const onChangeSubscription =
    (featureKey: CharacterFeatureKeys) => (checked: boolean) => {
      const method = checked ? "subscribe" : "unsubscribe";
      dispatch(changeModuleFeature[method]({ moduleId, featureKey }));
    };

  const moduleIds = moduleFeatures.map((feature) => feature.id);
  const featuresArray = Object.entries(features) as [
    CharacterFeatureKeys,
    CharacterFeatureType[]
  ][];

  return (
    <>
      <TextInput
        label="Title"
        internalLabel
        value={title || ""}
        onChange={changeTitle}
      />
      <Categories>
        {featuresArray.map(([featureKey, features]) => (
          <Category key={featureKey}>
            <CategoryCheckbox
              label={featureKeyLabels[featureKey]}
              onChange={onChangeSubscription(featureKey)}
              value={featureSubscriptions.includes(featureKey)}
            />
            {features.map((feature) => (
              <FeatureCheckbox
                label={feature.label}
                key={feature.id}
                onChange={onChangeFeature(featureKey, feature.id)}
                value={moduleIds.includes(feature.id)}
              />
            ))}
          </Category>
        ))}
      </Categories>
    </>
  );
};
