import { useDispatch, useSelector } from "react-redux";

import { selectShowModuleStats, changeModule } from "features/character";

import { Button } from "components";

export const ToggleShowStatsButton = ({ id }: { id: string }) => {
  const dispatch = useDispatch()

  const showStats = useSelector(selectShowModuleStats(id))

  const onToggle = () => {
    dispatch(changeModule.statVisibility({ id }));
  };

  const symbol = showStats === undefined ? "-" : showStats === true ? "x" : "o"

  return (
    <Button onClick={onToggle}>Toggle stats {symbol}</Button>
  )
}