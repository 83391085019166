import { ProficienciesKeys, MainProficienciesKeys } from "types";
import { CharacterReducer } from "./types";

type ProficiencyReducer = CharacterReducer<{
  key: ProficienciesKeys;
  index: number;
  value: string;
}>;

type ProficienciesReducer = CharacterReducer<{
  key: ProficienciesKeys;
  value: string[];
}>;

type MainProficiencyReducer = CharacterReducer<{
  key: MainProficienciesKeys;
  value: boolean;
}>;

export const changeProficiency: ProficiencyReducer = (state, action) => {
  const { key, index, value } = action.payload;
  state.proficiencies[key][index] = value;
};

export const changeProficiencies: ProficienciesReducer = (state, action) => {
  const { key, value } = action.payload;
  state.proficiencies[key] = value;
};

export const changeMainProficiency: MainProficiencyReducer = (state, action) => {
  const { key, value } = action.payload;
  state.proficiencies.main[key] = value;
};
