import { CSSProperties, FC } from "react";
import styled from "styled-components";

import { statColorMixin } from "styles";

const ResourcesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`;

const ResourcesInnerContainer = styled.div`
  display: grid;
`;

const ResourcesLabel = styled.p`
  width: 100%;
  margin-bottom: 4px;

  text-transform: uppercase;
  text-align: center;
  font-size: 12px;
  line-height: 1;
`;

type ResourceSizes = "large" | "medium" | "small"
type ResourceStyle<K> = { [key in ResourceSizes]: K }

const resourceStyles: ResourceStyle<CSSProperties> = {
  large: {
    height: "64px",
    minWidth: "64px"
  },
  medium: {
    height: "56px",
    minWidth: "56px"
  },
  small: {
    height: "40px",
    minWidth: "40px",
    borderRadius: "8px",
  },
};

const resourceValueSmallFontStyles: ResourceStyle<string> = {
  large: `
    p:only-child {
      font-size: 20px;
    }
  `,
  medium: `
    p:only-child {
      font-size: 20px;
    }
  `,
  small: `
    p:only-child {
      font-size: 16px;
    }
  `,
};

const Resource = styled.div`
  padding: 4px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid black;
  border-radius: 16px;
  &:not(:last-of-type) {
    border-right: none;
  }
  &:not(:first-of-type) {
    border-left: none;
  }
`;

const ResourceLabel = styled.div`
  text-transform: uppercase;
  text-align: center;
  font-size: 10px;
  line-height: 1;
`;

const ResourceValue = styled.div<{ size: ResourceSizes }>`
  flex-grow: 1;
  text-align: center;
  ${statColorMixin};

  ${({ size }) => resourceValueSmallFontStyles[size]}
`;

type ResourceValueType = string | number;
type ResourceType = {
  label: string;
  value?: Array<ResourceValueType> | ResourceValueType;
};
type ResourcesProps = {
  resources: ResourceType[];
  label?: string;
  size?: ResourceSizes;
};

export const Resources: FC<ResourcesProps> = ({ label, resources, size = "large" }) => {
  const styles = resourceStyles[size]
  return (
  <ResourcesContainer>
    {label && <ResourcesLabel>hit dice</ResourcesLabel>}
    <ResourcesInnerContainer style={{ gridTemplateColumns: resources.map(() => "1fr").join(" ") }}>
      {resources.map(({ label, value }) => (
        <Resource
          key={label}
          style={styles}
        >
          <ResourceLabel>{label}</ResourceLabel>
          {value && (
            <ResourceValue size={size}>
              {Array.isArray(value) ? (
                value.map((value) => <p key={value}>{value}</p>)
              ) : (
                <p>{value}</p>
              )}
            </ResourceValue>
          )}
        </Resource>
      ))}
    </ResourcesInnerContainer>
  </ResourcesContainer>
)};
