import { CombatStatsType, DiceType, diceArray } from "types";

import { ChangeReducer, CharacterReducer, SimpleChangeReducer } from "./types";

type CombatStatsReducer = SimpleChangeReducer<CombatStatsType>;

export const changeCombatStats: CombatStatsReducer = (state, action) => {
  state.combatStats[action.payload.key] = action.payload.value;
};

export const changeHitDice: ChangeReducer<DiceType> = (state, action) => {
  const { key, value, index } = action.payload;
  state.combatStats.hitDice[index][key] = value;
};

export const removeHitDice: CharacterReducer<number> = (state, action) => {
  state.combatStats.hitDice.splice(action.payload, 1);
};

export const addHitDice: CharacterReducer = (state) => {
  const unusedHitDie =
    diceArray.find(
      (die) => !state.combatStats.hitDice.some((hitDie) => die === hitDie.die)
    ) || diceArray[0];

  state.combatStats.hitDice.push({
    amount: 1,
    die: unusedHitDie,
  });
};
