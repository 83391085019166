import styled, { css } from "styled-components";
import { Style, unit, noFocusMixin } from "styles";
import { InputButton } from "components";

export const textInputColors = {
  background: "white",
  border: "#777",
  text: "black",
  hoverBorder: "#444",
  activeBorder: "black",
  invalidBorder: "red", // Todo: Add validation styles
};

const textInputRawStyles: Style = {
  borderWidth: 2,
  borderRadius: 8,
  height: 40,
  verticalPadding: 10,
  verticalPaddingSmall: 6,
  horizontalPadding: 16,
  labelMargin: 4,
};

const styles = {
  borderWidth: unit(textInputRawStyles.borderWidth),
  borderRadius: unit(textInputRawStyles.borderRadius),
  height: unit(textInputRawStyles.height),
  horizontalPadding: unit(
    textInputRawStyles.horizontalPadding - textInputRawStyles.borderWidth
  ),
  verticalPadding: unit(
    textInputRawStyles.verticalPadding - textInputRawStyles.borderWidth
  ),
  verticalPaddingSmall: unit(
    textInputRawStyles.verticalPaddingSmall - textInputRawStyles.borderWidth
  ),
  labelMargin: unit(textInputRawStyles.labelMargin),
};

type InputStyles = { inputSize?: "small"; internalLabel?: boolean };

const sizes = ({ inputSize, internalLabel }: InputStyles) =>
  ({
    small: internalLabel
      ? css`
          padding: ${styles.horizontalPadding};
          padding-top: 20px;
          padding-bottom: 4px;
        `
      : css`
          padding: ${styles.verticalPaddingSmall} ${styles.horizontalPadding};
        `,
    medium: internalLabel
      ? css`
          padding: ${styles.horizontalPadding};
          padding-top: 20px;
          padding-bottom: 4px;
        `
      : css`
          padding: ${styles.verticalPadding} ${styles.horizontalPadding};
        `,
  }[inputSize || "medium"]);

export const inputStyles = css<{
  inputSize?: "small";
  internalLabel?: boolean;
}>`
  width: 100%;

  ${sizes}

  border-style: solid;
  border-width: ${styles.borderWidth};
  border-radius: ${styles.borderRadius};

  font-size: 16px;
  line-height: 20px;

  background-color: ${textInputColors.background};
  border-color: ${textInputColors.border};
  color: ${textInputColors.text};

  &:hover {
    border-color: ${textInputColors.hoverBorder};
    z-index: 1;
  }

  &:focus {
    ${noFocusMixin}
    border-color: ${textInputColors.activeBorder};
    z-index: 1;
  }
`;

export const Container = styled.label`
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;

  width: 100%;

  pointer-events: none;

  > * {
    pointer-events: all;
  }

  :focus-within,
  :hover {
    ${InputButton} {
      background: rgba(221, 221, 221, 1);
      color: rgba(0, 0, 0, 1);
    }

    span {
      margin-left: 8px;
      width: 10px;
      opacity: 1;
    }
  }
`;

const internalLabelStyles = css`
  position: absolute;
  top: 6px;
  font-size: 12px;
  font-weight: 600;
  left: 16px;
  z-index: 6;
  color: #555;
`;

export const Label = styled.p<{ internal?: boolean }>`
  margin-bottom: ${styles.labelMargin};
  ${({ internal }) => (internal ? internalLabelStyles : "")}
`;
