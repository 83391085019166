import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { DragOverlay, DndContext, closestCorners } from "@dnd-kit/core";

import {
  changeBase,
  selectBase,
  changeFeature,
  selectAllFeatures,
} from "features/character";
import { TextInput } from "components";
import { CharacterBaseType, CharacterFeatureKeys } from "types";

import { Features, OnFeatureChange, SimpleFeature } from "./features";
import { useComplexDragAndDrop } from "./utils";

const BaseContainer = styled.div`
  width: 100%;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const NameAndRaceContainer = styled.div`
  width: 100%;
  display: flex;

  > * {
    width: 100%;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

const featureKeys = ["feats", "racialTraits", "otherTraits"] as const;
const featureTitles = {
  feats: "Feats",
  racialTraits: "Racial traits",
  otherTraits: "Other traits",
} as const;

export const Base = () => {
  const dispatch = useDispatch();
  const { name, race, background } = useSelector(selectBase);
  const features = useSelector(selectAllFeatures);

  const handleBaseChange =
    (key: keyof CharacterBaseType) => (value: string) => {
      dispatch(changeBase({ key, value }));
    };

  const handleFeatureChange =
    (featureKey: CharacterFeatureKeys): OnFeatureChange =>
    (index, key) =>
    (value) => {
      dispatch(changeFeature.change({ featureKey, index, value, key }));
    };

  const handleRemoveFeature =
    (featureKey: CharacterFeatureKeys) => (index: number) => () => {
      dispatch(changeFeature.remove({ featureKey, index }));
    };

  const handleAddFeature = (featureKey: CharacterFeatureKeys) => () => {
    dispatch(changeFeature.add({ featureKey }));
  };

  const onChangeOrder = (
    index: number,
    id: string,
    featureKey: string,
    oldFeatureKey: string
  ) => {
    dispatch(
      changeFeature.move({
        featureKey: featureKey as CharacterFeatureKeys,
        index,
        id,
        oldFeatureKey: oldFeatureKey as CharacterFeatureKeys,
      })
    );
  };

  const { dndContextProps, activeItem, activeId, activeContainer } =
    useComplexDragAndDrop({
      items: features,
      onChangeOrder,
    });

  return (
    <DndContext {...dndContextProps} collisionDetection={closestCorners}>
      <BaseContainer>
        <NameAndRaceContainer>
          <TextInput
            label="Name"
            value={name}
            onChange={handleBaseChange("name")}
          />
          <TextInput
            label="Race"
            value={race}
            onChange={handleBaseChange("race")}
          />
          <TextInput
            label="Background"
            value={background}
            onChange={handleBaseChange("background")}
          />
        </NameAndRaceContainer>
        {featureKeys.map((key) => (
          <Features
            id={key}
            active={activeContainer === key}
            key={key}
            activeId={activeId}
            title={featureTitles[key]}
            features={features[key]}
            onChange={handleFeatureChange(key)}
            onRemove={handleRemoveFeature(key)}
            onAdd={handleAddFeature(key)}
          />
        ))}
        <DragOverlay>
          {activeItem && <SimpleFeature {...activeItem} />}
        </DragOverlay>
      </BaseContainer>
    </DndContext>
  );
};
