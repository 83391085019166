import { useSelector } from 'react-redux';
import { selectPassiveStats } from 'features/character';
import { ModuleType } from "../utils";
import { GenericStats } from "../generic-stats";

export const PassiveStats: ModuleType = () => {
  const passiveStats = useSelector(selectPassiveStats)

  return (
    <GenericStats stats={passiveStats} />
)};
