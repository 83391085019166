import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import {
  MainProficienciesKeys,
  mainProficiencyKeys,
  ProficienciesKeys,
} from "types";
import {
  selectProficiencies,
  changeProficiency,
} from "features/character";
import { TextInput, Checkbox } from "components";

const mainProficiencyLabels = {
  lightArmour: "Light armour",
  simpleWeapons: "Simple weapons",
  mediumArmour: "Medium armour",
  martialWeapons: "Martial weapons",
  heavyArmour: "Heavy armour",
  shields: "Shields",
} as const;

const proficiencyLabels = {
  languages: "Languages",
  weapons: "Weapons",
  tools: "Tools",
  other: "Other proficiencies",
} as const;

const proficiencyKeys = Object.keys(proficiencyLabels) as ProficienciesKeys[]

const ProficienciesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px 32px;
`;

const MainProficienciesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px 32px;
`;

export const Proficiencies = () => {
  const dispatch = useDispatch();
  const { main, ...proficiencies } =
    useSelector(selectProficiencies);

  const onChangeMainProficiency =
    (key: MainProficienciesKeys) => (value: boolean) => {
      dispatch(changeProficiency.changeMain({ key, value }));
    };

  const onChangeProficiencies =
    (key: ProficienciesKeys) => (joinedValue: string) => {
      const value = joinedValue.split(", ");
      dispatch(changeProficiency.changeMulti({ key, value }));
    };

  return (
    <ProficienciesContainer>
      <MainProficienciesContainer>
        {mainProficiencyKeys.map((key) => (
          <Checkbox
            key={key}
            label={mainProficiencyLabels[key]}
            value={main[key]}
            onChange={onChangeMainProficiency(key)}
          />
        ))}
      </MainProficienciesContainer>
      {proficiencyKeys.map(key => (
        <TextInput
          key={key}
          label={proficiencyLabels[key]}
          value={proficiencies[key].join(", ")}
          onChange={onChangeProficiencies(key)}
        />
      ))}
    </ProficienciesContainer>
  );
};
