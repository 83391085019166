import { FC } from "react";

import styled from "styled-components";

import { ClassType } from "types";
import { Border } from "components";

const ClassesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  position: relative;
`;

const MultiClassesContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TotalClassLevel = styled.div`
  border: 1px solid black;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  background: white;
  margin-left: 4px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  z-index: 10;
`;

const SmallClassLevel = styled(Border)`
  width: 40px;
  min-height: 40px;
  height: 100%;
  background: white;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  height: auto;
  align-self: stretch;
`;

const SmallClassName = styled(Border)`
  font-size: 16px;
  display: flex;
  align-items: center;
  margin: 4px 0;
  width: 100%;
  line-height: 1;
  padding: 4px 8px;
  justify-self: stretch;
  height: min-content;
  margin-left: 1px;
`;

const MultiClassContainer = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  position: relative;
  margin-left: -8px;

  &:not(:first-child) {
    margin-top: -4px;
  }
`;

const ClassNameLabel = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-height: 1em;
`

const totalLevel = (classes: ClassType[]) =>
  classes.reduce((acc, { level }) => acc + level, 0);

export const ClassMulti: FC<{ classes: ClassType[] }> = ({ classes }) => (
  <MultiClassesContainer>
    <TotalClassLevel>{totalLevel(classes)}</TotalClassLevel>
    <ClassesContainer>
      {classes.map(({ id, className, subClass, level }) => (
        <MultiClassContainer key={id}>
          <SmallClassLevel borderWidth={4} lineWidth={1} border="connected">
            {level}
          </SmallClassLevel>
          <SmallClassName borderWidth={4} lineWidth={1} border="flagRight">
            <ClassNameLabel>
              {className}
              {subClass && ` - ${subClass}`}
            </ClassNameLabel>
          </SmallClassName>
        </MultiClassContainer>
      ))}
    </ClassesContainer>
  </MultiClassesContainer>
);
