import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { characterListReducer } from "features/character-list";
import { characterReducer } from "features/character";
import { sheetReducer } from "features/sheet";
import { uiReducer } from "features/ui";
import { moduleDragReducer } from "features/module-drag";

export const store = configureStore({
  reducer: {
    characterList: characterListReducer,
    character: characterReducer,
    sheet: sheetReducer,
    ui: uiReducer,
    moduleDrag: moduleDragReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
