import { v4 as uuid } from "uuid";

import {
  AbilityScoresType,
  SkillsType,
  GenericStatsType,
  ProficienciesType,
  MainProficiencies,
  CharacterFeatureType,
  CombatStatsType,
  WeaponsType,
  ArmorsType,
  SpellcastingType,
  SpellType,
  ClassType,
  CharacterBaseType,
  CharacterFeaturesType,
  EquipmentType,
  CharacterType,
  SheetDataType,
} from "types";

export const abilityScores: AbilityScoresType = [
  { id: "str", name: "Strength", modifier: 3, value: 16 },
  { id: "dex", name: "Dexterity", modifier: 0, value: 10 },
  { id: "con", name: "Constitution", modifier: 3, value: 16 },
  { id: "int", name: "Intelligence", modifier: -2, value: 6 },
  { id: "wis", name: "Wisdom", modifier: 2, value: 14 },
  { id: "cha", name: "Charisma", modifier: 4, value: 18 },
];

export const activeStats: GenericStatsType = [
  {
    id: "inspirationDie",
    statName: "inspiration die",
    isModifier: false,
    statValue: undefined,
    isNotStat: true,
  },
  {
    id: "proficiencyBonus",
    statName: "proficiency bonus",
    isModifier: true,
    statValue: 3,
  },
];

export const passiveStats: GenericStatsType = [
  {
    id: "passiveInsight",
    statName: "Passive insight",
    isModifier: false,
    statValue: 12,
  },
  {
    id: "passivePerception",
    statName: "Passive perception",
    isModifier: false,
    statValue: 12,
  },
  {
    id: "passiveInvestigation",
    statName: "Passive investigation",
    isModifier: false,
    statValue: 8,
  },
];

export const skills: SkillsType = [
  {
    id: "strST",
    ability: "str",
    name: "Saving Throws",
    savingThrow: true,
    proficiencyMultiplier: 0,
  },
  { id: "athlt", ability: "str", name: "Athletics", proficiencyMultiplier: 1 },
  {
    id: "dexST",
    ability: "dex",
    name: "Saving Throws",
    savingThrow: true,
    proficiencyMultiplier: 1,
  },
  { id: "acrbt", ability: "dex", name: "Acrobatics", proficiencyMultiplier: 0 },
  {
    id: "slght",
    ability: "dex",
    name: "Sleight of Hand",
    proficiencyMultiplier: 0,
  },
  { id: "stlth", ability: "dex", name: "Stealth", proficiencyMultiplier: 0 },
  {
    id: "conST",
    ability: "con",
    name: "Saving Throws",
    savingThrow: true,
    proficiencyMultiplier: 0,
  },
  {
    id: "intST",
    ability: "int",
    name: "Saving Throws",
    savingThrow: true,
    proficiencyMultiplier: 0,
  },
  { id: "arcna", ability: "int", name: "Arcana", proficiencyMultiplier: 0 },
  { id: "histr", ability: "int", name: "History", proficiencyMultiplier: 0 },
  {
    id: "invst",
    ability: "int",
    name: "Investigation",
    proficiencyMultiplier: 0,
  },
  { id: "natur", ability: "int", name: "Nature", proficiencyMultiplier: 1 },
  { id: "relgn", ability: "int", name: "Religion", proficiencyMultiplier: 0 },
  {
    id: "wisST",
    ability: "wis",
    name: "Saving Throws",
    savingThrow: true,
    proficiencyMultiplier: 0,
  },
  {
    id: "anmlh",
    ability: "wis",
    name: "Animal Handling",
    proficiencyMultiplier: 1,
  },
  { id: "insgt", ability: "wis", name: "Insight", proficiencyMultiplier: 0 },
  { id: "prcpt", ability: "wis", name: "Perception", proficiencyMultiplier: 0 },
  { id: "srviv", ability: "wis", name: "Survival", proficiencyMultiplier: 1 },
  { id: "medcn", ability: "wis", name: "Medicine", proficiencyMultiplier: 0 },
  {
    id: "chaST",
    ability: "cha",
    name: "Saving Throws",
    savingThrow: true,
    proficiencyMultiplier: 1,
  },
  { id: "dcptn", ability: "cha", name: "Deception", proficiencyMultiplier: 0 },
  {
    id: "intmd",
    ability: "cha",
    name: "Intimidation",
    proficiencyMultiplier: 1,
  },
  {
    id: "perfr",
    ability: "cha",
    name: "Performance",
    proficiencyMultiplier: 1,
  },
  { id: "prssn", ability: "cha", name: "Persuasion", proficiencyMultiplier: 0 },
];

const mainProficiencies: MainProficiencies = {
  lightArmour: true,
  simpleWeapons: true,
  mediumArmour: true,
  martialWeapons: true,
  heavyArmour: false,
  shields: true,
};

export const proficiencies: ProficienciesType = {
  main: mainProficiencies,
  weapons: [],
  tools: ["Cook's utensils"],
  other: ["Flute", "Horn"],
  languages: ["common", "giant"],
};

export const combatStats: CombatStatsType = {
  armorClass: 12,
  shield: 2,
  initiative: 0,
  speed: 30,
  climbingSpeed: 15,
  flyingSpeed: 0,
  swimmingSpeed: 15,
  hpMax: 44,
  hitDice: [
    {
      amount: 5,
      die: "d8",
    }
  ],
};

export const classes: ClassType[] = [
  {
    id: uuid(),
    className: "Bard",
    subClass: "College of Valor",
    level: 5,
  },
];

export const racialTraits: CharacterFeatureType[] = [
  {
    id: uuid(),
    label: "Stone's Endurance",
    resources: 1,
    text: "When you take damage, use your reaction to reduce the damage by d12 + con modifier. Once per short/long rest.",
  },
  {
    id: uuid(),
    label: "Mountain Born",
    text: "Resistance to cold damage. Acclimated to all high altitudes",
  },
  {
    id: uuid(),
    label: "Powerful Build",
    text: "You count as one size larger when determining your carrying capacity and the weight you can push, drag, or lift.",
  },
];

export const classFeatures: CharacterFeatureType[] = [
  {
    id: uuid(),
    label: "Bardic inspiration",
    resources: 3, // charisma modifier
    text: `You can inspire others through stirring words or music. To do so, you use a bonus action on your turn to choose one creature other than yourself within 60 feet of you who can hear you. That creature gains one Bardic Inspiration die, a d8.

Once within the next 10 minutes, the creature can roll the die and add the number rolled to one ability check, attack roll, or saving throw it makes. The creature can wait until after it rolls the d20 before deciding to use the Bardic Inspiration die, but must decide before the DM says whether the roll succeeds or fails. Once the Bardic Inspiration die is rolled, it is lost. A creature can have only one Bardic Inspiration die at a time.

A creature that has a Bardic Inspiration die from you can roll that die and add the number rolled to a weapon damage roll it just made. Alternatively, when an attack roll is made against the creature, it can use its reaction to roll the Bardic Inspiration die and add the number rolled to its AC against that attack, after seeing the roll but before knowing whether it hits or misses.

You can use this feature a number of times equal to your Charisma modifier (a minimum of once). You regain any expended uses when you finish a short/long rest.`,
  },
  {
    id: uuid(),
    label: "Jack of All Trades",
    text: "Add half your proficiency bonus, rounded down, to any ability check you make that doesn't already include your proficiency bonus.",
  },
];

export const feats: CharacterFeatureType[] = [
  {
    id: uuid(),
    label: "Chef",
    text: `As part of a short rest, you can cook special food, provided you have ingredients and cook's utensils on hand. You can prepare enough of this food for a number of creatures equal to 4 + your proficiency bonus. At the end of the short rest, any creature who eats the food and spends one or more Hit Dice to regain hit points regains an extra 1d8 hit points.

With one hour of work or when you finish a long rest, you can cook a number of treats equal to your proficiency bonus. These special treats last 8 hours after being made. A creature can use a bonus action to eat one of those treats to gain temporary hit points equal to your proficiency bonus.`,
  },
];

export const otherTraits: CharacterFeatureType[] = [
  {
    id: uuid(),
    label: "Mountaineer",
    text: "You understand mountains and are able to traverse them with relative ease, as well as guide your allies along the treacherous routes.",
  }
];

export const features: CharacterFeaturesType = {
  classFeatures,
  feats,
  racialTraits,
  otherTraits,
};

export const weapons: WeaponsType = [
  {
    id: uuid(),
    name: "Warhammer",
    attackBonus: 5,
    ranged: false,
    versatile: true,
    damage: {
      amount: 1,
      die: "d8",
    },
    damageBonus: 0,
    damageType: "bludgeoning",
    notes: [
      {
        id: uuid(),
        title: "Versatile (1d10)",
      },
    ],
  },
];

export const armors: ArmorsType = [
  {
    id: uuid(),
    name: "Hide Armor",
    armorClass: 12,
    stealthDisadvantage: false,
    notes: [],
  },
];

export const equipment: EquipmentType = {
  armors,
  weapons,
};

export const spellcasting: SpellcastingType = {
  spellAttackModifier: 6,
  spellSaveDC: 14,
  cantripsAmount: 3,
  spellsAmount: 8,
  spellSlots: [
    { level: 1, amount: 4 },
    { level: 2, amount: 3 },
    { level: 3, amount: 2 },
    // { level: 4, amount: 4 },
    // { level: 5, amount: 4 },
    // { level: 6, amount: 4 },
    // { level: 7, amount: 4 },
    // { level: 8, amount: 4 },
    // { level: 9, amount: 4 },
  ],
};

export const spellIDs = [uuid(), uuid(), uuid(), uuid(), uuid(), uuid(), uuid(), uuid(), uuid(), uuid(), uuid()];

export const spells: SpellType[] = [
  {
    id: spellIDs[0],
    level: 0,
    name: "Vicious mockery",
    description: "(60ft, vision) wis ST, 2d4 psy, dis on next attack",
    longDescription: "You unleash a string of insults laced with subtle enchantments at a creature you can see within range. If the target can hear you (though it need not understand you), it must succeed on a Wisdom saving throw or take 2d4 psychic damage and have disadvantage on the next attack roll it makes before the end of its next turn.",
    reference: "",
  },
  {
    id: spellIDs[1],
    level: 0,
    name: "Message",
    description: `(120ft, aprox loc) whisper msg, can respond`,
    reference: "",
  },
  {
    id: spellIDs[2],
    level: 0,
    name: "Light",
    description: `1hr. Touch obj 10ft big. Any color, bright 20ft, dim 20 feet. Dismiss action. If obj held, dexST.`,
    reference: "",
  },
  {
    id: spellIDs[3],
    level: 1,
    name: "Animal Friendship",
    description: `(24h, 30ft, see and hear me) Int > 4, fails. Wis ST or charm. >1st + target`,
    reference: "",
  },
  {
    id: spellIDs[4],
    level: 1,
    name: "Speak with animals",
    description: `(10min, self, ritual) Min info on nearby locations and monsters, within the past day. Persuade for small favor maybe.`,
    reference: "",
  },
  {
    id: spellIDs[5],
    level: 1,
    name: "Heroism",
    description: `(Conc, 1 min, touch) Willing creature frightened immunity, 6 temp hp start of each turn. Lose temp hp on spell end. >1st + target.`,
    reference: "",
  },
  {
    id: spellIDs[6],
    level: 1,
    name: "Healing word",
    description: `(60ft see, bonus action) 1d4 + 6. >1st +1d4`,
    reference: "",
  },
  {
    id: spellIDs[7],
    level: 1,
    name: "Command",
    description: `(60ft, see, understand lang) One word, wisdom ST or follow non harmful command.`,
    reference: "",
  },
  {
    id: spellIDs[8],
    level: 2,
    name: "Locate Animal or Plant",
    description: `(Ritual) Describe or name a beast or plant. Learn direction, distance < 5 miles.`,
    reference: "",
  },
  {
    id: spellIDs[9],
    level: 2,
    name: "Lesser restoration",
    description: `(Touch) end disease or condition: blind, deaf, paralyze, or poison.`,
    reference: "",
  },
  {
    id: spellIDs[10],
    level: 3,
    name: "Mass healing word",
    description: `(60ft see, bonus action) < 7, HP 1d4 + 6`,
    reference: "",
  },
];

export const base: CharacterBaseType = {
  name: `Arunaka Anakalathai`,
  race: "Goliath",
  background: "Outlander",
};

export const sheetData: SheetDataType = {
  showAllStats: true,
  layout: {
    areas: {
      area1: {
        modules: []
      },
      area2: {
        modules: []
      },
      area3: {
        modules: []
      },
    }
  },
  modules: {}
}

export const initialState: CharacterType = {
  id: uuid(),
  createdAt: Date.now(),
  savedAt: Date.now(),
  base,
  activeStats,
  passiveStats,
  skills,
  spells,
  combatStats,
  abilityScores,
  classes,
  spellcasting,
  proficiencies,
  equipment,
  features,
  sheetData
};
