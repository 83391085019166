import styled from "styled-components";
import { Link, useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { selectLastStatTab } from "features/ui";

const CharacterSheetNav = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  width: 100%;
  border: none;
`;

const CharacterSheetLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  text-align: center;
  font-size: 16px;
  border: none;
  border: 0px solid black;
  border-bottom-width: 1px;
  
  padding: calc(8px - 2px) calc(16px - 2px) calc(8px - 1px);
  min-height: 32px;
  line-height: 1;
  text-decoration: none;
  color: black;
  
  &:first-child {
    border-right-width: 1px;
  }

  &.active {
    padding-bottom: calc(8px - 4px);
    border-bottom-width: 4px;
  }
`;

export const CharacterTabs = () => {
  const lastStatTab = useSelector(selectLastStatTab);
  const { id } = useParams<{ id: string }>()
  const location = useLocation().pathname
  const isStats = location.includes("/stats")

  return (
    <CharacterSheetNav>
      <CharacterSheetLink className={!isStats ? "active" : ""} to={`/${id}/sheet`}>Sheet</CharacterSheetLink>
      <CharacterSheetLink className={isStats ? "active" : ""} to={`/${id}/stats${lastStatTab}`}>
        Stats
      </CharacterSheetLink>
    </CharacterSheetNav>
  )
}