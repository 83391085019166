import { RootState } from "app/store";

export const selectModulesStates = (state: RootState) => ({
  draggedModule: state.sheet.draggedModule,
  movedModule: state.sheet.movedModule,
});

export const selectIsModuleActive = (id: string) => (state: RootState) =>
  state.sheet.activeModule === id;

export const selectIsStyleSelectActive = (state: RootState) =>
  state.sheet.styleSelectActive;
