import { FC } from "react";
import styled from "styled-components";

import { ClassType } from "types";

const ClassesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

const ClassContainer = styled.div`
  display: flex;
`;

const ClassLevel = styled.div`
  border: 1px solid black;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-top: -16px;
  margin-right: -16px;
  background: white;
  margin-left: 16px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  z-index: 1;
`;

const ClassName = styled.div`
  background: white;
  border: 1px solid black;
  font-size: 32px;
  line-height: 40px;
  padding: 4px 16px;
  justify-self: stretch;
`;

const SubClassName = styled.div`
  background: white;
  border: 1px solid black;
  border-top: none;
  font-size: 20px;
  line-height: 24px;
  width: calc(100% - 48px);
  padding: 4px 16px;
  align-self: center;
`;

const SingleClassNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  position: relative;
`;

export const ClassSingle: FC<ClassType> = ({ id, className, subClass, level }) => (
  <ClassesContainer>
    <ClassContainer key={id}>
      <ClassLevel>{level}</ClassLevel>
      <SingleClassNameContainer>
        <ClassName>{className}</ClassName>
        {subClass && <SubClassName>{subClass}</SubClassName>}
      </SingleClassNameContainer>
    </ClassContainer>
  </ClassesContainer>
)
