import { useSelector } from "react-redux"
import styled from "styled-components";

import { selectCombatStats } from "features/character";
import { Border } from "components";

import { ModuleType } from "../utils"

import { ArmorClassWithShield } from "./armor-class";
import { SimpleStat } from "./simple-stat";
import { HitPoints, TempHitPoints } from "./hit-points";
import { HitDice } from "./hit-dice";
import { DeathSaves } from "./death-saves";

const CombatStatsContainer = styled(Border)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 8px 16px;

  stroke: white;
  fill: #f0f0f0;
  --module-background: #f0f0f0;

  gap: 8px;
`;

export const CombatStats: ModuleType = () => {
  const {
    armorClass,
    shield,
    initiative,
    speed,
    hpMax,
    hitDice
  } = useSelector(selectCombatStats)

  return (
  <CombatStatsContainer lineWidth={4} borderWidth={16} border="stylish">
    <ArmorClassWithShield armorClass={armorClass} shield={shield} />
    <SimpleStat label="initiative" value={initiative} />
    <SimpleStat label="speed" value={speed} />
    <HitPoints hpMax={hpMax} />
    <TempHitPoints />
    <HitDice hitDice={hitDice} />
    <DeathSaves />
  </CombatStatsContainer>
)};
