import styled, { CSSObject } from "styled-components";

import { RowButton } from "components";

import { inputStyles, Container, Label } from "../styles";
import {
  useDropdown,
  DropdownContainer,
  DropdownItem,
} from "components/internal";

const SelectContainer = styled(Container)`
  position: relative;
`;

export const SelectHandler = styled.button<{
  inputSize?: "small";
  internalLabel?: boolean;
  isPlaceholder?: boolean;
}>`
  ${inputStyles};
  color: ${({ isPlaceholder }) => isPlaceholder ? "#888" : "#000"};
  display: flex;
  justify-content: space-between;
  align-content: center;
`;

const DropdownIcon = styled.span`
  margin-left: 8px;
  color: #000;
`;

type SelectProps<T> = {
  options: { value: T; label: string }[];
  value: T;
  onChange: (value: T) => void;
  label?: string;
  placeholder?: string;
  className?: string;
  style?: CSSObject;
  size?: "small";
  internalLabel?: boolean;
};

export function Select<T>({
  options,
  value,
  label,
  onChange,
  className,
  placeholder = "",
  style,
  size,
  internalLabel,
}: SelectProps<T>) {
  const { isOpen, handlerProps, itemProps } = useDropdown<T>({
    onChange,
    value,
    options,
  });

  const selectedOption = options.find((option) => option.value === value);
  const textValue = selectedOption?.label || placeholder;

  return (
    <SelectContainer className={className} style={style}>
      {label && <Label internal={internalLabel}>{label}</Label>}
      <SelectHandler
        {...handlerProps}
        inputSize={size}
        internalLabel={internalLabel}
        isPlaceholder={textValue === placeholder}
      >
        {textValue}
        <DropdownIcon>{isOpen ? "˄" : "˅"}</DropdownIcon>
      </SelectHandler>
      {isOpen && (
        <DropdownContainer>
          {options.map((option, index) => (
            <DropdownItem key={option.label}>
              <RowButton {...itemProps(option, index)}>
                {option.label}
              </RowButton>
            </DropdownItem>
          ))}
        </DropdownContainer>
      )}
    </SelectContainer>
  );
}
