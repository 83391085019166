import React, { FC } from "react";

import {
  AbilityScoresColumn,
  AbilityScoreBlock,
  AbilityScoreName,
  AbilityScoreLarge,
  AbilityScoreSmall,
} from "./components";
import { getModifierSymbol } from "utils";
import { AbilityScoreType } from "types";
import { BorderProps } from "components";

const AbilityScore: FC<AbilityScoreType & BorderProps> = ({
  name,
  modifier,
  value,
  ...props
}) => (
  <AbilityScoreBlock {...props}>
    <AbilityScoreName>{name}</AbilityScoreName>
    <AbilityScoreLarge>{getModifierSymbol(modifier)}</AbilityScoreLarge>
    <AbilityScoreSmall {...props}>{value}</AbilityScoreSmall>
  </AbilityScoreBlock>
);

type AbilityScoresProps = { abilityScores: AbilityScoreType[] } & BorderProps;

export const AbilityScores: FC<AbilityScoresProps> = ({
  abilityScores,
  ...props
}) => (
  <AbilityScoresColumn>
    {abilityScores.map((abilityScore) => (
      <AbilityScore key={abilityScore.name} {...abilityScore} {...props} />
    ))}
  </AbilityScoresColumn>
);
