import React, { FC } from "react";

import { SkillsType, AbilityScoresType } from "types";
import { findByProperty, getModifierSymbol } from "utils";
import {
  SkillsContainer,
  SkillGroupContainer,
  SkillGroupAbilityLabelContainer,
  SkillGroupAbilityLabel,
  SkillGroupList,
  SkillGroupListItem,
  SkillSymbol,
  SavingThrowSymbol,
  SkillValue,
  SkillName,
} from "./components";

type SkillsProps = { abilityScores: AbilityScoresType; skills: SkillsType };
type GroupSkillsType = (
  abilityScores: AbilityScoresType,
  skills: SkillsType
) => { groupedSkills: SkillsType; modifier: number }[];

const groupSkills: GroupSkillsType = (abilityScores, skills) =>
  abilityScores.map(({ id, modifier }) => ({
    groupedSkills: findByProperty("ability", skills, id),
    modifier,
  }));

const isSavingThrow = (name: string): boolean => name === "Saving Throws";

const SkillGroup: FC<{ skills: SkillsType; modifier: number }> = ({
  skills,
  modifier,
}) => (
  <SkillGroupContainer>
    <SkillGroupAbilityLabelContainer>
      <SkillGroupAbilityLabel>{skills[0].ability}</SkillGroupAbilityLabel>
    </SkillGroupAbilityLabelContainer>
    <SkillGroupList>
      {skills.map(({ id, name, proficiencyMultiplier }) => (
        <SkillGroupListItem key={id}>
          {isSavingThrow(name) ? (
            <SavingThrowSymbol proficiencyMultiplier={proficiencyMultiplier} />
          ) : (
            <SkillSymbol proficiencyMultiplier={proficiencyMultiplier} />
          )}
          <SkillValue>
            {/* TODO: Add proficiency bonus here instead of the 3: */}
            {getModifierSymbol(modifier + 3 * proficiencyMultiplier)}
          </SkillValue>
          <SkillName>{name}</SkillName>
        </SkillGroupListItem>
      ))}
    </SkillGroupList>
  </SkillGroupContainer>
);

export const Skills: FC<SkillsProps> = ({ skills, abilityScores }) => (
  <SkillsContainer>
    {groupSkills(abilityScores, skills).map(({ groupedSkills, modifier }) => (
      <SkillGroup
        key={groupedSkills[0].ability}
        skills={groupedSkills}
        modifier={modifier}
      />
    ))}
  </SkillsContainer>
);
