import { FC } from "react";
import styled, { css } from "styled-components";

import { ArmorType } from "types";

import {
  EquipmentRow,
  EquipmentRowField,
  EquipmentLabelsRow,
  EquipmentLabel,
  EquipmentNotes,
} from "./styles";

const armorLabels = ["armor", "armor class", "str", "stealth"];

const armorFieldWidths = {
  name: css`
    flex-grow: 1;
    min-width: 0;
  `,
  attackBonus: css`
    flex-grow: 0;
    min-width: 80px;
  `,
  damage: css`
    flex-grow: 0;
    min-width: 28px;
  `,
  damageType: css`
    flex-grow: 0;
    min-width: 54px;
  `,
};

const ArmorName = styled(EquipmentRowField)`
  ${armorFieldWidths.name};
`;
const ArmorAC = styled(EquipmentRowField)`
  ${armorFieldWidths.attackBonus};
`;
const ArmorStrength = styled(EquipmentRowField)`
  ${armorFieldWidths.damage};
`;
const ArmorStealth = styled(EquipmentRowField)`
  ${armorFieldWidths.damageType};
  text-align: center;
`;

const ArmorNameLabel = styled(EquipmentLabel)`
  ${armorFieldWidths.name};
`;

const ArmorACLabel = styled(EquipmentLabel)`
  ${armorFieldWidths.attackBonus};
`;

const ArmorStrengthLabel = styled(EquipmentLabel)`
  ${armorFieldWidths.damage};
`;

const ArmorStealthLabel = styled(EquipmentLabel)`
  ${armorFieldWidths.damageType};
`;

const Armor: FC<Partial<ArmorType>> = ({
  name,
  armorClass,
  strength,
  stealthDisadvantage,
  notes,
}) => (
  <>
    <EquipmentRow>
      <ArmorName>{name}</ArmorName>
      <ArmorAC>{armorClass}</ArmorAC>
      <ArmorStrength>{strength}</ArmorStrength>
      <ArmorStealth>{stealthDisadvantage ? "X" : null}</ArmorStealth>
    </EquipmentRow>
    {notes && <EquipmentNotes notes={notes} />}
  </>
);

export const Armors: FC<{ armors: ArmorType[] }> = ({ armors }) => (
  <>
    <EquipmentLabelsRow>
      <ArmorNameLabel>{armorLabels[0]}</ArmorNameLabel>
      <ArmorACLabel>{armorLabels[1]}</ArmorACLabel>
      <ArmorStrengthLabel>{armorLabels[2]}</ArmorStrengthLabel>
      <ArmorStealthLabel>{armorLabels[3]}</ArmorStealthLabel>
    </EquipmentLabelsRow>
    {armors.map((armor) => (
      <Armor key={armor.id} {...armor} />
    ))}
    {armors.length === 0 && <Armor />}
  </>
);
