import * as characterBaseReducers from "./base";
import * as combatStatsReducers from "./combatStats";
import * as classesReducers from "./classes";
import * as featuresReducers from "./features";
import * as abilityScoreAndSkillsReducers from "./abilityScoresAndSkills";
import * as spellsReducers from "./spells";
import * as spellcastingReducers from "./spellcasting";
import * as equipmentReducers from "./equipment";
import * as baseStatsReducers from "./baseStats";
import * as proficienciesReducers from "./proficiencies";
import * as initializeReducers from "./initialize";
import * as sheetReducers from "./sheet";

export const reducers = {
  ...characterBaseReducers,
  ...abilityScoreAndSkillsReducers,
  ...proficienciesReducers,
  ...baseStatsReducers,
  ...equipmentReducers,
  ...spellcastingReducers,
  ...combatStatsReducers,
  ...spellsReducers,
  ...featuresReducers,
  ...classesReducers,
  ...initializeReducers,
  ...sheetReducers,
}
