import styled from "styled-components";
import { Button } from "components";
import { Input } from "../text-input";
import { focusRingMixin } from "styles";

export const numberInputStyles = {
  size: "--number-input-size",
  width: "--number-input-width",
}

export const sizes = { small: "32px", medium: "40px" }
export const widths = { normal: "128px", fullWidth: "100%" }

export type NumberInputSize = keyof typeof sizes

export const NumberInputContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  width: var(${numberInputStyles.width});
  height: var(${numberInputStyles.size});

  :focus-within:before {
    ${focusRingMixin()}
  }

  > button {
    width: var(${numberInputStyles.size});
    min-width: var(${numberInputStyles.size});
    min-height: var(${numberInputStyles.size});
    padding: 0;
  }
`;

export const NumberInputLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  margin-bottom: 4px;
`;

export const StyledInput = styled(Input)`
  text-align: center;
  height: var(${numberInputStyles.size});

  border-color: black;
  padding: 0;

  border-radius: 0;
  border-left-width: 0;
  border-right-width: 0;

  -moz-appearance: textfield;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const IncrementButton = styled(Button)`
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
`;

export const DecrementButton = styled(Button)`
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
`;
