import { ChangeEvent, KeyboardEvent, useRef } from "react";

import { InputButton } from "components";
import { Input, ButtonsContainer } from "./styles";

import { Container, Label } from "../styles";
import { InputSize, BaseInputProps } from "../types";

export type NewNumberInputProps<T extends number | undefined> = BaseInputProps & InputSize & {
  onChange: (value: T) => void;
  label?: string;
  value: T;
  min?: number;
  max?: number;
  step?: number;
  /** TODO: In progress */
  mask?: {
    display: (stringValue: string) => string,
    parse: (maskedValue: string) => number | undefined
  },
  alignRight?: true;
  internalLabel?: boolean;
};

export function NewNumberInput<T extends number | undefined> ({
  children,
  label,
  onChange,
  value,
  min,
  max,
  style,
  size,
  mask,
  alignRight,
  internalLabel,
  step = 1,
  ...props
}: NewNumberInputProps<T>) {
  const ref = useRef<HTMLInputElement>(null);

  const isValueBad = (newValue: number) =>
    (max && newValue > max) || (min && newValue < min);

  const changeByStep = (modifier: number) => () => {
    const currentValue = value ? Math.floor(value as number / step) : 0;
    const newValue = Number(currentValue + modifier) * step;
    !isValueBad(newValue) && onChange?.(newValue as T);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (["ArrowUp", "ArrowDown"].includes(e.code)) {
      const modifier = e.code === "ArrowUp" ? 1 : -1
      e.preventDefault()
      changeByStep(modifier)()
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newValue = undefined

    if (mask) {
      newValue = mask.parse(e.target.value)
    } else if (e.target.value !== "") {
      newValue = Number(e.target.value)
    }

    if (newValue !== undefined) {
      !isValueBad(newValue) && onChange?.(newValue as T);
    } else {
      onChange?.(newValue as T);
    }
  };

  const handleButtonFocus = () => {
    ref.current?.focus();
  };

  const isEmpty = value === undefined;
  const stringValue = isEmpty ? "" : String(value)
  const displayValue = mask ? mask.display(stringValue) : stringValue

  const buttonsSize = size === "small" || internalLabel ? "small" : undefined

  return (
    <Container>
      {label && <Label internal={internalLabel}>{label}</Label>}
      <Input
        internalLabel={internalLabel}
        inputSize={size}
        ref={ref}
        style={{
          caretColor: (isEmpty && props.placeholder) ? "transparent" : "black",
          textAlign: alignRight ? "right" : "center",
          ...style
        }}
        type="number"
        value={displayValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        {...props}
      />
      <ButtonsContainer size={buttonsSize} >
        <InputButton
          size={buttonsSize}
          tabIndex={-1}
          onFocus={handleButtonFocus}
          onClick={changeByStep(-1)}
        >
          -
        </InputButton>
        <InputButton
          size={buttonsSize}
          tabIndex={-1}
          onFocus={handleButtonFocus}
          onClick={changeByStep(1)}
        >
          +
        </InputButton>
      </ButtonsContainer>
      {children}
    </Container>
  );
};
