import React, { useState, memo, MouseEvent } from "react";
import { useDispatch } from "react-redux";

import { Button } from "components";
import { closeWindow } from "features/sheet";

import { AllModulesKeys } from "../../sheet-modules";
import { isResizeable, ResizeButton } from "../resize";

import { SpellMenu } from "./spells-menu";
import { StyleMenu } from "./style-menu";
import { ToggleShowStatsButton } from "./toggle-show-stats";
import { CharacterFeaturesMenu } from "./character-features";
import { MoveButton, CancelButton, ControlsContainer, ControlsWrapper } from "./styles";
import { drag } from "features/module-drag";
import { getExtraHeightId } from "../../sheet-modules/utils";

export { MoveButton }

type ModuleContainerControlsProps = {
  endDrag: () => void;
  remove: () => void;
  noMoveOnZoom: (e: React.MouseEvent) => void;
  toggleShowStats?: () => void;
  moduleName: AllModulesKeys;
  id: string;
  index: number;
  area: string;
};

export type MenuProps = {
  changeMenu: (newMenu: Menus) => () => void;
} & ModuleContainerControlsProps;

const DefaultMenu = ({ moduleName, remove, changeMenu, id }: MenuProps) => (
  <>
    <Button onClick={changeMenu("style")}>Change style</Button>
    {moduleName === "Spellcasting" && (
      <Button onClick={changeMenu("spells")}>Edit spell list</Button>
    )}
    {moduleName === "CharacterFeatures" && (
      <Button onClick={changeMenu("characterFeatures")}>Edit features</Button>
    )}
    <ToggleShowStatsButton id={id} />
    <Button onClick={remove}>Delete</Button>
  </>
);

const menus = {
  default: DefaultMenu,
  style: StyleMenu,
  spells: SpellMenu,
  characterFeatures: CharacterFeaturesMenu,
};

type Menus = keyof typeof menus;

export const ModuleContainerControls = memo<ModuleContainerControlsProps>(
  (props) => {
    const dispatch = useDispatch();
    const [menu, setMenu] = useState<Menus>("default");

    const changeMenu = (newMenu: Menus) => () => setMenu(newMenu);

    const deselect = () => {
      dispatch(closeWindow());
    };

    const stopPropogation = (e: MouseEvent) => e.stopPropagation();

    const onDrag = (e: MouseEvent) => {
      e.stopPropagation();

      dispatch(drag.changeArea(props.area))
      dispatch(drag.changeIndex(props.index))
      dispatch(drag.start(props.id))
      dispatch(drag.changeOriginalPosition({ area: props.area, index: props.index }))

      const element = document.getElementById(props.id);
      if (element) {
        const rect = element.getBoundingClientRect();
        const { x, y } = rect;
        const { clientHeight } = element;

        let height = clientHeight

        const extraHeightId = getExtraHeightId(props.id)
        const extraHeightElement = document.getElementById(extraHeightId)

        if (extraHeightElement) {
          height = height - extraHeightElement.clientHeight
        }

        dispatch(drag.changeHeight(clientHeight))
        dispatch(drag.changeMinHeight(height))
        dispatch(drag.changeStartCoords([x, y]));
      }
    }
 
    return (
      <ControlsWrapper>
        {isResizeable(props.moduleName) && <ResizeButton />}
        <CancelButton
          size="small"
          onMouseDown={stopPropogation}
          onClick={deselect}
        >
          X
        </CancelButton>
        <ControlsContainer onMouseDown={stopPropogation}>
          {menu !== "default" && (
            <Button onClick={changeMenu("default")}>Back</Button>
          )}
          {React.createElement(menus[menu], { ...props, changeMenu })}
        </ControlsContainer>
        <MoveButton
          onMouseDown={onDrag}
          onMouseUp={props.endDrag}
        />
      </ControlsWrapper>
    );
  }
);
