import { useState } from "react";

import { Button, TextInput, InputGroup } from "components";

import { signInWithEmailAndPassword, signInWithGoogle } from "./authentication";
import {
  useAuthStatus,
  LoginContainer,
  LoginText,
  LoginLink,
  Title,
} from "./common";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const signIn = () => signInWithEmailAndPassword(email, password);

  useAuthStatus();

  return (
    <LoginContainer>
      <Title>
        <span>Sign in to</span>Character Draft
      </Title>
      <InputGroup.Container>
        <InputGroup.Row>
          <TextInput
            label="Email address"
            internalLabel
            type="email"
            value={email}
            onChange={setEmail}
          />
        </InputGroup.Row>
        <InputGroup.Row>
          <TextInput
            label="Password"
            internalLabel
            type="password"
            value={password}
            onChange={setPassword}
          />
        </InputGroup.Row>
      </InputGroup.Container>
      <Button onClick={signIn}>Sign in</Button>
      <Button onClick={signInWithGoogle}>Sign in with Google</Button>
      <LoginText>
        <p>
          <LoginLink to="/reset">Forgot password?</LoginLink>
        </p>
        <p>
          Don't have an account? <LoginLink to="/register">Register</LoginLink>{" "}
          now.
        </p>
      </LoginText>
    </LoginContainer>
  );
};
