import {
  AbilityScoresType,
  SkillsType,
  GenericStatsType,
  ProficienciesType,
  MainProficiencies,
  CharacterFeatureType,
  CombatStatsType,
  WeaponsType,
  ArmorsType,
  SpellcastingType,
  SpellType,
  CharacterFeaturesType,
  EquipmentType,
  CharacterType,
  SheetDataType,
} from "types";

export const abilityScores: AbilityScoresType = [
  { id: "str", name: "Strength", modifier: 0, value: 10 },
  { id: "dex", name: "Dexterity", modifier: 0, value: 10 },
  { id: "con", name: "Constitution", modifier: 0, value: 10 },
  { id: "int", name: "Intelligence", modifier: 0, value: 10 },
  { id: "wis", name: "Wisdom", modifier: 0, value: 10 },
  { id: "cha", name: "Charisma", modifier: 0, value: 10 },
];

export const activeStats: GenericStatsType = [
  {
    id: "inspirationDie",
    statName: "inspiration die",
    isModifier: false,
    isNotStat: true,
  },
  {
    id: "proficiencyBonus",
    statName: "proficiency bonus",
    isModifier: true,
  },
];

export const passiveStats: GenericStatsType = [
  {
    id: "passiveInsight",
    statName: "Passive insight",
    isModifier: false,
  },
  {
    id: "passivePerception",
    statName: "Passive perception",
    isModifier: false,
  },
  {
    id: "passiveInvestigation",
    statName: "Passive investigation",
    isModifier: false,
  },
];

export const skills: SkillsType = [
  {
    id: "strST",
    ability: "str",
    name: "Saving Throws",
    savingThrow: true,
    proficiencyMultiplier: 0,
  },
  { id: "athlt", ability: "str", name: "Athletics", proficiencyMultiplier: 0 },
  {
    id: "dexST",
    ability: "dex",
    name: "Saving Throws",
    savingThrow: true,
    proficiencyMultiplier: 0,
  },
  { id: "acrbt", ability: "dex", name: "Acrobatics", proficiencyMultiplier: 0 },
  {
    id: "slght",
    ability: "dex",
    name: "Sleight of Hand",
    proficiencyMultiplier: 0,
  },
  { id: "stlth", ability: "dex", name: "Stealth", proficiencyMultiplier: 0 },
  {
    id: "conST",
    ability: "con",
    name: "Saving Throws",
    savingThrow: true,
    proficiencyMultiplier: 0,
  },
  {
    id: "intST",
    ability: "int",
    name: "Saving Throws",
    savingThrow: true,
    proficiencyMultiplier: 0,
  },
  { id: "arcna", ability: "int", name: "Arcana", proficiencyMultiplier: 0 },
  { id: "histr", ability: "int", name: "History", proficiencyMultiplier: 0 },
  {
    id: "invst",
    ability: "int",
    name: "Investigation",
    proficiencyMultiplier: 0,
  },
  { id: "natur", ability: "int", name: "Nature", proficiencyMultiplier: 0 },
  { id: "relgn", ability: "int", name: "Religion", proficiencyMultiplier: 0 },
  {
    id: "wisST",
    ability: "wis",
    name: "Saving Throws",
    savingThrow: true,
    proficiencyMultiplier: 0,
  },
  {
    id: "anmlh",
    ability: "wis",
    name: "Animal Handling",
    proficiencyMultiplier: 0,
  },
  { id: "insgt", ability: "wis", name: "Insight", proficiencyMultiplier: 0 },
  { id: "prcpt", ability: "wis", name: "Perception", proficiencyMultiplier: 0 },
  { id: "srviv", ability: "wis", name: "Survival", proficiencyMultiplier: 0 },
  { id: "medcn", ability: "wis", name: "Medicine", proficiencyMultiplier: 0 },
  {
    id: "chaST",
    ability: "cha",
    name: "Saving Throws",
    savingThrow: true,
    proficiencyMultiplier: 0,
  },
  { id: "dcptn", ability: "cha", name: "Deception", proficiencyMultiplier: 0 },
  {
    id: "intmd",
    ability: "cha",
    name: "Intimidation",
    proficiencyMultiplier: 0,
  },
  {
    id: "perfr",
    ability: "cha",
    name: "Performance",
    proficiencyMultiplier: 0,
  },
  { id: "prssn", ability: "cha", name: "Persuasion", proficiencyMultiplier: 0 },
];

const mainProficiencies: MainProficiencies = {
  lightArmour: false,
  simpleWeapons: false,
  mediumArmour: false,
  martialWeapons: false,
  heavyArmour: false,
  shields: false,
};

export const proficiencies: ProficienciesType = {
  main: mainProficiencies,
  weapons: [],
  tools: [],
  other: [],
  languages: [],
};

export const combatStats: CombatStatsType = {
  speed: 30,
  climbingSpeed: 15,
  swimmingSpeed: 15,
  flyingSpeed: 0,
  hitDice: [{ amount: 1, die: "d6" }],
};

export const racialTraits: CharacterFeatureType[] = [];

export const classFeatures: CharacterFeatureType[] = [];

export const feats: CharacterFeatureType[] = [];

export const otherTraits: CharacterFeatureType[] = [];

export const features: CharacterFeaturesType = {
  classFeatures,
  feats,
  racialTraits,
  otherTraits,
};

export const weapons: WeaponsType = [];

export const armors: ArmorsType = [];

export const equipment: EquipmentType = {
  armors,
  weapons,
};

export const spellcasting: SpellcastingType = {
  spellSlots: [],
};

export const spellIDs = [];

export const spells: SpellType[] = [];

export const sheetData: SheetDataType = {
  showAllStats: true,
  layout: {
    areas: {},
  },
  modules: {},
};

export const characterBase: Omit<
  CharacterType,
  "base" | "id" | "createdAt" | "savedAt" | "classes"
> = {
  activeStats,
  passiveStats,
  skills,
  spells,
  combatStats,
  abilityScores,
  spellcasting,
  proficiencies,
  equipment,
  features,
  sheetData,
};

export const initialState: CharacterType = {
  savedAt: Date.now(),
  createdAt: Date.now(),
  id: "",
  base: {
    name: "",
    race: "",
    background: "",
  },
  classes: [],
  activeStats,
  passiveStats,
  skills,
  spells,
  combatStats,
  abilityScores,
  spellcasting,
  proficiencies,
  equipment,
  features,
  sheetData,
};
