import { findIndexById } from "utils";
import { CharacterFeatureType, CharacterFeatureKeys } from "types";

import { create } from "./utils";
import { ChangeReducer, CharacterReducer } from "./types";

export const changeFeatureReducer: ChangeReducer<
  CharacterFeatureType,
  { featureKey: CharacterFeatureKeys }
> = (state, action) => {
  const { index, value, key, featureKey } = action.payload;
  state.features[featureKey][index][key] = value;
};

export const changeFeatureOrder: CharacterReducer<{
  featureKey: CharacterFeatureKeys;
  index: number;
  id: string;
}> = (state, action) => {
  const { index, id, featureKey } = action.payload;
  const features = state.features[featureKey];
  const oldIndex = findIndexById(features, id);
  const feature = features[oldIndex];
  features.splice(oldIndex, 1); // remove old
  features.splice(index, 0, feature); // add new
};

export const moveFeature: CharacterReducer<{
  featureKey: CharacterFeatureKeys;
  oldFeatureKey: CharacterFeatureKeys;
  index: number;
  id: string;
}> = (state, action) => {
  const { index, id, featureKey, oldFeatureKey } = action.payload;
  const oldFeatures = state.features[oldFeatureKey];
  const oldIndex = findIndexById(oldFeatures, id);
  const feature = oldFeatures[oldIndex];
  oldFeatures.splice(oldIndex, 1); // remove old

  const features = state.features[featureKey];
  features.splice(index, 0, feature); // add new
};

export const removeFeature: CharacterReducer<{
  featureKey: CharacterFeatureKeys;
  index: number;
}> = (state, action) => {
  state.features[action.payload.featureKey].splice(action.payload.index, 1);
};

export const addFeature: CharacterReducer<{
  featureKey: CharacterFeatureKeys;
}> = (state, action) => {
  state.features[action.payload.featureKey].push(create.feature());
};
