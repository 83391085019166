import styled from "styled-components";

import { EquipmentNoteType } from "types";

export const EquipmentContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const EquipmentNotesContainer = styled.section`
  background: #eee;
  margin-top: -8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 16px 32px;
  padding-top: 24px;

  display: flex;
  flex-direction: column;

  margin-bottom: 16px;

  gap: 16px;
`;

export type EquipmentHandler<O extends {}> = <T extends keyof O>(
  key: T,
  index: number
) => (value: O[T]) => void;

export type EquipmentNoteHandler = <T extends keyof EquipmentNoteType>(
  equipmentIndex: number,
  key: T,
  index: number
) => (value: EquipmentNoteType[T]) => void;
