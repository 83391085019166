import { useSelector, useDispatch } from "react-redux";

import { selectEquipment, changeArmor } from "features/character";
import {
  TextInput,
  NewNumberInput,
  InputGroup,
  Button,
  TextArea,
} from "components";
import { ArmorType } from "types";
import {
  EquipmentHandler,
  EquipmentNoteHandler,
  EquipmentContainer,
  EquipmentNotesContainer,
} from "./common";

const armorLabels = {
  name: "Name",
  stealthDisadvantage: "Stealth disadvantage",
  armorClass: "Armor class",
  strength: "Strength",
} as const;

type ArmorHandler = EquipmentHandler<ArmorType>;

export const Armor = () => {
  const dispatch = useDispatch();
  const { armors } = useSelector(selectEquipment); 

  const onAddArmor = () => {
    dispatch(changeArmor.add());
  };

  const onArmorChange: ArmorHandler = (key, index) => (value) => {
    dispatch(changeArmor.change({ index, key, value }));
  };

  const onAddArmorNote = (equipmentIndex: number) => () => {
    dispatch(changeArmor.addNote(equipmentIndex));
  };

  const onArmorNoteChange: EquipmentNoteHandler =
    (equipmentIndex, key, index) => (value) => {
      dispatch(changeArmor.changeNote({ equipmentIndex, index, key, value }));
    };

  return (
    <EquipmentContainer>
      {armors.map((armor, i) => (
        <InputGroup.Container key={armor.id}>
          <InputGroup.Row>
            <TextInput
              internalLabel
              label={armorLabels.name}
              value={armor.name}
              onChange={onArmorChange("name", i)}
            />
            <InputGroup.Checkbox
              label={armorLabels.stealthDisadvantage}
              value={armor.stealthDisadvantage}
              onChange={onArmorChange("stealthDisadvantage", i)}
            />
          </InputGroup.Row>
          <InputGroup.Row>
            <NewNumberInput
              internalLabel
              label={armorLabels.armorClass}
              placeholder="10"
              value={armor.armorClass}
              onChange={onArmorChange("armorClass", i)}
            />
            <NewNumberInput
              internalLabel
              placeholder="10"
              label={armorLabels.strength}
              value={armor.strength}
              onChange={onArmorChange("strength", i)}
            />
          </InputGroup.Row>
          <EquipmentNotesContainer>
            {armor.notes?.map((note, noteIndex) => (
              <InputGroup.Container key={note.id}>
                <InputGroup.Row>
                  <TextInput
                    internalLabel
                    label="title"
                    value={note.title}
                    onChange={onArmorNoteChange(i, "title", noteIndex)}
                  />
                </InputGroup.Row>
                <InputGroup.Row>
                  <TextArea
                    internalLabel
                    label="text"
                    value={note.text}
                    onChange={onArmorNoteChange(i, "text", noteIndex)}
                  />
                </InputGroup.Row>
              </InputGroup.Container>
            ))}
            <Button alignSelf="start" size="small" onClick={onAddArmorNote(i)}>
              Add armor note
            </Button>
          </EquipmentNotesContainer>
        </InputGroup.Container>
      ))}
      <Button alignSelf="start" size="small" onClick={onAddArmor}>
        Add armor
      </Button>
    </EquipmentContainer>
  );
};
