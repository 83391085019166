import { useState } from "react";

import { TextInput, Button, InputGroup } from "components";

import {
  registerWithEmailAndPassword,
  signInWithGoogle,
} from "./authentication";
import {
  useAuthStatus,
  LoginContainer,
  LoginLink,
  LoginText,
  Title,
} from "./common";

export const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const register = () => {
    const passwordsMatch = password === repeatPassword;
    if (!passwordsMatch) {
      alert("The passwords don't match.");
      return;
    }
    registerWithEmailAndPassword(email, password);
  };

  useAuthStatus();

  return (
    <LoginContainer>
      <Title>
        <span>Register for</span>
        Character Draft
      </Title>
      <InputGroup.Container>
        <InputGroup.Row>
          <TextInput
            internalLabel
            id="username"
            type="email"
            value={email}
            onChange={setEmail}
            label="E-mail Address"
          />
        </InputGroup.Row>
        <InputGroup.Row>
          <TextInput
            internalLabel
            type="password"
            value={password}
            onChange={setPassword}
            label="Password"
          />
        </InputGroup.Row>
        <InputGroup.Row>
          <TextInput
            internalLabel
            type="password"
            value={repeatPassword}
            onChange={setRepeatPassword}
            label="Repeat password"
          />
        </InputGroup.Row>
      </InputGroup.Container>
      <Button onClick={register}>Register</Button>
      <Button onClick={signInWithGoogle}>Register with Google</Button>
      <LoginText>
        <p>
          Already have an account? <LoginLink to="/login">Login</LoginLink> now.
        </p>
      </LoginText>
    </LoginContainer>
  );
};
