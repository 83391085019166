import { IconButtonDropdown } from "components";
import { logout } from "account";

const options = [{ value: "logout", label: "logout" }];

const containerStyles = {
  position: "absolute",
  top: "16px",
  right: "32px",
} as const;

export const FloatingSettings = () => {
  const onClick = (option: string) => {
    if (option === "logout") {
      logout();
    }
  };

  return (
    <IconButtonDropdown
      containerStyles={containerStyles}
      anchor="right"
      size="small"
      onClick={onClick}
      options={options}
    />
  );
};
