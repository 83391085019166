import styled from "styled-components"

import { inputZIndex } from "styles"
import { Checkbox } from "../../inputs/checkbox"

export const InputGroupCheckbox = styled(Checkbox).attrs({ size: "small" })`
  border: 2px solid #777;
  margin-left: -2px;

  flex-direction: column;
  align-items: flex-start;
  padding-left: 14px;
  width: calc(100%);
  justify-content: flex-start;
  background: white;

  ${inputZIndex}

  :hover {
    border-color: #444;
  }

  :focus-within {
    border-color: #000;
  }

  > p {
    color: #555;
    margin: 0;
    margin-top: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: 600;
  }
`