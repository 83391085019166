import { FC, useEffect, useRef, RefObject } from "react";
import { createPortal } from "react-dom";

import { IconButton, Button } from "components";

import {
  ModalBackdrop,
  ModalContainer,
  ModalTop,
  ModalTitle,
  ModalBottom,
  ActionButton,
  ModalContents,
  HiddenFocusElement,
} from "./styles";

type ModalProps = {
  onClose: () => void;
  title: string;
  hideCancel?: boolean;
  action?: () => void;
  actionText?: string;
  lastFocuseableRef?: RefObject<{ [key: string]: any; focus: () => void }>;
};

export const Modal: FC<ModalProps> = ({
  children,
  action,
  actionText,
  onClose,
  title,
  hideCancel,
  lastFocuseableRef,
}) => {
  const modalElement = useRef(document.getElementById("modal"));
  const prevActiveRef = useRef(document.activeElement);
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const actionButtonRef = useRef<HTMLButtonElement>(null);

  const onFocusLast = () => {
    closeButtonRef.current?.focus();
  };

  const onFocusFirst = () => {
    const lastFocusableElement =
      actionButtonRef.current ||
      cancelButtonRef.current ||
      lastFocuseableRef?.current ||
      closeButtonRef.current;

    lastFocusableElement?.focus();
  };

  useEffect(() => {
    if (closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  }, []);

  const handleClose = () => {
    if (prevActiveRef && prevActiveRef.current) {
      (prevActiveRef.current as any)?.focus();
    }

    onClose();
  };

  if (!modalElement.current) {
    return null;
  }

  return createPortal(
    <ModalBackdrop>
      <ModalContainer>
        <HiddenFocusElement tabIndex={0} aria-hidden onFocus={onFocusFirst} />
        <ModalTop>
          <ModalTitle>{title}</ModalTitle>
          <IconButton ref={closeButtonRef} size="small" onClick={handleClose}>
            X
          </IconButton>
        </ModalTop>
        <ModalContents>{children}</ModalContents>
        {!(hideCancel && (!action || !actionText)) && (
          <ModalBottom>
            {!hideCancel && (
              <Button ref={cancelButtonRef} onClick={handleClose}>
                Cancel
              </Button>
            )}
            {action && actionText && (
              <ActionButton ref={actionButtonRef} onClick={action}>
                {actionText}
              </ActionButton>
            )}
          </ModalBottom>
        )}
        <HiddenFocusElement tabIndex={0} aria-hidden onFocus={onFocusLast} />
      </ModalContainer>
    </ModalBackdrop>,
    modalElement.current
  );
};
