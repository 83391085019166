import { ClassType } from "types";

import { ChangeReducer, CharacterReducer } from "./types";
import { create } from "./utils";

export const changeClass: ChangeReducer<ClassType> = (state, action) => {
  const { index, key, value } = action.payload;
  state.classes[index][key] = value;
};

export const removeClass: CharacterReducer<{ index: number }> = (state, action) => {
  state.classes.splice(action.payload.index, 1);
};

export const addClass: CharacterReducer = (state) => {
  state.classes.push(create.class());
};
