import { FC } from "react";
import { useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

import { Resources } from "../resources";
import { mapNTimes } from "utils";
import { Border } from "components";
import {
  selectModuleTitle,
  selectModuleCharacterFeatures,
} from "features/character";

import { ModuleType, ExtraHeightSimple } from "../utils";

const CharacterFeaturesContainer = styled(Border)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 16px;
`;

const CharacterFeaturesTitle = styled.div`
  width: 100%;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

const CharacterFeaturesSection = styled.div`
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const ResourcesContainer = styled.div`
  margin-top: 8px;
  display: flex;
  width: 100%;
  gap: 12px;
  align-items: center;
  justify-content: center;
`;

const Circles = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
  align-items: center;
  justify-content: center;
  flex-shrink: 1;
  flex-basis: 0;
`;

const Circle = styled.div`
  height: 12px;
  width: 12px;
  border: 1px solid black;
  border-radius: 50%;
`;

const CharacterFeaturesText = styled.div`
  font-size: 16px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  white-space: break-spaces;

  > * {
    white-space: break-spaces;
  }
`;

const Slots: FC<{ resources: number }> = ({ resources }) => (
  <Circles>
    {mapNTimes(resources, (i) => (
      <Circle key={i} />
    ))}
  </Circles>
);

export const CharacterFeatures: ModuleType = ({ id, border }) => {
  const features = useSelector(selectModuleCharacterFeatures(id));
  const title = useSelector(selectModuleTitle(id)) || "";

  return (
    <CharacterFeaturesContainer borderWidth={8} lineWidth={2} border={border}>
      <CharacterFeaturesTitle>{title}</CharacterFeaturesTitle>
      {features.map(({ id, label, text, resources, dice }) => (
        <CharacterFeaturesSection key={id}>
          <CharacterFeaturesText>
            {Boolean(resources || dice) && <ResourcesContainer>
              {dice && (
                <Resources
                  size="small"
                  resources={[{ label: "Dice", value: dice }]}
                />
              )}
              {resources && <Slots resources={resources} />}
            </ResourcesContainer>}
            <ReactMarkdown>{`**${label}:** ${text}`}</ReactMarkdown>
          </CharacterFeaturesText>
        </CharacterFeaturesSection>
      ))}
      <ExtraHeightSimple id={id}/>
    </CharacterFeaturesContainer>
  );
};
