import styled, { keyframes } from "styled-components";

import { inputButtonPosition } from "components";
import { inputStyles } from "../styles";

const flash = keyframes`
  from {
    text-decoration-color: rgba(0,0,0,0);
  }

  to {
    text-decoration-color: rgba(0,0,0,0.9);
  }
`;

export const Input = styled.input<{ inputSize?: "small", internalLabel?: boolean }>`
  ${inputStyles};
  text-align: center;

  -moz-appearance: textfield;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  animation: ${flash} 1.05s steps(2, jump-none) infinite;
  text-decoration-color: transparent;

  :focus {
    ::placeholder {
      text-decoration: underline;
      text-decoration-color: inherit;
    }
  }
`;

export const ButtonsContainer = styled.div<{ size?: "small" }>`
  bottom: ${inputButtonPosition};
  left: ${inputButtonPosition};
  display: flex;
  flex-direction: row;
  position: absolute;
  border-radius: 4px;
  overflow: hidden;
  z-index: 5;
`;