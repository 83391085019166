import React, { FC } from "react";
import styled from "styled-components";

const SimpleStatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid black;
  text-align: center;

  height: 80px;
  width: 80px;
`

const SimpleStatLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 1;
  margin: 4px 0;
  text-align: center;
  text-transform: uppercase;
`

const SimpleStatValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
`

type SimpleStatType = {
  value?: number,
  label: string
}

export const SimpleStat: FC<SimpleStatType> = ({ value, label }) => (
  <SimpleStatContainer>
    <SimpleStatLabel>{label}</SimpleStatLabel>
    <SimpleStatValue>{value}</SimpleStatValue>
  </SimpleStatContainer>
)