import { FC } from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"

import { MinimalCharacterType } from "types";
import { noFocusMixin } from "styles";
// import { IconButton } from "components";
// import { useDispatch } from "react-redux";
// import { useDeleteCharacter } from "firebase-utils";

const CharacterName = styled.h2`
  text-align: center;
`;

const RaceAndBackground = styled.p`
  > span:not(:last-child) {
    :after {
      content: " - ";
    }
  }
`;

const width = 280;
const height = 400;

const CharacterCardContainer = styled.div`
  position: relative;
`;

const CharacterCardLink = styled(Link)`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;

  text-decoration: none;
  color: #000;

  border: 16px solid #ddd;
  border-radius: 32px;
  padding: 16px;
  width: ${width}px;
  height: ${height}px;

  transition: border-color 0.12s ease-in-out;

  :hover {
    border-color: #ccc;
  }

  :focus {
    ${noFocusMixin};
    border-color: #bbb;
  }

  :active {
    border-color: #999;
  }
`;

const ClassesContainer = styled.div``;

const Class = styled.p`
  display: flex;
  align-items: center;
  font-size: 20px;
`;

const ClassLevel = styled.span`
  display: flex;
  height: 32px;
  width: 32px;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 2px solid black;
  border-radius: 50%;
  margin-right: 8px; 
`;

// const DeleteButton = styled(IconButton)`
//   position: absolute;
//   top: -24px;
//   right: -24px;
// `

export const CharacterCard: FC<MinimalCharacterType> = ({
  id,
  base: { name, background, race },
  classes,
}) => {
  // const totalLevel = classes.reduce((total, { level }) => total + level, 0);
  // const isMultiClass = classes.length > 1;

  // const dispatch = useDispatch()
  // const [deleteCharacter, isDeleting] = useDeleteCharacter()

  // const onDelete: MouseEventHandler<HTMLButtonElement> = (e) => {
  //   e.stopPropagation();

  //   if (!isDeleting) {
  //     dispatch(deleteCharacter(id))
  //   }
  // } 

  return (
    <CharacterCardContainer key={id}>
      <CharacterCardLink to={`${id}/sheet`}>
        <CharacterName>{name}</CharacterName>
        {(race || background) && (
          <RaceAndBackground>
            {race && <span>{race}</span>}
            {background && <span>{background}</span>}
          </RaceAndBackground>
        )}
        <ClassesContainer>
          {classes?.map(({ id, className, subClass, level }) => (
            <Class key={id}>
                {/* {!isMultiClass && <ClassLevel>{level}</ClassLevel>} */}
                <ClassLevel>{level}</ClassLevel>
                {className && <>{subClass} {className}</>}
            </Class>
          ))}
        </ClassesContainer>
      </CharacterCardLink>
      {/* <DeleteButton onClick={onDelete}>X</DeleteButton> */}
    </CharacterCardContainer>
  );
};
