import { RootState } from "app/store";

import { filterByIds, pickById } from "utils";
import { CharacterFeatureKeys, CharacterFeatureType } from "types";

export const selectCharacter = (state: RootState) => state.character;

export const selectAbilityScores = (state: RootState) =>
  state.character.abilityScores;

export const selectActiveStats = (state: RootState) =>
  state.character.activeStats;
export const selectPassiveStats = (state: RootState) =>
  state.character.passiveStats;

export const selectClasses = (state: RootState) => state.character.classes;

export const selectCombatStats = (state: RootState) =>
  state.character.combatStats;

export const selectEquipment = (state: RootState) => state.character.equipment;
export const selectWeapons = (state: RootState) =>
  state.character.equipment.weapons;
export const selectArmors = (state: RootState) =>
  state.character.equipment.armors;

export const selectAllFeatures = (state: RootState) => state.character.features;

export const selectSkills = (state: RootState) => state.character.skills;
export const selectSavingThrows = (state: RootState) =>
  state.character.skills.filter(({ savingThrow }) => savingThrow);
export const selectNonSavingThrowsSkills = (state: RootState) =>
  state.character.skills.filter(({ savingThrow }) => !savingThrow);

export const selectProficiencies = (state: RootState) =>
  state.character.proficiencies;
export const selectLanguages = (state: RootState) =>
  state.character.proficiencies.languages;

export const selectSpellcasting = (state: RootState) =>
  state.character.spellcasting;

export const selectSpells = (state: RootState) => state.character.spells;

export const selectBase = (state: RootState) => state.character.base;
export const selectName = (state: RootState) => state.character.base.name;

// SHEET

export const selectAreas = (state: RootState) =>
  state.character.sheetData.layout.areas;

export const selectModules = (areaId: string) => (state: RootState) =>
  state.character.sheetData.layout.areas[areaId].modules;

export const selectModuleName = (id: string) => (state: RootState) =>
  state.character.sheetData.modules[id].moduleName;

export const selectModule = (id: string) => (state: RootState) =>
  state.character.sheetData.modules[id];

export const selectShowModuleStats = (id: string) => (state: RootState) =>
  state.character.sheetData.modules[id].showStats;

export const selectShowAllStats = (state: RootState) =>
  state.character.sheetData.showAllStats;

export const selectModuleTitle = (id: string) => (state: RootState) =>
  state.character.sheetData.modules[id].title;

export const selectModuleSpells = (moduleId: string) => (state: RootState) => {
  const spellIds = state.character.sheetData.modules[moduleId].spells;
  if (!spellIds) return [];

  return spellIds === "all"
    ? state.character.spells
    : filterByIds(state.character.spells, spellIds);
};

export const selectModuleSpellIds = (moduleId: string) => (state: RootState) =>
  state.character.sheetData.modules[moduleId]?.spells || [];

export const selectModuleCharacterFeatures =
  (moduleId: string) => (state: RootState) => {
    const { characterFeatures } = state.character.sheetData.modules[moduleId];

    if (!characterFeatures) return [];

    return Object.entries(characterFeatures).reduce<CharacterFeatureType[]>(
      (acc, [key, value]) => {
        if (!value) return acc;

        const allFeatures = state.character.features;
        const groupFeatures = allFeatures[key as CharacterFeatureKeys];
        const features =
          value === "all"
            ? groupFeatures
            : value.map(pickById(groupFeatures)).filter(Boolean);

        return [...acc, ...(features as CharacterFeatureType[])];
      },
      []
    );
  };

export const selectModuleCharacterFeatureSubscriptions =
  (moduleId: string) => (state: RootState) => {
    const module = state.character.sheetData.modules[moduleId];

    if (!module || !module.characterFeatures) return [];

    return Object.entries(module.characterFeatures)
      .filter(([key, value]) => value === "all")
      .map(([key]) => key) as CharacterFeatureKeys[];
  };
