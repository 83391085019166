import styled from "styled-components";

import { secondaryButtonStyles } from "styles";

export const InputGroupBottomButton = styled.button`
  margin-top: -8px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 8px;
  font-size: 16px;

  line-height: 1;
  text-align: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  
  ${secondaryButtonStyles}

  :focus {
    padding-bottom: 6px;
    z-index: 2;
  }

  :first-child {
    margin-top: 0;
    padding-top: 8px;
    align-items: center;
    border-radius: 8px;
  }
`;