import { FC } from "react";
import styled from "styled-components";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import {
  TextInput,
  TextArea,
  DiceInput,
  InputGroup,
  Button,
} from "components";
import { CharacterFeatureType, GenericValueChange } from "types";

import { useDndAttributes } from "./utils";
import { dragging, DragHandle, RemoveButton } from "./styles";

export type OnFeatureChange = GenericValueChange<CharacterFeatureType>;

const FeaturesContainer = styled.div`
  margin: 32px 0;
`;

const FeaturesTitle = styled.h3`
  margin-bottom: 8px;
`;

const FeatureContainer = styled.div`
  position: relative;
`;

const DraggingFeatureContainer = dragging(FeatureContainer);

const FeatureInputGroupRow = styled(InputGroup.Row)`
  > label:last-of-type:not(:first-of-type) {
    width: 160px;
    flex-shrink: 0;
  }
`;

const FeaturesList = styled.div`
  min-height: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px 40px;
  border-radius: 16px;
  margin-bottom: 8px;
  margin-left: -40px;
  width: calc(100% + 40px * 2);
  transition: background-color 0.12s ease-in-out;
`;

const noop = () => {};
export const SimpleFeature: FC<CharacterFeatureType> = ({
  label,
  dice,
  resources,
  text,
}) => (
  <DraggingFeatureContainer>
    <DragHandle className="active focus" />
    <RemoveButton />
    <FeatureInputGroupRow>
      <TextInput placeholder="Feature title" value={label} onChange={noop} />
      <DiceInput
        placeholder="0"
        onChange={noop}
        onChangeDice={noop}
        value={resources}
        diceValue={dice}
      />
    </FeatureInputGroupRow>
    <FeatureInputGroupRow>
      <TextArea
        placeholder="Feature description"
        value={text}
        onChange={noop}
      />
    </FeatureInputGroupRow>
  </DraggingFeatureContainer>
);

const Feature: FC<{
  active: boolean;
  index: number;
  feature: CharacterFeatureType;
  onChange: OnFeatureChange;
  onRemove: (index: number) => () => void;
}> = ({ active, index, feature, onChange, onRemove }) => {
  const { label, dice, resources, text, id } = feature;
  const { itemProps, handleProps } = useDndAttributes({ id, active });

  return (
    <FeatureContainer {...itemProps}>
      <FeatureInputGroupRow>
        <TextInput
          placeholder="Feature title"
          value={label}
          onChange={onChange(index, "label")}
        />
        <DiceInput
          placeholder="0"
          onChange={onChange(index, "resources")}
          onChangeDice={onChange(index, "dice")}
          value={resources}
          diceValue={dice}
        />
      </FeatureInputGroupRow>
      <InputGroup.Row>
        <TextArea
          placeholder="Feature description"
          value={text}
          onChange={onChange(index, "text")}
        />
      </InputGroup.Row>
      <DragHandle {...handleProps} />
      <RemoveButton onClick={onRemove(index)} />
    </FeatureContainer>
  );
};

type FeaturesProps = {
  id: string;
  title: string;
  features: CharacterFeatureType[];
  onAdd: () => void;
  onChange: OnFeatureChange;
  onRemove: (index: number) => () => void;
  active: boolean;
  activeId?: string;
};

const FeaturesInner: FC<FeaturesProps> = ({
  id,
  title,
  features,
  onAdd,
  activeId,
  active,
  ...handlers
}) => {
  const { setNodeRef } = useDroppable({ id });

  return (
    <FeaturesContainer>
      <FeaturesTitle>{title}</FeaturesTitle>
      <FeaturesList
        ref={features.length ? null : setNodeRef}
        style={{ backgroundColor: active ? "#eee" : "transparent" }}
      >
        {features.map((feature, i) => (
          <Feature
            active={activeId === feature.id}
            key={feature.id}
            index={i}
            feature={feature}
            {...handlers}
          />
        ))}
      </FeaturesList>
      <Button onClick={onAdd}>Add feature +</Button>
    </FeaturesContainer>
  );
};

export const Features: FC<FeaturesProps> = (props) => (
  <SortableContext
    items={props.features}
    strategy={verticalListSortingStrategy}
  >
    <FeaturesInner {...props} />
  </SortableContext>
);
