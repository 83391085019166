import { useEffect, useState } from "react";
import styled from "styled-components";

import { Button, AppSidebar } from "components";
import { useFetchCharacters } from "firebase-utils"
import { selectIsCharacterListFetched, setCharacterListFetched } from "features/ui";
import { selectCharacterList } from "features/character-list";

import { CharacterCard } from "./character-card";
import { CreateCharacterModal } from "./create-character-modal";
import { useDispatch, useSelector } from "react-redux";

const Container = styled.div`
  width: 100%;
  height: 100%;
  gap: 64px;
  padding-right: 32px;
  display: flex;
`;

const PageTitle = styled.h1`
  margin: 48px 0 32px 0;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin-top: 32px;
`;

export const CharacterList = () => {
  const dispatch = useDispatch()
  const hasFetched = useSelector(selectIsCharacterListFetched)
  const characters = useSelector(selectCharacterList)
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoadingCharacters, isDone] = useFetchCharacters(hasFetched)
  
  const toggleCharacterModal = () => {
    setModalOpen((prev) => !prev);
  };

  useEffect(() => {
    if (isDone) {
      dispatch(setCharacterListFetched())
    }
  }, [isDone, dispatch])

  return (
    <Container>
      <AppSidebar />
      <Main>
        <PageTitle>Characters {isLoadingCharacters && "(loading)"}</PageTitle>
        <Button alignSelf="start" onClick={toggleCharacterModal}>
          Create character
        </Button>
        <List>
          {characters.map((classProps) => (
            <CharacterCard key={classProps.id} {...classProps} />
          ))}
        </List>
      </Main>
      {isModalOpen && (
        <CreateCharacterModal onClose={toggleCharacterModal} />
      )}
    </Container>
  );
};
