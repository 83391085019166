import { useDispatch, useSelector } from "react-redux";

import {
  changeModuleSpell,
  selectSpells,
  selectModuleSpellIds,
} from "features/character";
import { Checkbox } from "components";

import { MenuProps } from ".";

export const SpellMenu = ({ id: moduleId }: MenuProps) => {
  const dispatch = useDispatch();
  const spells = useSelector(selectSpells);
  const spellIds = useSelector(selectModuleSpellIds(moduleId));

  const onChange = (spellId: string) => (checked: boolean) => {
    const method = checked ? "add" : "remove";
    dispatch(changeModuleSpell[method]({ moduleId, spellId }));
  };

  const onChangeSubscription = (checked: boolean) => {
    const method = checked ? "subscribe" : "unsubscribe";
    dispatch(changeModuleSpell[method]({ moduleId }));
  };

  const allSpells = spellIds === "all"

  return (
    <>
      <Checkbox
        value={allSpells}
        onChange={onChangeSubscription}
        label="All spells"
      />
      {spells.map((spell) => (
        <Checkbox
          key={spell.id}
          value={spellIds.includes(spell.id) || allSpells}
          onChange={onChange(spell.id)}
          label={`${spell.level} - ${spell.name}`}
        />
      ))}
    </>
  );
};
