import React, { forwardRef, useEffect, useRef } from "react";
import styled from "styled-components";
import useComponentSize from "@rehooks/component-size";
import { borders, BorderNames, BorderOptions } from "./borders";

export * from "./borders"

const BorderContainer = styled.div<{ borderWidth: number }>`
  position: relative;
  height: 100%;
  width: 100%;
  border-style: solid;
  border-color: transparent;
  border-width: ${(p) => p.borderWidth}px;
  z-index: 1;

  /*
    Stroke and fill are inherited by global styles
    or any set by styling the component.
  */
  stroke: inherit;
  fill: inherit;

  /* 
    The pointer events of the container are none,
    but those of the child-svg and other children
    should be all - or auto.
  */
  pointer-events: none !important;
  * {
    pointer-events: all;
  }
`;

const BorderSVG = styled.svg<{ lineWidth: number }>`
  stroke-width: ${({ lineWidth }) => lineWidth};
  position: absolute;
  z-index: -1;
  pointer-events: none !important;
  overflow: visible;
`;

const Path = styled.path`
  pointer-events: all !important;
  stroke-linecap: square;
`;

export type BorderProps = {
  border?: BorderNames;
  component?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
  svgProps?: React.ComponentProps<typeof BorderSVG>;
  svgPathProps?: React.ComponentProps<typeof Path>;
  onSizeChange?: (dimensions: number[]) => void;
} & BorderOptions;

export const Border = forwardRef<HTMLElement, BorderProps & React.HTMLAttributes<HTMLElement>>(({
  children,
  border = "stylish",
  borderWidth,
  lineWidth,
  component,
  svgProps = {},
  onSizeChange,
  svgPathProps,
  style,
  ...props
}, ref) => {
  const containerRef = useRef<HTMLElement>(ref as HTMLElement | null);
  const { width, height } = useComponentSize(containerRef);

  const options = {
    lineWidth,
    borderWidth,
  };

  useEffect(() => {
    onSizeChange?.([width, height])
  }, [onSizeChange, height, width])

  const { borderContainerStyles, svgStyles, paths } = borders[border]([width, height], options);

  return (
    <BorderContainer
      ref={containerRef}
      as={component}
      borderWidth={borderWidth}
      style={{ ...borderContainerStyles, ...style }}
      {...props}
    >
      <BorderSVG
        {...svgProps}
        style={{
          height: height + "px",
          width: width + "px",
          top: -borderWidth + "px",
          left: -borderWidth + "px",
          ...svgStyles
        }}
        viewBox={`0 0 ${width} ${height}`}
        xmlns="http://www.w3.org/2000/svg"
        lineWidth={lineWidth}
      >
        <clipPath id="myClip" clipPathUnits="objectBoundingBox">
          <circle cx=".5" cy=".5" r=".5" />
        </clipPath>
        {paths.map((path, i) => (
          <Path
            id={svgProps.id ? `${svgProps.id}-${i}` : undefined}
            key={path.d}
            {...svgPathProps}
            {...path}
          />
        ))}
        {svgProps.children}
      </BorderSVG>
      {children}
    </BorderContainer>
  );
});
