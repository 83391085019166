import styled, { css } from "styled-components"

type Size = { size?: "small" }

export const inputButtonSizes = {
  small: css`
    height: 20px;
    width: 20px;
  `,
  medium: css`
    height: 24px;
    width: 24px;
  `
}

export const inputButtonPosition = ({ size }: Size) => ({
  small: "6px",
  medium: "8px",
}[size || "medium"])

export const InputButton = styled.button<{ size?: "small" }>`
  ${({ size }) => inputButtonSizes[size || "medium"]}

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
  user-select: none;

  max-height: 100%;

  transition-property: background-color, color;
  transition-duration: 0.08s;
  transition-timing-function: linear;

  z-index: 2;

  color: rgba(0,0,0,0);
  background-color: rgba(221, 221, 221, 0);

  &:hover {
    background-color: rgba(204, 204, 204, 1) !important;
  }
  &:active {
    background-color: rgba(187, 187, 187, 1) !important;
  }

  &:first-child {
    padding-bottom: 2px;
  }
`;
