import { useEffect, useCallback, useState } from "react";
import firebase from "firebase/app";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

import { characterBase } from "data-base";
import { MinimalCharacterType, CharacterType } from "types";
import { changeCharacterList, changeCharacterInList, removeCharacterFromList } from "features/character-list";
import { cacheCharacter, removeCachedCharacter } from "features/ui";

import { auth, db } from "./account";

export const useUser = () => {
  const [user, userLoading, userError] = useAuthState(auth);
  const history = useHistory();

  useEffect(() => {
    if (userLoading) {
      return;
    }
    if (userError || !user) {
      if (userError) {
        alert(userError.message);
      }
      history.replace("/login");
      return;
    }
  }, [user, userLoading, userError, history]);

  return user;
};

export const useAddCharacter = (): [
  (character: MinimalCharacterType) => void,
  boolean,
  boolean,
  string | undefined
] => {
  const [user] = useAuthState(auth);
  const [isSaving, setIsSaving] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [error, setError] = useState<string>();
  const dispatch = useDispatch()
  const history = useHistory()

  const addCharacter = async (character: MinimalCharacterType) => {
    if (!user) {
      return;
    }

    try {
      setIsSaving(true);

      const batch = db.batch();

      const listRef = db
        .collection("users")
        .doc(user.uid)
        .collection("characters")
        .doc("characterList");

      const characterRef = db
        .collection("users")
        .doc(user.uid)
        .collection("characters")
        .doc(character.id);

      batch.update(listRef, {
        [character.id]: character,
      });

      const fullCharacter: CharacterType = {
        ...characterBase,
        ...character,
      };

      batch.set(characterRef, fullCharacter);

      await batch.commit();

      dispatch(changeCharacterInList(character))
      dispatch(cacheCharacter(fullCharacter))
      setIsDone(true);
      setIsSaving(false);
      history.push(`/${character.id}/sheet`);
    } catch (err: any) {
      setError(err.message);
      setIsSaving(false);
      console.error(err);
      alert(err.message);
    }
  }

  return [addCharacter, isSaving, isDone, error];
};

export const useDeleteCharacter = (): [
  (id: string) => void,
  boolean,
  string | undefined
] => {
  const [user] = useAuthState(auth);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState<string>();
  const dispatch = useDispatch()
  const history = useHistory()

  const deleteCharacter = useCallback(
    async (id: string) => {
      if (!user) {
        return;
      }

      try {
        setIsDeleting(true);

        const batch = db.batch();

        const listRef = db
          .collection("users")
          .doc(user.uid)
          .collection("characters")
          .doc("characterList");

        const characterRef = db
          .collection("users")
          .doc(user.uid)
          .collection("characters")
          .doc(id);

        batch.update(listRef, {
          [id]: firebase.firestore.FieldValue.delete(),
        });

        batch.delete(characterRef);

        await batch.commit();

        dispatch(removeCharacterFromList(id))
        dispatch(removeCachedCharacter(id))
        setIsDeleting(false);
        history.replace(`/`);
      } catch (err: any) {
        setError(err.message);
        setIsDeleting(false);
        console.error(err);
        alert(err.message);
      }
    },
    [user, dispatch, history]
  );

  return [deleteCharacter, isDeleting, error];
};

export const useSaveCharacter = (): [
  (character: CharacterType) => void,
  boolean,
  boolean,
  string | undefined
] => {
  const [user] = useAuthState(auth);
  const [isSaving, setIsSaving] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [error, setError] = useState<string>();
  const dispatch = useDispatch()

  const saveCharacter = useCallback(
    async (character: CharacterType) => {
      if (!user) {
        return;
      }

      try {
        setIsSaving(true);

        const batch = db.batch();

        const listRef = db
          .collection("users")
          .doc(user.uid)
          .collection("characters")
          .doc("characterList");

        const characterRef = db
          .collection("users")
          .doc(user.uid)
          .collection("characters")
          .doc(character.id);

        const minimalCharacter: MinimalCharacterType = {
          id: character.id,
          base: character.base,
          classes: character.classes,
          savedAt: Date.now(),
          createdAt: character.createdAt,
        };

        batch.update(listRef, {
          [character.id]: minimalCharacter,
        });

        batch.update(characterRef, character);

        await batch.commit();

        dispatch(changeCharacterInList(minimalCharacter))
        dispatch(cacheCharacter(character))

        setIsDone(true);
        setIsSaving(false);
      } catch (err: any) {
        setError(err.message);
        setIsSaving(false);
        console.error(err);
        alert(err.message);
      }
    },
    [user, dispatch]
  );

  return [saveCharacter, isSaving, isDone, error];
};

type Firestore = firebase.firestore.Firestore;
type DocumentData = firebase.firestore.DocumentData;
type CollectionRef = firebase.firestore.CollectionReference<DocumentData>;
type DocumentRef = firebase.firestore.DocumentReference<DocumentData>;

export function createUseFetchUserData<T>(
  path: string[],
  dataCallback: (data: T) => void,
  defaultData: T | undefined,
) {
  return function (hasFetched: boolean, pathId?: string) {
    const [user] = useAuthState(auth);
    const [isLoading, setIsLoading] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      if (hasFetched) {
        return
      }

      const fetchAnything = async () => {
        if (!user) {
          return;
        }

        try {
          setIsLoading(true);

          const userDataPathBase = ["users", user.uid].concat(path);
          const userDataPath = pathId
            ? userDataPathBase.concat([pathId])
            : userDataPathBase;

          const firestorePath = userDataPath.reduce((acc, key) => {
            if ("collection" in acc) {
              return acc.collection(key);
            } else {
              return acc.doc(key);
            }
          }, db as Firestore | CollectionRef | DocumentRef);

          if ("get" in firestorePath) {
            const query = await firestorePath.get();

            const data =
              "data" in query
                ? await query.data()
                : ((await query.docs) as any);

            if (!data && "set" in firestorePath && defaultData) {
              await firestorePath.set(defaultData);
            }

            const result = data || defaultData;

            setIsDone(true);
            setIsLoading(false);

            if (result) {
              dispatch(dataCallback(result));
            }
          }
        } catch (err: any) {
          console.error(err);
          alert(err.message);
        }
      };

      fetchAnything();
    }, [user, dispatch, pathId, hasFetched]);

    return [isLoading, isDone] as [boolean, boolean];
  };
}

type CharacterListType = { [key: string]: MinimalCharacterType };

const setCharacters = (characterList: CharacterListType) =>
  changeCharacterList(Object.values(characterList));

export const useFetchCharacters = createUseFetchUserData<CharacterListType>(
  ["characters", "characterList"],
  setCharacters,
  {},
);

export const useFetchCharacter = createUseFetchUserData<CharacterType>(
  ["characters"],
  cacheCharacter,
  undefined,
);

export const useUserData = (user: firebase.User) => {
  const fetchUserData = useCallback(async () => {
    if (!user) {
      return;
    }

    try {
      const query = await db.collection("users").doc(user.uid).get();
      const data = await query.data();

      if (!data) {
        await db.collection("users").doc(user.uid).set({
          uid: user.uid,
          email: user.email,
        });
      }
    } catch (err: any) {
      console.error(err);
      alert(err.message);
    }
  }, [user]);

  useEffect(() => {
    fetchUserData();
  }, [user, fetchUserData]);
};
