import styled, { css } from "styled-components";
import { SelectHandler } from "../select";
import { CheckboxContainer } from "../checkbox";

import { inputZIndex } from "styles";

const removeButtonZIndex = css`
  z-index: 0;

  :hover {
    z-index: 1;
  }

  :focus-within {
    z-index: 2;
  }
`;

const positions = {
  top: css`
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  `,
  left: css`
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    width: calc(100% + 1px);
    margin-right: -1px;
  `,
  right: css`
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    width: calc(100% + 1px);
    margin-left: -1px;
  `,
  bottom: css`
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  `,
};

const checkboxPositions = {
  left: css`
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    width: calc(100% - 14px);
  `,
  right: css`
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    width: calc(100% - 14px);
    margin-left: -2px;
  `,
};

type Widths = {
  [key: string]: string;
};

export const InputGroupRow = styled.div<{ widths?: Widths }>`
  width: 100%;
  display: flex;
  position: relative;

  input,
  textArea,
  ${SelectHandler},
  ${CheckboxContainer} {
    ${inputZIndex};
  }

  > button {
    ${removeButtonZIndex};
  }

  :not(:first-of-type) {
    margin-top: -2px;

    > button,
    input,
    textArea,
    ${SelectHandler},
    ${CheckboxContainer} {
      ${positions.bottom}
    }
  }

  :not(:last-of-type) {
    > button,
    input,
    textArea,
    ${SelectHandler},
    ${CheckboxContainer} {
      ${positions.top}
    }
  }

  > :not(:last-of-type) {
    input,
    textArea,
    ${SelectHandler},
    ${CheckboxContainer} {
      ${positions.left}
    }
  }

  > :not(:first-of-type):not(:last-of-type) {
    input,
    textArea,
    ${SelectHandler},
    ${CheckboxContainer} {
      margin-left: -2px;
      width: calc(100% + 2px) !important;
    }
  }

  > :not(:first-of-type) {
    input,
    textArea,
    ${SelectHandler},
    ${CheckboxContainer} {
      ${positions.right}
    }

    > p {
      left: 14px;
    }
  }

  ${CheckboxContainer}:not(:first-of-type) {
    ${checkboxPositions.right};
  }

  ${({ widths }) =>
    widths ? Object.entries(widths).map(([number, value]) => `
      > :nth-child(${number}) {
        width: ${value} !important;
        flex-shrink: 0 !important;
      }  
    `) : ""}
`;
