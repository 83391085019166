import { useDispatch } from "react-redux";

import { changeModule } from "features/character";

import { Button, borderKeys, BorderNames } from "components";


import { MenuProps } from ".";

export const StyleMenu = ({ id }: MenuProps) => {
  const dispatch = useDispatch();
  const chooseBorder = (border: BorderNames) => () => {
    dispatch(changeModule.border({ activeModuleId: id, border }));
  };

  return (
    <>
      {borderKeys.map((border) => (
        <Button key={border} onClick={chooseBorder(border)}>
          {border}
        </Button>
      ))}
    </>
  );
};
