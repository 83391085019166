import { useSelector, useDispatch } from "react-redux";
import { DndContext, DragOverlay, closestCorners } from "@dnd-kit/core";

import { GenericValueChange, ClassType } from "types";
import {
  changeClass,
  changeFeature,
  selectClasses,
  selectAllFeatures,
} from "features/character";
import { TextInput, NewNumberInput, InputGroup } from "components";

import { useSimpleDragAndDrop } from "../utils";
import { Features, OnFeatureChange, SimpleFeature } from "../features";
import { ClassLabels, ClassLabel } from "./styles";

const featureKey = "classFeatures";

type OnClassChange = GenericValueChange<ClassType>;

export const Class = () => {
  const dispatch = useDispatch();
  const classes = useSelector(selectClasses);
  const features = useSelector(selectAllFeatures);

  const onClassChange: OnClassChange = (index, key) => (value) => {
    dispatch(changeClass.change({ index, value, key }));
  };

  const handleAddClass = () => {
    dispatch(changeClass.add());
  };

  const handleRemoveClass = (index: number) => () => {
    dispatch(changeClass.remove({ index }));
  };

  const handleClassFeatureChange: OnFeatureChange = (index, key) => (value) => {
    dispatch(changeFeature.change({ featureKey, index, value, key }));
  };

  const handleChangeClassFeatureOrder = (index: number, id: string) => {
    dispatch(changeFeature.order({ featureKey, index, id }));
  };

  const handleRemoveClassFeature = (index: number) => () => {
    dispatch(changeFeature.remove({ featureKey, index }));
  };

  const handleAddClassFeature = () => {
    dispatch(changeFeature.add({ featureKey }));
  };

  const { dndContextProps, activeItem, activeId } = useSimpleDragAndDrop({
    items: features.classFeatures,
    onChangeOrder: handleChangeClassFeatureOrder,
  });

  const firstClassId = classes[0]?.id;

  return (
    <>
      <InputGroup.Container>
        {firstClassId && (
          <ClassLabels>
            <ClassLabel htmlFor={`${firstClassId}-class`}>Class</ClassLabel>
            <ClassLabel htmlFor={`${firstClassId}-subclass`}>
              Subclass
            </ClassLabel>
            <ClassLabel htmlFor={`${firstClassId}-level`} right>
              Level
            </ClassLabel>
          </ClassLabels>
        )}
        <div>
          {classes.map(({ id, className, subClass, level }, index) => (
            <InputGroup.Row key={id}>
              <TextInput
                id={`${id}-class`}
                value={className}
                onChange={onClassChange(index, "className")}
              />
              <TextInput
                id={`${id}-subClass`}
                value={subClass}
                onChange={onClassChange(index, "subClass")}
              />
              <NewNumberInput
                id={`${id}-level`}
                style={{ textAlign: "right" }}
                value={level}
                onChange={onClassChange(index, "level")}
              />
              {classes.length > 1 && (
                <InputGroup.RowButton onClick={handleRemoveClass(index)}>
                  X
                </InputGroup.RowButton>
              )}
            </InputGroup.Row>
          ))}
          {classes.length < 3 && (
            <InputGroup.BottomButton onClick={handleAddClass}>
              Add class +
            </InputGroup.BottomButton>
          )}
        </div>
      </InputGroup.Container>
      <DndContext {...dndContextProps} collisionDetection={closestCorners}>
        <Features
          activeId={activeId}
          active={Boolean(activeItem)}
          id="classFeatures"
          title="Class Features"
          features={features.classFeatures}
          onChange={handleClassFeatureChange}
          onRemove={handleRemoveClassFeature}
          onAdd={handleAddClassFeature}
        />
        <DragOverlay>
          {activeItem && <SimpleFeature {...activeItem} />}
        </DragOverlay>
      </DndContext>
    </>
  );
};
