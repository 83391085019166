import { FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { DragOverlay, DndContext } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { selectSpells, changeSpell } from "features/character";
import { magicSchools, SpellType } from "types";
import { inputZIndex } from "styles";
import {
  Button,
  TextInput,
  NewNumberInput,
  TextArea,
  InputGroup,
  Select,
  Checkbox,
} from "components";

import { useSimpleDragAndDrop, useDndAttributes } from "./utils";
import { DragHandle, RemoveButton, dragging } from "./styles";

const magicSchoolsOptions = magicSchools.map((school) => ({
  label: `${school.substring(0, 1).toUpperCase()}${school.substring(1)}`,
  value: school,
}));

const SpellsContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 16px;

  padding-bottom: 80px;
`;

const SpellContainer = styled.div`
  position: relative;
  align-self: stretch;
`;

const DraggingSpellContainer = dragging(SpellContainer);

const StyledCheckbox = styled(Checkbox)`
  background: white;
  border: 2px solid #777;
  margin-left: -2px;

  flex-direction: column;
  align-items: flex-start;
  padding-left: 14px;
  justify-content: flex-start;

  ${inputZIndex}

  :hover {
    border-color: #444;
  }

  :focus-within {
    border-color: #000;
  }

  > p {
    color: #555;
    margin: 0;
    margin-top: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: 600;
  }
`;

type OnSpellChange = <T extends keyof SpellType>(
  key: T
) => (value: SpellType[T]) => void;

const Spell: FC<{
  active: boolean;
  spell: SpellType;
  onChange: OnSpellChange;
  onRemove: () => void;
}> = ({ active, spell, onChange, onRemove }) => {
  const { itemProps, handleProps } = useDndAttributes({ id: spell.id, active });

  return (
    <SpellContainer key={spell.id} {...itemProps}>
      <InputGroup.Row>
        <TextInput
          label="Name"
          internalLabel
          value={spell.name}
          onChange={onChange("name")}
        />
        <TextInput
          label="Reference"
          internalLabel
          style={{ width: "160px" }}
          value={spell.reference}
          onChange={onChange("reference")}
        />
      </InputGroup.Row>
      <InputGroup.Row>
        <NewNumberInput
          label="Spell level"
          internalLabel
          value={spell.level}
          onChange={onChange("level")}
        />
        <Select
          placeholder="No school"
          label="Magic school"
          internalLabel
          value={spell.school}
          onChange={onChange("school")}
          options={magicSchoolsOptions}
        />
        <StyledCheckbox
          size="small"
          label="Ritual"
          value={spell.ritual || false}
          onChange={onChange("ritual")}
        />
      </InputGroup.Row>
      <InputGroup.Row>
        <TextArea
          label="Short description"
          internalLabel
          value={spell.description}
          onChange={onChange("description")}
        />
      </InputGroup.Row>
      <InputGroup.Row>
        <TextArea
          label="Long description"
          internalLabel
          value={spell.longDescription || ""}
          onChange={onChange("longDescription")}
        />
      </InputGroup.Row>
      <DragHandle {...handleProps} />
      <RemoveButton onClick={onRemove} />
    </SpellContainer>
  );
};

const noop = () => {};
const SimpleSpell: FC<{ spell: SpellType }> = ({ spell }) => (
  <DraggingSpellContainer key={spell.id}>
    <InputGroup.Row>
      <TextInput
        label="Name"
        internalLabel
        value={spell.name}
        onChange={noop}
      />
      <TextInput
        label="Reference"
        internalLabel
        style={{ width: "160px" }}
        value={spell.reference}
        onChange={noop}
      />
    </InputGroup.Row>
    <InputGroup.Row>
      <NewNumberInput
        label="Spell level"
        internalLabel
        value={spell.level}
        onChange={noop}
      />
      <Select
        placeholder="No school"
        label="Magic school"
        internalLabel
        value={spell.school}
        onChange={noop}
        options={magicSchoolsOptions}
      />
      <StyledCheckbox
        size="small"
        label="Ritual"
        value={spell.ritual || false}
        onChange={noop}
      />
    </InputGroup.Row>
    <InputGroup.Row>
      <TextArea
        label="Short description"
        internalLabel
        value={spell.description}
        onChange={noop}
      />
    </InputGroup.Row>
    <InputGroup.Row>
      <TextArea
        label="Long description"
        internalLabel
        value={spell.longDescription || ""}
        onChange={noop}
      />
    </InputGroup.Row>
    <DragHandle className="active focus" />
    <RemoveButton />
  </DraggingSpellContainer>
);

export const Spells = () => {
  const dispatch = useDispatch();
  const spells = useSelector(selectSpells);
  const onChange =
    (index: number): OnSpellChange =>
    (key) =>
    (value) => {
      dispatch(changeSpell.change({ index, value, key }));
    };

  const onChangeOrder = (index: number, id: string) => {
    dispatch(changeSpell.order({ index, id }));
  };

  const onRemove = (index: number) => () => {
    dispatch(changeSpell.remove({ index }));
  };

  const onAdd = () => {
    dispatch(changeSpell.add());
  };

  const { dndContextProps, activeItem, activeId } = useSimpleDragAndDrop({
    items: spells,
    onChangeOrder,
  });

  return (
    <DndContext {...dndContextProps}>
      <SpellsContainer>
        <SortableContext items={spells} strategy={verticalListSortingStrategy}>
          {spells.map((spell, i) => (
            <Spell
              key={spell.id}
              onChange={onChange(i)}
              onRemove={onRemove(i)}
              spell={spell}
              active={activeId === spell.id}
            />
          ))}
        </SortableContext>
        <DragOverlay>
          {activeItem && <SimpleSpell spell={activeItem} />}
        </DragOverlay>
        <Button onClick={onAdd}>Add Spell +</Button>
      </SpellsContainer>
    </DndContext>
  );
};
