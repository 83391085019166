import styled from "styled-components";
import { useExtraHeightHook } from "components/character-details/sheet/module-container/resize";
import { FC, useMemo } from "react";
import { BorderNames } from "components";

const line = `
<svg viewBox="0 0 10 40" xmlns="http://www.w3.org/2000/svg">
  <line stroke="black" x1="0" y1="40" x2="10" y2="40" />
</svg>
`;

export const ExtraHeightLinesContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  background: url(${`'data:image/svg+xml;utf8,${line}'`});
  background-repeat: repeat;
  background-size: 10px 40px;
  background-position: top;
`;

export const ExtraHeightSimpleContainer = styled.div`
  flex-grow: 1;
  `;

type ExtraHeightComponent = FC<{ id: string }>

export const getExtraHeightId = (id: string) => `${id}-extra-height`

export const ExtraHeightLines: ExtraHeightComponent = ({ id }) => {
  const extraHeightRef = useExtraHeightHook();
  const extraHeightId = useMemo(() => getExtraHeightId(id), [id])

  return (
    <ExtraHeightLinesContainer id={extraHeightId} ref={extraHeightRef}/>
    )
  }
  export const ExtraHeightSimple: ExtraHeightComponent = ({ id }) => {
  const extraHeightRef = useExtraHeightHook();
  const extraHeightId = useMemo(() => getExtraHeightId(id), [id])

  return (
    <ExtraHeightSimpleContainer id={extraHeightId} ref={extraHeightRef}/>
  )
}

export type ModuleType = FC<{ border: BorderNames, id: string }>