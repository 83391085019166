import { CSSProperties } from "react";
import { SizeType } from "./types";

export type BorderOptions = {
  lineWidth: number;
  borderWidth: number;
};

type BorderFunction = (
  size: SizeType,
  options: BorderOptions
) => {
  borderContainerStyles?: CSSProperties;
  svgStyles?: CSSProperties;
  paths: {
    d: string;
    id?: string;
    style?: CSSProperties;
    clipPath?: string;
  }[];
};

const rectangle = ([x, y]: SizeType, inset: number) => `
  M${inset},${inset}
  L${x - inset},${inset}
  L${x - inset},${y - inset}
  L${inset},${y - inset}
  L${inset},${inset}
`;

const double: BorderFunction = (size, { lineWidth, borderWidth }) => {
  const bi = lineWidth / 2; // border-inset
  const i = borderWidth - bi; // inset of the inner border
  const outer = rectangle(size, bi);
  const inner = rectangle(size, i);
  return { paths: [{ d: `${outer} ${inner}` }] };
};

const tripple: BorderFunction = (size, { lineWidth, borderWidth }) => {
  const bi = lineWidth / 2; // border-inset
  const io = borderWidth - bi; // inset outer
  const im = borderWidth / 2; // inset middle
  const outer = rectangle(size, bi);
  const middle = rectangle(size, im);
  const inner = rectangle(size, io);
  return { paths: [{ d: `${outer} ${middle} ${inner}` }] };
};

const stylish: BorderFunction = (size, { lineWidth, borderWidth }) => {
  const [x, y] = size;
  const bi = lineWidth / 2; // border inset
  const i = borderWidth - bi; // inner inset
  const r = borderWidth * 2; // corner radius

  const arcBase = `A ${r} ${r}, 0, 0, 0`;
  const arc1 = `${arcBase}, ${x - bi},${r + bi}`;
  const arc2 = `${arcBase}, ${x - r - bi},${y - bi}`;
  const arc3 = `${arcBase}, ${bi},${y - r - bi}`;
  const arc4 = `${arcBase}, ${r + bi},${bi}`;

  const outer = `
    M${r + bi},${bi}
    L${x - r - bi},${bi}
    ${arc1}
    L${x - bi},${y - r - bi}
    ${arc2}
    L${r + bi},${y - bi}
    ${arc3}
    L${bi},${r + bi}
    ${arc4}
  `;
  const inner = rectangle(size, i);

  return { paths: [{ d: `${outer} ${inner}` }] };
};

// CHUNK
type Vertices = Array<SizeType>;
const invert = (vertices: Vertices, invertX: number, invertY: number) =>
  vertices.map(([x, y]) => [
    invertX ? invertX - x : x,
    invertY ? invertY - y : y,
  ]);
const draw = (vertices: Vertices) =>
  vertices.map(([x, y], i) => `${i === 0 ? "M" : "L"}${x},${y}`).join(" ");

const chunk: BorderFunction = (size, { lineWidth, borderWidth }) => {
  const [x, y] = size;
  const bi = lineWidth / 2; // border inset
  const i = borderWidth - bi; // inner inset

  const steepness = 12;

  // corners
  const xSizeOuter = Math.round(x / 3);
  const xSizeInner = xSizeOuter - steepness;
  const ySizeOuter = Math.round(y / 3);
  const ySizeInner = ySizeOuter - steepness;
  const innnerCorner = i + 8;
  const outerCorner = bi + 2;
  const peakOffset = bi - 4;

  const corner = [
    [outerCorner, outerCorner],
    [xSizeOuter, peakOffset],
    [xSizeInner, i],
    [innnerCorner, innnerCorner],
    [i, ySizeInner],
    [peakOffset, ySizeOuter],
    [outerCorner, outerCorner],
  ];

  const corner1 = draw(corner);
  const corner2 = draw(invert(corner, x, 0));
  const corner3 = draw(invert(corner, 0, y));
  const corner4 = draw(invert(corner, x, y));

  // caps
  const capSides = xSizeOuter + steepness;
  const sideSides = ySizeOuter + steepness;

  const cap = [
    [capSides, peakOffset],
    [x - capSides, peakOffset],
    [x - xSizeOuter, i],
    [xSizeOuter, i],
    [capSides, peakOffset],
  ];

  const side = [
    [peakOffset, sideSides],
    [peakOffset, y - sideSides],
    [i, y - ySizeOuter],
    [i, ySizeOuter],
    [peakOffset, sideSides],
  ];

  const top = draw(cap);
  const bottom = draw(invert(cap, 0, y));
  const left = draw(side);
  const right = draw(invert(side, x, 0));

  return {
    paths: [
      {
        d: `${corner1} ${corner2} ${corner3} ${corner4} ${top} ${bottom} ${left} ${right}`,
      },
    ],
  };
};

const flagRight: BorderFunction = (size, { lineWidth, borderWidth }) => {
  const [x, y] = size;
  const bi = lineWidth / 2; // border inset
  const i = borderWidth - bi; // inner inset
  const innermost = y / 2;
  const innerMargin = i * 1.33;

  const d = `
    M${i},${bi}
    L${x - bi},${bi}
    L${x - innermost},${y / 2}
    L${x - bi},${y - bi}
    L${i},${y - bi}
    M${i},${i}
    L${x - i - innerMargin},${i}
    L${x - innermost - innerMargin},${y / 2}
    L${x - i - innerMargin},${y - i}
    L${i},${y - i}
  `;

  const borderContainerStyles = {
    borderLeft: 0,
    borderRightWidth: innermost + innerMargin + "px",
  };

  return { borderContainerStyles, paths: [{ d }] };
};

const flagLeft: BorderFunction = (size, { lineWidth, borderWidth }) => {
  const [x, y] = size;
  const bi = lineWidth / 2; // border inset
  const i = borderWidth - bi; // inner inset
  const innermost = y / 2;
  const innerMargin = i * 1.33;

  const d = `
    M${x},${bi}
    L${bi},${bi}
    L${x - (x - innermost)},${y / 2}
    L${x - (x - bi)},${y - bi}
    L${x},${y - bi}

    M${x},${i}
    L${x - (x - i - innerMargin)},${i}
    L${x - (x - innermost - innerMargin)},${y / 2}
    L${x - (x - i - innerMargin)},${y - i}
    L${x},${y - i}
  `;

  const borderLeftWidth = `${Math.round(innermost + innerMargin)}px`;
  const borderContainerStyles = { borderRight: 0, borderLeftWidth };
  const svgStyles = { left: `-${borderLeftWidth}` };

  return { borderContainerStyles, svgStyles, paths: [{ d }] };
};

const connected: BorderFunction = (size, { lineWidth, borderWidth }) => {
  const [x, y] = size;
  const bi = lineWidth / 2; // border inset
  const i = borderWidth - bi; // inner inset
  const r = borderWidth * 2;

  const arcBase = `A${r},${r},0,0,0`;
  const arcBaseI = `A${r},${r},0,0,1`;

  const top = `
    M${-bi},${-r}
    ${arcBase},${r - bi},0
    L${x - r - bi},${0}
    ${arcBase},${x + bi},${-r}
    L${x + bi},${borderWidth + r}
    ${arcBase},${x - r - bi},${borderWidth}
    L${r - bi},${borderWidth}
    ${arcBase},${-bi},${borderWidth + r}
    z
  `;
  const bottom = `
    M${-bi},${y + r}
    ${arcBaseI},${r - bi},${y}
    L${x - r - bi},${y}
    ${arcBaseI},${x + bi},${y + r}
    L${x + bi},${y - (borderWidth + r)}
    ${arcBaseI},${x - r - bi},${y - borderWidth}
    L${r - bi},${y - borderWidth}
    ${arcBaseI},${-bi},${y - (borderWidth + r)}
    z
  `;

  const clipPathRaw = [
    `M${-bi},${borderWidth + r}`,
    `${arcBaseI},${r - bi},${borderWidth}`,
    `L${x - r - bi},${borderWidth}`,
    `${arcBaseI},${x + bi},${borderWidth + r}`,
    `L${x + bi},${y - (borderWidth + r)}`,
    `${arcBaseI},${x - r - bi},${y - borderWidth}`,
    `L${r - bi},${y - borderWidth}`,
    `${arcBaseI},${-bi},${y - (borderWidth + r)}`,
  ].join(" ")

  const clipPath = `path('${clipPathRaw} z')`

  const left = `M${bi},0 L${bi},${y} M${i},${y} L${i},0`;
  const right = `M${x - bi},0 L${x - bi},${y} M${x - i},${y} L${x - i},0`;

  return {
    paths: [
      { d: top + bottom},
      {
        d: left + right,
        clipPath,
      },
    ],
  };
};

// const dwarvish: BorderFunction = (size, { lineWidth, borderWidth }) => {
//   // const corner =
//   return { paths: [{ d: "" }] };
// };

export const borders = {
  chunk,
  double,
  tripple,
  stylish,
  flagRight,
  flagLeft,
  connected,
};

export type BorderNames = keyof typeof borders;

export const borderKeys = Object.keys(borders) as BorderNames[];
